/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components

// @mui material components
import Card from "@mui/material/Card";
import { useLocation } from "react-router-dom";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

function PolicyData() {
  const {state} = useLocation();
  // console.log(state);
  return (
    <Card>
      <SoftBox mt={5} px={100}>
      <SoftBox component="img" src="https://portal.care4edu.com/Care4edu.png" alt={state.policy_name} width="100%" borderRadius="lg" />
      </SoftBox>
      <SoftBox pb={3} px={50}>
        {state.policy_name && (
          <SoftTypography
            variant="caption"
            color="inherit"
            textTransform="uppercase"
            fontWeight="medium"
            textGradient
          >
            {"Erfeective from " + state.policy_date}
          </SoftTypography>
        )}
        <SoftBox display="block" mt={0.5} mb={1}>
         (
              <SoftTypography
                display="inline"
                variant="h5"
                textTransform="capitalize"
                className="color-background"
              >
                {state.policy_name}
              </SoftTypography>
          ) 
        </SoftBox>
        <SoftTypography variant="body2" component="p" color="text">
          {state.policy_description}
        </SoftTypography>
        <SoftTypography variant="body2" component="p" color="text">
          {state.policy_data}
        </SoftTypography>
        <SoftTypography variant="body2" component="p" color="text">
          {state.policy_description2}
        </SoftTypography>
        {state.policy_audience && (
          <SoftBox display="flex" alignItems="center" mt={3}>
            <SoftAvatar variant="rounded" src="https://portal.care4edu.com/Care4edu.png" alt={state.policy_data} shadow="md" />
            <SoftBox pl={2} lineHeight={0}>
              <SoftTypography component="h6" variant="button" fontWeight="medium" gutterBottom>
                {"Important Note"}
              </SoftTypography>
              <SoftTypography variant="caption" color="text">
                {state.policy_note}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        )}
      </SoftBox>
    </Card>
  );
}
export default PolicyData;