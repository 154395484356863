import React, { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftAvatar from "components/SoftAvatar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import Swal from 'sweetalert2';
import CircularProgress from "@mui/material/CircularProgress";

const UserPage = () => {
    const [userData, setUserData] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [userDepartment, setUserDepartment] = useState("");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [error, setError] = useState(null);

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level') === "true";
        setPermission(accessLevel);
        const department = localStorage.getItem('Depart Details');
        setUserDepartment(department);
    }, []);

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            const url = `${APIData.api}users?org=${org}`;
            try {
                const response = await fetch(url, { headers: APIData.headers });
                if (!response.ok) throw new Error("Failed to fetch user data");
                const data = await response.json();
                setUserData(data);
                setError(null);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setError('Failed to fetch user data');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to fetch user data.',
                });
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const filteredUsers = userData.filter(user =>
        user.user_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.user_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.user_phone_number.includes(searchTerm)
    );

    const paginatedUsers = filteredUsers.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const columns = [
        { name: "User Name", align: "left" },
        { name: "Email", align: "left" },
        { name: "Phone Number", align: "left" },
    ];

    const rows = paginatedUsers.map((user) => ({
        "User Name": (
            <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                <SoftBox mr={2}>
                    <SoftAvatar
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(user.user_name)}&background=random`}
                        alt={user.user_name}
                        size="sm"
                        variant="rounded"
                    />
                </SoftBox>
                <SoftTypography variant="button" fontWeight="medium">
                    {user.user_name}
                </SoftTypography>
            </SoftBox>
        ),
        Email: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {user.user_email}
            </SoftTypography>
        ),
        "Phone Number": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {user.user_phone_number}
            </SoftTypography>
        ),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">User List</SoftTypography>
                            <SoftBox>
                                <SoftInput
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    size="small"
                                />
                            </SoftBox>
                        </SoftBox>

                        <SoftBox sx={{ overflow: 'auto' }}>
                            {loading ? (
                                <SoftBox display="flex" justifyContent="center" p={3}>
                                    <CircularProgress />
                                </SoftBox>
                            ) : error ? (
                                <SoftBox display="flex" justifyContent="center" p={3}>
                                    <SoftTypography color="error">{error}</SoftTypography>
                                </SoftBox>
                            ) : (
                                <>
                                    <SoftBox
                                        sx={{
                                            "& .MuiTableRow-root:not(:last-child)": {
                                                "& td": {
                                                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                        `${borderWidth[1]} solid ${borderColor}`,
                                                },
                                            },
                                        }}
                                    >
                                        <Table columns={columns} rows={rows} />
                                    </SoftBox>
                                    <SoftBox
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        p={3}
                                    >
                                        <SoftBox display="flex" gap={1} alignItems="center">
                                            <SoftTypography variant="caption" color="secondary">
                                                {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredUsers.length)} of {filteredUsers.length}
                                            </SoftTypography>
                                            <SoftButton
                                                variant="text"
                                                color="info"
                                                onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                                disabled={page === 0}
                                                iconOnly
                                            >
                                                <KeyboardArrowLeft />
                                            </SoftButton>
                                            <SoftButton
                                                variant="text"
                                                color="info"
                                                onClick={() => setPage(prev => Math.min(Math.ceil(filteredUsers.length / itemsPerPage) - 1, prev + 1))}
                                                disabled={page >= Math.ceil(filteredUsers.length / itemsPerPage) - 1}
                                                iconOnly
                                            >
                                                <KeyboardArrowRight />
                                            </SoftButton>
                                        </SoftBox>
                                    </SoftBox>
                                </>
                            )}
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default UserPage;