import React, { useState, useEffect } from 'react';
import { Grid, Card, CircularProgress, Backdrop } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import * as AiIcons from "react-icons/ai";
import { Link } from 'react-router-dom';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import SoftInput from 'components/SoftInput';
import Swal from 'sweetalert2';

const AddSpoc = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [roleData, setRoleData] = useState({
        supervisor: '',
        email_id: '',
        roles: ''
    });

    const [selectedOptions, setSelectedOptions] = useState({
        supervisor: null,
        email_id: null,
        roles: null
    });

    const [empDetails, setEmpDetails] = useState([]);

    const rolesOptions = [
        { value: 'HR', label: 'Hr' },
        { value: 'SALES', label: 'Sales' },
        { value: 'SUPPORT', label: 'Support' },
        { value: 'ORGCOMM', label: 'Communication' },
        { value: 'ENROLLMENTS', label: 'Enrollment' },
        { value: 'EVENTS', label: 'Event' },
        { value: 'ACADEMY', label: 'Academy' },
        { value: 'FINANCE', label: 'Finance' },
        { value: 'TECHNICAL', label: 'Technical' },
    ];

    useEffect(() => {
        fetchEmpDetails();
    }, []);

    const fetchEmpDetails = async () => {
        try {
            const response = await axios.get(
                APIData.api + `employee/details?org=${org}`,
                { headers: APIData.headers }
            );
            setEmpDetails(response.data);
        } catch (error) {
            console.error('Error fetching employee details:', error);
            toast.error('Failed to fetch employee details');
        }
    };

    const validationRules = {
        supervisor: (value) => !value ? 'Name is required' : '',
        email_id: (value) => !value ? 'Email is required' : '',
        roles: (value) => !value ? 'Department is required' : ''
    };

    const validateFields = () => {
        const newErrors = {};
        Object.keys(validationRules).forEach(field => {
            const error = validationRules[field](roleData[field]);
            if (error) newErrors[field] = error;
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const name = actionMeta.name;
        const value = selectedOption ? selectedOption.value : '';
    
        setRoleData(prev => ({
            ...prev,
            [name]: value
        }));
    
        setSelectedOptions(prev => ({
            ...prev,
            [name]: selectedOption
        }));

     
    if (name === 'supervisor') {
        // Using the correct property names: 'name' and 'email'
        const selectedEmployee = empDetails.find(emp => emp.name === value);
        if (selectedEmployee) {
            setRoleData(prev => ({
                ...prev,
                email_id: selectedEmployee.email  // Changed from email_id to email
            }));
        }
    }

    if (errors[name]) {
        setErrors(prev => ({
            ...prev,
            [name]: ''
        }));
    }
};

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateFields()) {
            toast.error("Please fill in all required fields");
            return;
        }

        setLoading(true);
        const formdata = [
            {
                id: 0,
                roles: roleData.roles,
                supervisor: roleData.supervisor,
                email_id: roleData.email_id,
                org: org,
                opt: "YES"
            }
        ];
        console.log(formdata);

        try {
            const response = await axios.post(
                APIData.api + 'org-roles-head/',
                formdata,
                { headers: APIData.headers }
            );

            if (response.status === 201) {
                Swal.fire({
                    icon: "success",
                    title: "Updated successfully",
                    showConfirmButton: false,
                    timer: 1500
                })
                navigate('/depart-spoc');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to Add SPOC',
                    text: 'Please try again later.',
                    confirmButtonText: 'OK'
                });            }
        } catch (error) {
            //   alert('Failed to add SPOC. Please try again.');
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to add SPOC. Please try again",
            })
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const supervisorOptions = empDetails.map(emp => ({
        value: emp.name,    
        label: emp.name    
    }));

    const emailOptions = empDetails.map(emp => ({
        value: emp.email_id,
        label: emp.email_id
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "70vh" }}>
                <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid item xs={12} sm={8}>
                    <Card sx={{ height: 'auto' }}>
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/depart-spoc">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Add Department SPOC
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox pt={1} pb={3} px={3}>
                            <SoftBox component="form" onSubmit={handleSubmit}>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Name *</SoftTypography>
                                    <CustomSelect
                                        options={supervisorOptions}
                                        placeholder="Select Name"
                                        name="supervisor"
                                        value={selectedOptions.supervisor}
                                        onChange={handleSelectChange}
                                        error={!!errors.supervisor}
                                    />
                                    {errors.supervisor && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.supervisor}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Email</SoftTypography>
                                    <SoftInput
                                        value={roleData.email_id}
                                        disabled
                                        style={{ backgroundColor: '#f5f5f5' }}
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Department *</SoftTypography>
                                    <CustomSelect
                                        options={rolesOptions}
                                        placeholder="Select Department"
                                        name="roles"
                                        value={selectedOptions.roles}
                                        onChange={handleSelectChange}
                                        error={!!errors.roles}
                                    />
                                    {errors.roles && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.roles}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                <SoftBox mt={4} mb={1}>
                                    <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                                        Add SPOC
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

export default AddSpoc;