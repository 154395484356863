import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from 'components/SoftButton';
import SoftInput from "components/SoftInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { APIData, org } from '../authentication/APIData';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Card from "@mui/material/Card";

const Name = ({ name, email }) => {
    const [showFullName, setShowFullName] = useState(false);
    const truncationLength = 30;
    const displayedName = showFullName || name.length <= truncationLength
        ? name
        : `${name.substring(0, truncationLength)}...`;

    return (
        <SoftBox display="flex" alignItems="center" py={0.5}>
            <SoftBox mr={2}>
                <SoftAvatar
                    src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                    alt={name}
                    size="sm"
                    variant="rounded"
                />
            </SoftBox>
            <SoftBox display="flex" flexDirection="column">
                <SoftTypography variant="button" fontWeight="medium">
                    {displayedName}
                </SoftTypography>
                {name.length > truncationLength && (
                    <SoftTypography
                        variant="caption"
                        color="info"
                        onClick={() => setShowFullName(!showFullName)}
                        style={{ cursor: "pointer" }}
                    >
                        {showFullName ? "Show Less" : "Read More"}
                    </SoftTypography>
                )}
                <SoftTypography variant="caption" color="secondary">
                    {email}
                </SoftTypography>
            </SoftBox>
        </SoftBox>
    );
};

const Description = ({ description }) => {
    const [showFullDescription, setShowFullDescription] = useState(false);
    const isLongDescription = description.length > 20;
    const displayedDescription = showFullDescription || !isLongDescription
        ? description
        : `${description.substring(0, 20)}...`;

    return (
        <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {displayedDescription}
            </SoftTypography>
            {isLongDescription && (
                <SoftTypography
                    variant="caption"
                    color="info"
                    onClick={() => setShowFullDescription(!showFullDescription)}
                    style={{ cursor: "pointer" }}
                >
                    {showFullDescription ? "Show Less" : "Read More"}
                </SoftTypography>
            )}
        </SoftBox>
    );
};

const OrgPassword = () => {
    const [passwordData, setPasswordData] = useState([]);
    const [permission, setPermission] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [itemsPerPage] = useState(5);

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(parseInt(accessLevel));
    }, []);

    const fetchData = async () => {
        const baseUrl = `${APIData.api}orgpasswords/orgpassword/details?org=${org}`;
        try {
            const response = await fetch(baseUrl, { headers: APIData.headers });
            const data = await response.json();
            setPasswordData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleDelete = async (title) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to delete the password for "${title}"?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            try {
                const response = await fetch(
                    `${APIData.api}orgpasswords/delete?title=${title}`,
                    {
                        method: 'DELETE',
                        headers: { ...APIData.headers, 'Content-Type': 'application/json' },
                    }
                );
                if (response.ok) {
                    setPasswordData(prevData =>
                        prevData.filter(item => item.title !== title)
                    );
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Password deleted successfully',
                        confirmButtonColor: '#3085d6'
                    });
                } else {
                    const errorData = await response.json();
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: errorData.message || 'Failed to delete password',
                        confirmButtonColor: '#3085d6'
                    });
                }
            } catch (error) {
                console.error('Error deleting password:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to delete password. Please try again.',
                    confirmButtonColor: '#3085d6'
                });
            }
        }
    };

    const filteredData = passwordData.filter(pass =>
        pass.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        pass.created_by.toLowerCase().includes(searchTerm.toLowerCase()) ||
        pass.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        pass.supervisor_id.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedData = filteredData.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const columns = [
        { name: "Title", align: "left" },
        { name: "SupervisorId", align: "left" },
        { name: "Description", align: "left" },
        { name: "Action", align: "center" },
    ];

    const rows = paginatedData.map((pass) => ({
        Title: <Name name={pass.title} email={pass.created_by} />,
        SupervisorId: <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {pass.supervisor_id}
        </SoftTypography>,
        Description: <Description description={pass.description} />,
        Action: (
            <>
                {(permission === 1110 || permission === 1111 || isSuperAdmin) && (
                    <Link to={`/edit-password/${pass.title}`}>
                        <SoftTypography
                            component="a"
                            variant="button"
                            color="secondary"
                            fontWeight="medium"
                        >
                            Edit
                        </SoftTypography>
                    </Link>
                )}
                
                {(permission === 1111 || isSuperAdmin) && (
                    <SoftTypography
                        component="a"
                        variant="button"
                        color="error"
                        fontWeight="medium"
                        sx={{ marginLeft: "10px", cursor: "pointer" }}
                        onClick={() => handleDelete(pass.title)}
                    >
                        Delete
                    </SoftTypography>
                )}
    
                <Link to='/req-password' state={pass}>
                    <SoftTypography
                        component="a"
                        variant="button"
                        color="primary"
                        fontWeight="medium"
                        sx={{ marginLeft: "10px" }}
                    >
                        Request
                    </SoftTypography>
                </Link>
            </>
        ),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Organizations Passwords</SoftTypography>
                            <SoftBox display="flex" alignItems="center" gap={2}>
                                <SoftBox>
                                    <SoftInput
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        size="small"
                                    />
                                </SoftBox>
                                {!(permission === 1000) && (
                                    <Link to='/add-org-password'>
                                        <SoftButton variant="gradient" color="info" size="small">
                                            + Add Password
                                        </SoftButton>
                                    </Link>
                                )}
                            </SoftBox>
                        </SoftBox>
                        <SoftBox sx={{ overflow: 'auto' }}>
                            <Table columns={columns} rows={rows} />
                            <SoftBox
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                                p={3}
                            >
                                <SoftBox display="flex" gap={1} alignItems="center">
                                    <SoftTypography variant="caption" color="secondary">
                                        {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredData.length)} of {filteredData.length}
                                    </SoftTypography>
                                    <SoftButton
                                        variant="text"
                                        color="info"
                                        onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                        disabled={page === 0}
                                        iconOnly
                                    >
                                        <KeyboardArrowLeft />
                                    </SoftButton>
                                    <SoftButton
                                        variant="text"
                                        color="info"
                                        onClick={() => setPage(prev => Math.min(Math.ceil(filteredData.length / itemsPerPage) - 1, prev + 1))}
                                        disabled={page >= Math.ceil(filteredData.length / itemsPerPage) - 1}
                                        iconOnly
                                    >
                                        <KeyboardArrowRight />
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

Name.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
};

Description.propTypes = {
    description: PropTypes.string.isRequired,
};

export default OrgPassword;