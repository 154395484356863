import React, { useState, useEffect } from 'react';
import { AppBar, Tab, Tabs, Card, Grid } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import breakpoints from "assets/theme/base/breakpoints";
import SoftBox from 'components/SoftBox';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

function TabNavigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [currentUser, setCurrentUser] = useState(null);
  const [permission, setPermission] = useState(false);
  const [userDepartment, setUserDepartment] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const getInitialTabValue = (path) => {
    switch (path) {
      case '/assets-list':
        return 0;
      case '/my-assets':
        return 1;
      case '/assets-requests':
        return 2;
      case '/all-assets':
        return 3;
      default:
        return 0;
    }
  };

  const [tabValue, setTabValue] = useState(getInitialTabValue(location.pathname));

  // Update tab value when location changes
  useEffect(() => {
    setTabValue(getInitialTabValue(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
    if (sessionDetails && sessionDetails.user) {
      setCurrentUser(sessionDetails.user);
      setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
    }
    const accessLevel = localStorage.getItem('Access Level');
    setPermission(parseInt(accessLevel));
    const Department = localStorage.getItem('Depart Details');
    setUserDepartment(Department);
  }, []);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const shouldShowActions = () => {
    if (permission === 1100 || permission === 1000) {
      return false;
    }
    return isSuperAdmin || permission === 1111;
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/assets-list");
        break;
      case 1:
        navigate("/my-assets");
        break;
      case 2:
        navigate("/assets-requests");
        break;
      case 3:
        navigate("/all-assets");
        break;
      default:
        break;
    }
  };

  return (
    <SoftBox>
      <DashboardNavbar/>
      <Card>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6} lg={12} sx={{ ml: "0" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ background: "transparent" }}
              >
                <Tab
                  value={0}
                  label="Stores"
                  sx={{
                    color: tabValue === 0 ? "info.main" : "text.secondary",
                    fontWeight: tabValue === 0 ? "bold" : "normal",
                    borderBottom: tabValue === 0 ? "4px solid" : "none",
                    borderColor: "info.main",
                  }}
                />
                <Tab
                  value={1}
                  label="My Assets"
                  sx={{
                    color: tabValue === 1 ? "info.main" : "text.secondary",
                    fontWeight: tabValue === 1 ? "bold" : "normal",
                    borderBottom: tabValue === 1 ? "4px solid" : "none",
                    borderColor: "info.main",
                  }}
                />
                {shouldShowActions() && (
                  <Tab
                    value={2}
                    label="Verify Requests"
                    sx={{
                      color: tabValue === 2 ? "info.main" : "text.secondary",
                      fontWeight: tabValue === 2 ? "bold" : "normal",
                      borderBottom: tabValue === 2 ? "4px solid" : "none",
                      borderColor: "info.main",
                    }}
                  />
                )}
                {shouldShowActions() && (
                  <Tab
                    value={3}
                    label="All Assets"
                    sx={{
                      color: tabValue === 3 ? "info.main" : "text.secondary",
                      fontWeight: tabValue === 3 ? "bold" : "normal",
                      borderBottom: tabValue === 3 ? "4px solid" : "none",
                      borderColor: "info.main",
                    }}
                  />
                )}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
  );
}

export default TabNavigation;


// import React, { useState, useEffect } from 'react';
// import { AppBar, Tab, Tabs, Card, Grid } from '@mui/material';
// import { useLocation } from "react-router-dom";
// import breakpoints from "assets/theme/base/breakpoints";
// import SoftBox from 'components/SoftBox';
// import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
// import AssetsRequests from './assets-requests';
// import AllAllocatedAsset from './allAssets';
// import AssetList from './assets-list';
// import MyAssets from './my-assets';

// function TabNavigation() {
//   const location = useLocation();
//   const [tabsOrientation, setTabsOrientation] = useState("horizontal");
//   const [currentUser, setCurrentUser] = useState(null);
//   const [permission, setPermission] = useState(false);
//   const [userDepartment, setUserDepartment] = useState("");
//   const [isSuperAdmin, setIsSuperAdmin] = useState(false);
//   const [activeTab, setActiveTab] = useState(0);

//   useEffect(() => {
//     const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
//     if (sessionDetails && sessionDetails.user) {
//       setCurrentUser(sessionDetails.user);
//       setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
//     }
//     const accessLevel = localStorage.getItem('Access Level');
//     setPermission(parseInt(accessLevel));
//     const Department = localStorage.getItem('Depart Details');
//     setUserDepartment(Department);
//   }, []);

//   useEffect(() => {
//     function handleTabsOrientation() {
//       return window.innerWidth < breakpoints.values.sm
//         ? setTabsOrientation("vertical")
//         : setTabsOrientation("horizontal");
//     }
//     window.addEventListener("resize", handleTabsOrientation);
//     handleTabsOrientation();
//     return () => window.removeEventListener("resize", handleTabsOrientation);
//   }, [tabsOrientation]);

//   const shouldShowActions = () => {
//     if (permission === 1100 || permission === 1000) {
//       return false;
//     }
//     return isSuperAdmin || permission === 1111;
//   };

//   const handleSetTabValue = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   const renderActiveComponent = () => {
//     switch (activeTab) {
//       case 0:
//         return <AssetList />;
//       case 1:
//         return <MyAssets />;
//       case 2:
//         return <AssetsRequests/>;
//       case 3:
//         return <AllAllocatedAsset/>;
//       default:
//         return <AssetList />;
//     }
//   };

//   return (
//     <SoftBox>
//       <DashboardNavbar/>
//       <Card>
//         <Grid container spacing={3} alignItems="center">
//           <Grid item xs={12} md={6} lg={12} sx={{ ml: "0" }}>
//             <AppBar position="static">
//               <Tabs
//                 orientation={tabsOrientation}
//                 value={activeTab}
//                 onChange={handleSetTabValue}
//                 sx={{ background: "transparent" }}
//               >
//                 <Tab
//                   label="Stores"
//                   sx={{
//                     color: activeTab === 0 ? "info.main" : "text.secondary",
//                     fontWeight: activeTab === 0 ? "bold" : "normal",
//                     borderBottom: activeTab === 0 ? "2px solid" : "none",
//                     borderColor: "info.main",
//                   }}
//                 />
//                 <Tab
//                   label="My Assets"
//                   sx={{
//                     color: activeTab === 1 ? "info.main" : "text.secondary",
//                     fontWeight: activeTab === 1 ? "bold" : "normal",
//                     borderBottom: activeTab === 1 ? "2px solid" : "none",
//                     borderColor: "info.main",
//                   }}
//                 />
//                 {shouldShowActions() && (
//                   <Tab
//                     label="Verify Requests"
//                     sx={{
//                       color: activeTab === 2 ? "info.main" : "text.secondary",
//                       fontWeight: activeTab === 2 ? "bold" : "normal",
//                       borderBottom: activeTab === 2 ? "2px solid" : "none",
//                       borderColor: "info.main",
//                     }}
//                   />
//                 )}
//                 {shouldShowActions() && (
//                   <Tab
//                     label="All Assets"
//                     sx={{
//                       color: activeTab === 3 ? "info.main" : "text.secondary",
//                       fontWeight: activeTab === 3 ? "bold" : "normal",
//                       borderBottom: activeTab === 3 ? "2px solid" : "none",
//                       borderColor: "info.main",
//                     }}
//                   />
//                 )}
//               </Tabs>
//             </AppBar>
//           </Grid>
//         </Grid>
//       </Card>
//       {renderActiveComponent()}
//     </SoftBox>
//   );
// }

// export default TabNavigation;