
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import curved6 from "assets/images/curved-images/curved14.jpg";
import { Card, Grid } from "@mui/material";
import logo from 'assets/images/c4e.png';
import axios from "axios";
import { APIData, org } from 'authentication/APIData';
import { toast } from "react-toastify";
import Swal from 'sweetalert2';

function SignUp() {
  const navigate = useNavigate()
  const [agreement, setAgreement] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [orgLogo, setOrgLogo] = useState();
  const [errors, setErrors] = useState({});
  const [otpVisible, setOtpVisible] = useState(false);
  const [otpButtonVisible, setOtpButtonVisible] = useState(false);

  useEffect(() => {
    const handleSignOut = () => {
      localStorage.clear();
      navigate("/authentication/sign-up");
    };
    handleSignOut();
  }, []);

  useEffect(()=>{
    const fetchOrgData = async () => {
      try {
        const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`);
        if (!response.ok) {
          throw new Error("Failed to fetch organization data");
        }
        const data = await response.json();
        console.log(data);
        setOrgLogo(data.orgLogo);
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    };
    fetchOrgData()
  },[])
  
  const handleSetAgreement = () => setAgreement(!agreement);

  const validateName = (name) => /^[A-Za-z\s]+$/.test(name);
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePhoneNumber = (phone) => /^[0-9]{10}$/.test(phone);
  const validateOtp = (otp) => otp.trim() !== "" && /^[0-9]+$/.test(otp);
  const validatePassword = (password) => password.length >= 8;

  const handleInputChange = (setter, fieldName) => (event) => {
    setter(event.target.value);
    setErrors({ ...errors, [event.target.name]: "" });


    if (fieldName === "email") {
      if (validateEmail(event.target.value)) {
        setOtpButtonVisible(true);
      } else {
        setOtpButtonVisible(false);
      }
    }
  };

  const handleGetOtp = () => {
    if (validateEmail(email)) {
      axios
        .get(APIData.api + "users/otp/detail?emailId=" + email + `&org=${org}`, {
          headers: APIData.headers,
        })
        .then((response) => {
          if (response.data.status.toString().toLowerCase() === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: response.data.description,
              confirmButtonText: 'Okay'
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: response.data.errorDesc,
              confirmButtonText: 'Okay'
            });
          }

        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: "Please Check Your Email",
            confirmButtonText: 'Okay'
          });
        });
      setOtpVisible(true);
      return true;
    } else {
      setErrors({ ...errors, email: "Invalid email format." });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = {};
    if (!agreement) newErrors.agreement = "You must agree to the terms.";
    if (!name) newErrors.name = "Name is required.";
    else if (!validateName(name)) newErrors.name = "Name should not contain numbers.";
    if (!email) newErrors.email = "Email is required.";
    else if (!validateEmail(email)) newErrors.email = "Invalid email format.";
    if (!phoneNumber) newErrors.phoneNumber = "Phone number is required.";
    else if (!validatePhoneNumber(phoneNumber)) newErrors.phoneNumber = "Phone number must be 10 digits.";
    // if (!otp) newErrors.otp = "OTP is required.";
    // else if (!validateOtp(otp)) newErrors.otp = "OTP must be digits only.";
    if (!password) newErrors.password = "Password is required.";
    else if (!validatePassword(password)) newErrors.password = "Password must be at least 8 characters.";
    if (!confirmPassword) newErrors.confirmPassword = "Confirm Password is required.";
    else if (password !== confirmPassword) newErrors.confirmPassword = "Passwords do not match.";
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      if (!otpVisible) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning!',
          text: "Before submitting click on get otp",
          confirmButtonText: 'Okay'
        });
        return;
      }
      else if (!otp) {
        newErrors.otp = "OTP is required.";
        setErrors(newErrors);
        return;
      } else if (!validateOtp(otp)) {
        newErrors.otp = "OTP must be digits only.";
        setErrors(newErrors);
        return;
      }
      var sendstate = {
        org: org,
        user_name: name,
        user_email: email,
        user_password: password,
        user_phone_number: phoneNumber,
        otp: otp,
      };
      setTimeout(toast("Please Wait!"), 5000);
      axios
        .post(APIData.api + "users/", sendstate, { headers: APIData.headers })
        .then((response) => {
          if (response.data.status.toString().toLowerCase() === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: response.data.description,
              confirmButtonText: 'Okay'
            });
            localStorage.clear();
            navigate("/authentication/sign-in", { replace: true });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: response.data.errorDesc,
              confirmButtonText: 'Okay'
            });
          }
          console.log(response.status);
        })
        .catch((error) => {
          toast("It's Time To Grab A Coffee");
          console.log(error);
        });
      console.log("Form submitted successfully!");
    }
  };

  return (
    <BasicLayout
      title="Welcome!"
      description="Use these awesome forms to login or create a new account in your project for free."
      image={curved6}
    >
      <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
        <Grid item>
          <Card sx={{ width: '500px', height: 'auto' }}>
            <SoftBox p={3} mb={1} textAlign="center">
              <SoftTypography variant="h5" fontWeight="medium">
                Register with
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {/* <Socials /> */}
              <img src={orgLogo} style={{ height: "100px", width: "100px", objectFit: "contain" }} />
            </SoftBox>
            <SoftBox pt={2} pb={3} px={3}>
              <SoftBox component="form" role="form" onSubmit={handleSubmit}>
                <SoftBox mb={2}>
                  <SoftInput
                    placeholder="Name"
                    value={name}
                    onChange={handleInputChange(setName)}
                    name="name"
                    // error={!!errors.name}
                    helperText={errors.name}
                  />
                  <SoftTypography style={{ color: 'red', fontSize: '12px' }}>
                    {errors.name}
                  </SoftTypography>
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftInput
                    type="phonenumber"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={handleInputChange(setPhoneNumber)}
                    name="phoneNumber"
                    // error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber}
                  />
                  <SoftTypography style={{ color: 'red', fontSize: '12px' }}>
                    {errors.phoneNumber}
                  </SoftTypography>
                </SoftBox>
                <SoftBox mb={2} display="flex" alignItems="center">
                  <SoftInput
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={handleInputChange(setEmail, "email")}
                    name="email"
                    // error={!!errors.email}
                    helperText={errors.email}
                    sx={{ flex: 1, mr: 1 }}
                  />
                  <SoftTypography style={{ color: 'red', fontSize: '12px' }}>
                    {errors.email}
                  </SoftTypography>
                  {otpButtonVisible && (
                    <SoftButton onClick={handleGetOtp} variant="contained" color="light">
                      <b>Get OTP</b>
                    </SoftButton>
                  )}
                </SoftBox>
                {otpVisible && (
                  <SoftBox mb={2}>
                    <SoftInput
                      type="otp"
                      placeholder="OTP"
                      value={otp}
                      onChange={handleInputChange(setOtp)}
                      name="otp"
                      // error={!!errors.otp}
                      helperText={errors.otp}
                    />
                    <SoftTypography style={{ color: 'red', fontSize: '12px' }}>
                      {errors.otp}
                    </SoftTypography>
                  </SoftBox>
                )}
                <SoftBox mb={2}>
                  <SoftInput
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={handleInputChange(setPassword)}
                    name="password"
                    // error={!!errors.password}
                    helperText={errors.password}
                  />
                  <SoftTypography style={{ color: 'red', fontSize: '12px' }}>
                    {errors.password}
                  </SoftTypography>
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftInput
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={handleInputChange(setConfirmPassword)}
                    name="confirmPassword"
                    // error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                  />
                  <SoftTypography style={{ color: 'red', fontSize: '12px' }}>
                    {errors.confirmPassword}
                  </SoftTypography>
                </SoftBox>
                <SoftBox display="flex" alignItems="center">
                  <Checkbox checked={agreement} onChange={handleSetAgreement} />
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    onClick={handleSetAgreement}
                    sx={{ cursor: "pointer", userSelect: "none" }}
                  >
                    &nbsp;&nbsp;I agree to the&nbsp;
                  </SoftTypography>
                  <SoftTypography
                    component="a"
                    variant="button"
                    fontWeight="bold"
                    textGradient
                  >
                    Terms and Conditions
                  </SoftTypography>
                </SoftBox>
                {errors.agreement && (
                  <SoftTypography variant="caption" color="error">
                    {errors.agreement}
                  </SoftTypography>
                )}
                <SoftBox mt={4} mb={1}>
                  <SoftButton type="submit" variant="gradient" color="dark" fullWidth>
                    Sign Up
                  </SoftButton>
                </SoftBox>
                <SoftBox mt={3} textAlign="center">
                  <SoftTypography variant="button" color="text" fontWeight="regular">
                    Already have an account?&nbsp;
                    <SoftTypography
                      component={Link}
                      to="/authentication/sign-in"
                      variant="button"
                      color="dark"
                      fontWeight="bold"
                      textGradient
                    >
                      Sign in
                    </SoftTypography>
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </BasicLayout>
  );
}

export default SignUp;

