// import { useEffect } from "react";
// import { useLocation, NavLink } from "react-router-dom";
// import PropTypes from "prop-types";
// import List from "@mui/material/List";
// import Divider from "@mui/material/Divider";
// import Link from "@mui/material/Link";
// import Icon from "@mui/material/Icon";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
// import SidenavRoot from "examples/Sidenav/SidenavRoot";
// import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
// import { useSoftUIController, setMiniSidenav } from "context";

// function Sidenav({ color, brand, brandName, routes, ...rest }) {  const [controller, dispatch] = useSoftUIController();
//   const { miniSidenav, transparentSidenav } = controller;
//   const location = useLocation();
//   const { pathname } = location;
//   const collapseName = pathname.split("/").slice(1)[0];

//   const closeSidenav = () => setMiniSidenav(dispatch, true);

//   useEffect(() => {
//     function handleMiniSidenav() {
//       setMiniSidenav(dispatch, window.innerWidth < 1200);
//     }
//     window.addEventListener("resize", handleMiniSidenav);
//     handleMiniSidenav();
//     return () => window.removeEventListener("resize", handleMiniSidenav);
//   }, [dispatch, location]);

//   // Render a single route
//   const renderRoute = (routeItem) => {
//     const { type, name, icon, title, noCollapse, key, route, href, children } = routeItem;

//     if (type === "collapse") {
//       const childRoutes = children ? children.map(child => renderRoute(child)) : null;
      
//       const collapseContent = (
//         <SidenavCollapse
//           color={color}
//           key={key}
//           name={name}
//           icon={icon}
//           active={key === collapseName}
//           noCollapse={noCollapse}
//           route={route}
//         >
//           {childRoutes}
//         </SidenavCollapse>
//       );

//       return collapseContent;
//     }
//     if (type === "title") {
//       return (
//         <SoftTypography
//           key={key}
//           display="block"
//           variant="caption"
//           fontWeight="bold"
//           textTransform="uppercase"
//           opacity={0.6}
//           pl={3}
//           mt={2}
//           mb={1}
//           ml={1}
//         >
//           {title}
//         </SoftTypography>
//       );
//     } 
    
//     if (type === "divider") {
//       return <Divider key={key} />;
//     }

//     return null;
//   };

//   return (
//     <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
//       <SoftBox pt={3} pb={1} px={4} textAlign="center">
//         <SoftBox
//           display={{ xs: "block", xl: "none" }}
//           position="absolute"
//           top={0}
//           right={0}
//           p={1.625}
//           onClick={closeSidenav}
//           sx={{ cursor: "pointer" }}
//         >
//           <SoftTypography variant="h6" color="secondary">
//             <Icon sx={{ fontWeight: "bold" }}>close</Icon>
//           </SoftTypography>
//         </SoftBox>
//         <SoftBox component={NavLink} to="/dashboard" display="flex" alignItems="center">
//           {brand && <SoftBox component="img" src={brand} alt="Soft UI Logo" width="2rem" />}
//           <SoftBox
//             width={!brandName && "100%"}
//             sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
//           >
//             <SoftTypography component="h6" variant="button" fontWeight="medium">
//               {brandName}
//             </SoftTypography>
//           </SoftBox>
//         </SoftBox>
//       </SoftBox>
//       <Divider />
//       <List>
//         {routes.map((route) => renderRoute(route))}
//       </List>
//     </SidenavRoot>
//   );
// }

// Sidenav.defaultProps = {
//   color: "info",
//   brand: "",
// };

// Sidenav.propTypes = {
//   color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
//   brand: PropTypes.string,
//   brandName: PropTypes.string.isRequired,
//   routes: PropTypes.arrayOf(PropTypes.object).isRequired,
// };

// export default Sidenav;

import { useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import { useSoftUIController, setMiniSidenav } from "context";

function Sidenav({ color, brand, brandName, routes, ...rest }) {  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }
    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const renderRoute = (routeItem) => {
    const { type, name, icon, title, noCollapse, key, route, href, children } = routeItem;

    if (type === "collapse") {
      const childRoutes = children ? children.map(child => renderRoute(child)) : null;
      
      const collapseContent = (
        <SidenavCollapse
          color={color}
          key={key}
          name={name}
          icon={icon}
          active={key === collapseName}
          noCollapse={noCollapse}
          route={route}
        >
          {childRoutes}
        </SidenavCollapse>
      );

      return collapseContent;
    }
    if (type === "title") {
      return (
        <SoftTypography
          key={key}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          opacity={0.6}
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </SoftTypography>
      );
    } 
    
    if (type === "divider") {
      return <Divider key={key} />;
    }

    return null;
  };

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      <SoftBox 
        sx={{ 
          position: 'relative',
          height: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {/* Fixed Header */}
        <SoftBox 
          sx={{ 
            position: 'sticky',
            top: 0,
            backgroundColor: transparentSidenav ? 'transparent' : 'background.paper',
            zIndex: 1100
          }}
        >
          <SoftBox pt={3} pb={1} px={4} textAlign="center">
            <SoftBox
              display={{ xs: "block", xl: "none" }}
              position="absolute"
              top={0}
              right={0}
              p={1.625}
              onClick={closeSidenav}
              sx={{ cursor: "pointer" }}
            >
              <SoftTypography variant="h6" color="secondary">
                <Icon sx={{ fontWeight: "bold" }}>close</Icon>
              </SoftTypography>
            </SoftBox>
            <SoftBox component={NavLink} to="/dashboard" display="flex" alignItems="center">
              {brand && <SoftBox component="img" src={brand} alt="Soft UI Logo" width="2rem" />}
              <SoftBox
                width={!brandName && "100%"}
                sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
              >
                <SoftTypography component="h6" variant="button" fontWeight="medium">
                  {brandName}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <Divider />
        </SoftBox>

        {/* Scrollable Content */}
        <SoftBox 
          sx={{ 
            flex: 1,
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          <List>
            {routes.map((route) => renderRoute(route))}
          </List>
        </SoftBox>
      </SoftBox>
    </SidenavRoot>
  );
}

Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;