// import React, { useEffect, useState } from 'react';
// import { select } from 'd3';
// import { OrgChart } from 'd3-org-chart';
// import { APIData, org } from 'authentication/APIData';
// import axios from 'axios';
// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
// import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
// import SoftBox from 'components/SoftBox';
// import SoftTypography from 'components/SoftTypography';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import Button from '@mui/material/Button';
// import CustomSelect from 'assets/theme/components/Select/CustomSelect';
// import Swal from 'sweetalert2';
// import image from "../../assets/images/profile image.jpg";

// const OrgHierarchy = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [currentNodeId, setCurrentNodeId] = useState(null);
//   const [dialogType, setDialogType] = useState('');
//   const [allEmails, setAllEmails] = useState([]);
//   const [selectedEmail, setSelectedEmail] = useState('');

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(`${APIData.api}organization-hierarchy?org=${org}`, {
//         headers: APIData.headers,
//       });
//       const updatedData = response.data.map(item => {
//         if (item.email === item.supervisorEmail) {
//           item.parentId = null;
//         } else {
//           item.parentId = item.supervisorEmail;
//         }
//         item.id = item.email;
//         delete item.email;
//         delete item.supervisorEmail;
//         return item;
//       });
//       setData(updatedData);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const fetchAllEmails = () => {
//     const url = APIData.api + `organization-hierarchy?org=${org}`;
//     axios
//       .get(url, { headers: APIData.headers })
//       .then((resp) => {
//         const emails = resp.data.map(item => item.email);
//         setAllEmails(emails);
//       })
//       .catch((err) => {
//         Swal.fire({
//             icon: 'error',
//             title: 'Error Fetching Emails',
//             text: 'Please try again later.',
//             confirmButtonText: 'OK'
//         });
//     });
//       };

//   useEffect(() => {
//     fetchData();
//     fetchAllEmails();
//   }, []);

//   useEffect(() => {
//     if (data.length > 0) {
//       const chartContainer = select('.chart-container');
//       const chart = new OrgChart()
//         .container('.chart-container')
//         .data(data)
//         .nodeWidth(() => 250)
//         .initialZoom(0.7)
//         .nodeHeight(() => 175)
//         .childrenMargin(() => 40)
//         .compactMarginBetween(() => 15)
//         .compactMarginPair(() => 80)
//         .nodeContent((d) => `
//           <div style="padding-top:30px;background-color:none;margin-left:1px;height:${d.height}px;border-radius:2px;overflow:visible">
//             <div style="height:${d.height - 32}px;padding-top:0px;background-color:white;border:1px solid lightgray;">
//               <img src="${d.data.imageUrl ?? `${image}`}" style="margin-top:-30px;margin-left:${d.width / 2 - 30}px;border-radius:100px;width:60px;height:60px;" />
//               <div style="margin-right:10px;margin-top:15px;float:right">${d.data.id}</div>
//               <div style="margin-top:-30px;background-color:#3AB6E3;height:10px;width:${d.width - 2}px;border-radius:1px"></div>
//               <div style="padding:20px; padding-top:35px;text-align:center">
//                 <div style="color:#111672;font-size:16px;font-weight:bold">${d.data.name}</div>
//                 <div style="color:#404040;font-size:16px;margin-top:4px">${d.data.entity}</div>
//               </div> 
//               <div style="display:flex;justify-content:space-between;padding-left:15px;padding-right:15px;">
//                 <div> Manages: ${d.data._directSubordinates || 0} 👤</div>  
//                 <div> Oversees: ${d.data._totalSubordinates || 0} 👤</div>
//               </div>
//                <div style="padding: 10px; text-align: center;">
//                 <button style="margin-right: 100px;" data-id="${d.data.id}" class=" edit-btn">Update</button>
//                 <button data-id="${d.data.id}" class="delete-btn">Delete</button>
//               </div>
//             </div>     
//           </div>
//         `)
//         .onExpandOrCollapse(() => {
//           attachEventListeners();
//         })
//         .render();
//       attachEventListeners();
//     }
//   }, [data]);

//   const attachEventListeners = () => {
//     select('.chart-container').selectAll('.edit-btn')
//       .on('click', function () {
//         const id = this.getAttribute('data-id');
//         setCurrentNodeId(id);
//         setDialogType('edit');
//         setOpenDialog(true);
//       });

//     select('.chart-container').selectAll('.delete-btn')
//       .on('click', function () {
//         const id = this.getAttribute('data-id');
//         setCurrentNodeId(id);
//         setDialogType('delete');
//         setOpenDialog(true);
//       });
//   };

//   const handleClose = () => {
//     setOpenDialog(false);
//     setCurrentNodeId(null);
//     setSelectedEmail('');
//   };

//   const handleConfirm = () => {
//     if (selectedEmail == '') {
//       return;
//     }
//     else if (dialogType === 'edit') {
//       console.log('Edit node with ID:', selectedEmail.label);
//       const personDetails = data.find((employee) => employee.id === currentNodeId);

//       const url = APIData.api + 'organization-hierarchy';
//       const requestData = {
//         email: personDetails.id,
//         designation: personDetails.designation,
//         entity: personDetails.entity,
//         image: personDetails.image,
//         name: personDetails.name,
//         supervisorEmail: selectedEmail.label,
//         username: personDetails.username,
//         org: org
//       };
//       axios
//         .put(url, requestData, { headers: APIData.headers })
//         .then((resp) => {
//           fetchData();
//           Swal.fire({
//             icon: "success",
//             title: "Update Successfull",
//             showConfirmButton: false,
//           });
//         })
//         .catch((err) => {
//           Swal.fire({
//             icon: "error",
//             title: "Failed to update",
//             showConfirmButton: false,
//           });
//         })
//         .finally(() => { }
//         );
//     } else if (dialogType === 'delete') {
//       console.log('Delete node with ID:', selectedEmail);
//       const url = APIData.api + `organization-hierarchy/update-supervisor/delete/id?emailId=${currentNodeId}&newSupervisorEmail=${selectedEmail.label}`;
//       const requestData = {
//         emailId: currentNodeId,
//         newSupervisorEmail: selectedEmail.label
//       };
//       axios
//         .delete(url, { data: requestData, headers: APIData.headers })
//         .then((resp) => {
//           fetchData();
//           Swal.fire({
//             icon: "success",
//             title: "Delete Successfull",
//             showConfirmButton: false,
//           });
//         })
//         .catch((err) => {
//           Swal.fire({
//             icon: "error",
//             title: "Failed to delete",
//             showConfirmButton: false,
//           });
//         })
//         .finally(() => { }
//         );
//     }
//     handleClose();
//   };

//   const handleEmailChange = (event) => {
//     setSelectedEmail(event);
//   };

//   const emailOptions = allEmails.map(email => ({ value: email, label: email }));

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <SoftBox pt={2} px={2}>
//         <SoftBox mb={0.5} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//           <SoftTypography variant="h5">Organizational Hierarchy</SoftTypography>
//         </SoftBox>

//         {loading ? <p>Loading...</p> : <div className="chart-container"></div>}
//       </SoftBox>

//       <Dialog open={openDialog} onClose={handleClose}>
//         <DialogTitle>{dialogType === 'edit' ? 'Edit Employee' : 'Delete Employee'}</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             {"Please select the supervisor's email address to allocate the employee under their supervision"}
//           </DialogContentText>

//           <SoftBox mb={2}>
//             <CustomSelect
//               options={emailOptions}
//               value={selectedEmail}
//               onChange={handleEmailChange}
//               placeholder="Select Email"
//             />
//           </SoftBox>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={handleConfirm} color="primary">
//             {dialogType === 'edit' ? 'Update' : 'Delete'}
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </DashboardLayout>
//   );
// };

// export default OrgHierarchy;

import React, { useEffect, useState } from 'react';
import { select } from 'd3';
import { OrgChart } from 'd3-org-chart';
import { APIData, org } from 'authentication/APIData';
import axios from 'axios';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import Swal from 'sweetalert2';
import image from '../../assets/images/profile image.jpg';

const OrgHierarchy = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentNodeId, setCurrentNodeId] = useState(null);
  const [dialogType, setDialogType] = useState('');
  const [allEmails, setAllEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [filteredEmailOptions, setFilteredEmailOptions] = useState([]);

  const getSubordinates = (email, employeeData) => {
    const subordinates = new Set();
    const findSubordinates = (currentEmail) => {
      employeeData.forEach((employee) => {
        if (employee.parentId === currentEmail) {
          subordinates.add(employee.id);
          findSubordinates(employee.id);
        }
      });
    };
    findSubordinates(email);
    return Array.from(subordinates);
  };

  const getPotentialSupervisors = (currentEmail, employeeData) => {
    const subordinates = getSubordinates(currentEmail, employeeData);
    return employeeData
      .filter((employee) => !subordinates.includes(employee.id) && employee.id !== currentEmail)
      .map((employee) => ({
        value: employee.id,
        label: employee.id,
      }));
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${APIData.api}organization-hierarchy?org=${org}`, {
        headers: APIData.headers,
      });
      const updatedData = response.data.map((item) => ({
        id: item.email,
        parentId: item.email === item.supervisorEmail ? null : item.supervisorEmail,
        name: item.name,
        entity: item.entity,
        designation: item.designation,
        imageUrl: item.image,
        username: item.username,
      }));
      setData(updatedData);
      setAllEmails(response.data.map((item) => item.email));
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error Fetching Data',
        text: 'Please try again later.',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const chartContainer = select('.chart-container');
      const chart = new OrgChart()
        .container('.chart-container')
        .data(data)
        .nodeWidth(() => 250)
        .initialZoom(0.7)
        .nodeHeight(() => 175)
        .childrenMargin(() => 40)
        .compactMarginBetween(() => 15)
        .compactMarginPair(() => 80)
        .nodeContent((d) => `
          <div style="padding-top:30px;background-color:none;margin-left:1px;height:${d.height}px;border-radius:2px;overflow:visible">
            <div style="height:${d.height - 32}px;padding-top:0px;background-color:white;border:1px solid lightgray;">
              <img src="${d.data.imageUrl ?? `${image}`}" style="margin-top:-30px;margin-left:${d.width / 2 - 30}px;border-radius:100px;width:60px;height:60px;" />
              <div style="margin-right:10px;margin-top:15px;float:right">${d.data.id}</div>
              <div style="margin-top:-30px;background-color:#3AB6E3;height:10px;width:${d.width - 2}px;border-radius:1px"></div>
              <div style="padding:20px; padding-top:35px;text-align:center">
                <div style="color:#111672;font-size:16px;font-weight:bold">${d.data.name}</div>
                <div style="color:#404040;font-size:16px;margin-top:4px">${d.data.entity}</div>
              </div> 
              <div style="display:flex;justify-content:space-between;padding-left:15px;padding-right:15px;">
                <div> Manages: ${d.data._directSubordinates || 0} 👤</div>  
                <div> Oversees: ${d.data._totalSubordinates || 0} 👤</div>
              </div>
               <div style="padding: 10px; text-align: center;">
                <button style="margin-right: 100px;" data-id="${d.data.id}" class="edit-btn">Update</button>
                <button data-id="${d.data.id}" class="delete-btn">Delete</button>
              </div>
            </div>     
          </div>
        `)
        .onExpandOrCollapse(() => {
          attachEventListeners();
        })
        .render();
      attachEventListeners();
    }
  }, [data]);

  const attachEventListeners = () => {
    select('.chart-container').selectAll('.edit-btn').on('click', function () {
      const id = this.getAttribute('data-id');
      setCurrentNodeId(id);
      setDialogType('edit');
      const potentialSupervisors = getPotentialSupervisors(id, data);
      setFilteredEmailOptions(potentialSupervisors);
      setOpenDialog(true);
    });

    select('.chart-container').selectAll('.delete-btn').on('click', function () {
      const id = this.getAttribute('data-id');
      setCurrentNodeId(id);
      setDialogType('delete');
      const potentialSupervisors = getPotentialSupervisors(id, data);
      setFilteredEmailOptions(potentialSupervisors);
      setOpenDialog(true);
    });
  };

  const handleClose = () => {
    setOpenDialog(false);
    setCurrentNodeId(null);
    setSelectedEmail('');
    setFilteredEmailOptions([]);
  };

    const handleConfirm = () => {
    if (selectedEmail == '') {
      return;
    }
    else if (dialogType === 'edit') {
      console.log('Edit node with ID:', selectedEmail.label);
      const personDetails = data.find((employee) => employee.id === currentNodeId);

      const url = APIData.api + 'organization-hierarchy';
      const requestData = {
        email: personDetails.id,
        designation: personDetails.designation,
        entity: personDetails.entity,
        image: personDetails.image,
        name: personDetails.name,
        supervisorEmail: selectedEmail.label,
        username: personDetails.username,
        org: org
      };
      axios
        .put(url, requestData, { headers: APIData.headers })
        .then((resp) => {
          fetchData();
          Swal.fire({
            icon: "success",
            title: "Update Successfull",
            showConfirmButton: false,
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Failed to update",
            showConfirmButton: false,
          });
        })
        .finally(() => { }
        );
    } else if (dialogType === 'delete') {
      console.log('Delete node with ID:', selectedEmail);
      const url = APIData.api + `organization-hierarchy/update-supervisor/delete/id?emailId=${currentNodeId}&newSupervisorEmail=${selectedEmail.label}`;
      const requestData = {
        emailId: currentNodeId,
        newSupervisorEmail: selectedEmail.label
      };
      axios
        .delete(url, { data: requestData, headers: APIData.headers })
        .then((resp) => {
          fetchData();
          Swal.fire({
            icon: "success",
            title: "Delete Successfull",
            showConfirmButton: false,
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Failed to delete",
            showConfirmButton: false,
          });
        })
        .finally(() => { }
        );
    }
    handleClose();
  };

  const handleEmailChange = (event) => {
    setSelectedEmail(event);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={2} px={2}>
        <SoftBox mb={0.5} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <SoftTypography variant="h5">Organizational Hierarchy</SoftTypography>
        </SoftBox>

        {loading ? <p>Loading...</p> : <div className="chart-container"></div>}
      </SoftBox>

      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>{dialogType === 'edit' ? 'Edit Employee' : 'Delete Employee'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"Please select the supervisor's email address to allocate the employee under their supervision"}
          </DialogContentText>

          <SoftBox mb={2}>
            <CustomSelect
              options={filteredEmailOptions}
              value={selectedEmail}
              onChange={handleEmailChange}
              placeholder="Select Email"
              isSearchable
            />
          </SoftBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default OrgHierarchy;