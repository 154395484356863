import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { APIData, org } from '../authentication/APIData';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftAvatar from "components/SoftAvatar";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import { KeyboardArrowLeft, KeyboardArrowRight, ExpandMore, ExpandLess } from '@mui/icons-material';
import { IoMdMail } from "react-icons/io";
import { AiFillDelete } from "react-icons/ai";
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import ExpandButtonWithTooltip from './ExpandButtonWithTooltip';


const months = [
    { month: "All Months", value: "all" },
    { month: "January", value: "January" },
    { month: "February", value: "February" },
    { month: "March", value: "March" },
    { month: "April", value: "April" },
    { month: "May", value: "May" },
    { month: "June", value: "June" },
    { month: "July", value: "July" },
    { month: "August", value: "August" },
    { month: "September", value: "September" },
    { month: "October", value: "October" },
    { month: "November", value: "November" },
    { month: "December", value: "December" },
];

const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));

const MarksData = () => {
    const [loading, setLoading] = useState(false);
    const [sortField, setSortField] = useState("");
    const [sortDirection, setSortDirection] = useState("asc");
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");
    const [mainData, setMainData] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [displayGraphButton, setDisplayGraphButton] = useState(false);
    const [studentId, setStudentId] = useState(null);
    const [expandedColumns, setExpandedColumns] = useState(false);
    const [enrolledData, setEnrolledData] = useState([]);
    const [filters, setFilters] = useState({
        month: null,
        type: null,
        grade: null,
        student: null
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            const url = APIData.api + `marks?org=${org}`;
            const response = await fetch(url, { headers: APIData.headers });
            const data = await response.json();
            setMainData(data);
            setTasks(data);
            setLoading(false);
        } catch (err) {
            toast.error(err.message || "Error fetching data");
            setLoading(false);
        }
    };

    const applyFilters = async () => {
        try {
            setLoading(true);
            let filteredData = [...mainData]; // Start with all data

            // Apply filters one by one
            if (filters.month?.value && filters.month.value !== "all") {
                filteredData = filteredData.filter(item =>
                    item.months?.toLowerCase() === filters.month.value.toLowerCase()
                );
            }

            if (filters.type?.value && filters.type.value !== "all") {
                filteredData = filteredData.filter(item =>
                    item.test_type?.toLowerCase() === filters.type.value.toLowerCase()
                );
            }

            if (filters.grade?.value && filters.grade.value !== "all") {
                filteredData = filteredData.filter(item =>
                    item.grade?.toString() === filters.grade.value.toString()
                );
            }

            if (filters.student?.value && filters.student.value !== "all") {
                filteredData = filteredData.filter(item =>
                    item.student_id?.toString() === filters.student.value.toString()
                );
            }

            // Update the tasks with filtered data
            setTasks(filteredData);

            // Update graph button visibility
            setDisplayGraphButton(filters.student?.value && filters.student.value !== "all");
            setStudentId(filters.student?.value !== "all" ? filters.student.value : null);

            console.log('Applied Filters:', filters);
            console.log('Filtered Data:', filteredData);

        } catch (error) {
            console.error('Filter Error:', error);
            toast.error("Error applying filters");
        } finally {
            setLoading(false);
        }
    };

    // Effect to apply filters when they change
    useEffect(() => {
        if (Object.values(filters).some(filter => filter !== null)) {
            applyFilters();
        } else {
            // If all filters are cleared, reset to original data
            setTasks(mainData);
        }
    }, [filters]);

    // Updated filter handlers with logging
    const handleMonthFilter = (selectedOption) => {
        console.log('Month Filter Selected:', selectedOption);
        setFilters(prev => ({
            ...prev,
            month: selectedOption
        }));
    };

    const handleTypeFilter = (selectedOption) => {
        console.log('Type Filter Selected:', selectedOption);
        setFilters(prev => ({
            ...prev,
            type: selectedOption
        }));
    };

    const handleGradeFilter = (selectedOption) => {
        console.log('Grade Filter Selected:', selectedOption);
        setFilters(prev => ({
            ...prev,
            grade: selectedOption
        }));
    };

    const handleStudentFilter = (selectedOption) => {
        console.log('Student Filter Selected:', selectedOption);
        setFilters(prev => ({
            ...prev,
            student: selectedOption
        }));
    };

    // Function to clear all filters
    const clearFilters = () => {
        setFilters({
            month: null,
            type: null,
            grade: null,
            student: null
        });
        setTasks(mainData);
    };

    const sendEmail = async (data) => {
        setLoading(true);
        try {
            await axios.post(APIData.api + "marks/email/", data, { headers: APIData.headers });
            // toast("Email sent Successfully");
            Swal.fire({
                icon: "success",
                title: "Email sent Successfully" || response,
                showConfirmButton: false,
                timer: 1500
            });
            setLoading(false);
        } catch (error) {
            // toast("Error sending email");
            Swal.fire({
                icon: "error",
                title: "Failed",
                text: "Error sending email",
                footer: error.response.data
            });
            setLoading(false);
        }
    };

    const handleDelete = async (data) => {
        Swal.fire({
            title: `Are you sure you want to delete ${data.student_id}'s  ${data.test_type} marks`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "success",
            cancelButtonColor: "error",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(APIData.api + 'marks/' + data.id, { headers: APIData.headers });
                    if (response.data.status.toLowerCase() === "success") {
                        Swal.fire({
                            icon: "success",
                            title: `${data.student_id}'s  ${data.test_type} marks Deleted Successfully`,
                            showConfirmButton: false,
                            timer: 1500
                        });
                        setTasks(prevTasks => prevTasks.filter(t => t.id !== data.id));
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Failed",
                            text: response.data.errorDesc,
                        });

                    }
                } catch (error) {
                    // toast("Error occurred while deleting record.");
                    Swal.fire({
                        icon: "error",
                        title: "Failed",
                        text: "Error occurred while deleting record",
                    });
                }
            }
        });
    };


    const getVisibleColumns = () => {
        const basicColumns = [
            { name: "Student ID", align: "left" },
            { name: "Grade", align: "left" },
            { name: "Test Type", align: "left" },
            { name: "Total", align: "center" },
            { name: "Percentage", align: "center" },
            { name: "Action", align: "center" }
        ];

        const extendedColumns = [
            ...basicColumns.slice(0, -1),
            { name: "First Lang", align: "center" },
            { name: "Second Lang", align: "center" },
            { name: "Third Lang", align: "center" },
            { name: "Science", align: "center" },
            { name: "Social", align: "center" },
            { name: "Mathematics", align: "center" },
            { name: "Max Score", align: "center" },
            { name: "Test Date", align: "center" },
            // { name: "Month", align: "center" },
            basicColumns[basicColumns.length - 1]
        ];

        return expandedColumns ? extendedColumns : basicColumns;
    };

    const filteredTasks = tasks.filter(task =>
        Object.values(task).some(value =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const sortedTasks = [...filteredTasks].sort((a, b) => {
        if (!sortField) return 0;

        const aValue = a[sortField];
        const bValue = b[sortField];

        if (sortDirection === 'asc') {
            return aValue > bValue ? 1 : -1;
        } else {
            return aValue < bValue ? 1 : -1;
        }
    });

    const StudentCell = ({ studentId }) => {
        const studentData = enrolledData.find(s => s.student_id === studentId) || {};
        const name = studentData.name || studentId;

        return (
            <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                <SoftBox mr={2}>
                    <SoftAvatar
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                        alt={name}
                        size="sm"
                        variant="rounded"
                    />
                </SoftBox>
                <SoftBox display="flex" flexDirection="column">
                    <SoftTypography variant="button" fontWeight="medium">
                        {name}
                    </SoftTypography>

                </SoftBox>
            </SoftBox>
        );
    };

    StudentCell.propTypes = {
        studentId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    };

    const paginatedTasks = sortedTasks.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const rows = paginatedTasks.map((task) => ({
        "Student ID": <StudentCell studentId={task.student_id} />,

        "Grade": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {task.grade}
            </SoftTypography>
        ),
        "Test Type": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {task.test_type}
            </SoftTypography>
        ),
        ...(expandedColumns ? {
            "Science": (
                <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                    {task.science}
                </SoftTypography>
            ),
            "Social": (
                <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                    {task.social}
                </SoftTypography>
            ),
            "Mathematics": (
                <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                    {task.mathematics}
                </SoftTypography>
            ),
            "First Lang": (
                <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                    {task.first_language}
                </SoftTypography>
            ),
            "Second Lang": (
                <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                    {task.second_language}
                </SoftTypography>
            ),
            "Third Lang": (
                <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                    {task.third_language}
                </SoftTypography>
            ),
            "Max Score": (
                <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                    {task.max_score}
                </SoftTypography>
            ),
            "Test Date": (
                <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                    {task.test_date}
                </SoftTypography>
            ),
            // "Month": (
            //     <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            //         {task.months}
            //     </SoftTypography>
            // ),
        } : {}),
        "Total": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {task.total}
            </SoftTypography>
        ),
        "Percentage": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {task.percentage}%
            </SoftTypography>
        ),
        "Action": (
            <SoftBox display="flex" gap={1}>
                <SoftButton
                    variant="text"
                    color="info"
                    onClick={() => sendEmail(task)}
                    iconOnly
                >
                    <IoMdMail />
                </SoftButton>
                {sessiondetails?.userType === "SUPERADMIN" && (
                    <SoftButton
                        variant="text"
                        color="error"
                        onClick={() => handleDelete(task)}
                        iconOnly
                    >
                        <AiFillDelete />
                    </SoftButton>
                )}
            </SoftBox>
        ),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftBox display="flex" alignItems="center">
                                <SoftTypography variant="h6">Marks</SoftTypography>
                                <ExpandButtonWithTooltip>
                                    <SoftButton
                                        variant="text"
                                        color="info"
                                        onClick={() => setExpandedColumns(!expandedColumns)}
                                        iconOnly
                                    >
                                        {expandedColumns ? <ExpandLess /> : <ExpandMore />}
                                    </SoftButton>
                                </ExpandButtonWithTooltip>
                            </SoftBox>
                            {Object.values(filters).some(filter => filter !== null) && (
                                <SoftButton
                                    variant="outlined"
                                    color="info"
                                    size="small"
                                    onClick={clearFilters}
                                >
                                    Clear Filters
                                </SoftButton>
                            )}
                        </SoftBox>

                        <SoftBox px={3} mb={3}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} md={8}>
                                    <SoftBox display="flex" gap={2}>
                                        <CustomSelect
                                            isClearable
                                            value={filters.month}
                                            onChange={handleMonthFilter}
                                            options={[
                                                { value: "all", label: "All Months" },
                                                ...months.filter(m => m.value !== "all")
                                                    .map(m => ({ value: m.value, label: m.month }))
                                            ]}
                                            placeholder="Select Month"
                                        />
                                        <CustomSelect
                                            isClearable
                                            value={filters.type}
                                            onChange={handleTypeFilter}
                                            options={[
                                                { value: "all", label: "All Types" },
                                                ...Array.from(new Set(mainData.map(item => item.test_type)))
                                                    .map(type => ({ value: type, label: type }))
                                            ]}
                                            placeholder="Select Test Type"
                                        />
                                        <CustomSelect
                                            isClearable
                                            value={filters.grade}
                                            onChange={handleGradeFilter}
                                            options={[
                                                { value: "all", label: "All Grades" },
                                                ...Array.from(new Set(mainData.map(item => item.grade)))
                                                    .map(grade => ({ value: grade, label: grade }))
                                            ]}
                                            placeholder="Select Grade"
                                        />
                                        <CustomSelect
                                            isClearable
                                            value={filters.student}
                                            onChange={handleStudentFilter}
                                            options={[
                                                { value: "all", label: "All Students" },
                                                ...Array.from(new Set(mainData.map(item => item.student_id)))
                                                    .map(id => ({ value: id, label: id }))
                                            ]}
                                            placeholder="Select Student"
                                        />
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <SoftBox display="flex" gap={2} justifyContent="flex-end">
                                        <SoftInput
                                            placeholder="Search..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            size="small"
                                        />
                                        <SoftBox display="flex" gap={2}>
                                            <Link to="/createMarks" style={{ textDecoration: 'none' }}>
                                                <SoftButton variant="gradient" color="info" size="small" startIcon={<AddIcon />}>
                                                    New
                                                </SoftButton>
                                            </Link>
                                        </SoftBox>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </SoftBox>

                        <SoftBox sx={{ overflow: 'auto' }}>

                            <Table columns={getVisibleColumns()} rows={rows} />
                            <SoftBox
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                                p={3}
                            >
                                <SoftBox display="flex" gap={1} alignItems="center">
                                    <SoftTypography variant="caption" color="secondary">
                                        {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, sortedTasks.length)} of {sortedTasks.length}
                                    </SoftTypography>
                                    <SoftButton
                                        variant="text"
                                        color="info"
                                        onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                        disabled={page === 0}
                                        iconOnly
                                    >
                                        <KeyboardArrowLeft />
                                    </SoftButton>
                                    <SoftButton
                                        variant="text"
                                        color="info"
                                        onClick={() => setPage(prev => Math.min(Math.ceil(sortedTasks.length / itemsPerPage) - 1, prev + 1))}
                                        disabled={page >= Math.ceil(sortedTasks.length / itemsPerPage) - 1}
                                        iconOnly
                                    >
                                        <KeyboardArrowRight />
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>

                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default MarksData;