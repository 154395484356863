import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import curved9 from "assets/images/curved-images/curved-6.jpg";
import axios from "axios";
import Swal from 'sweetalert2';
import { APIData, org } from "authentication/APIData";

function CustomerSignIn() {
    const [rememberMe, setRememberMe] = useState(true);
    const [customerId, setCustomerId] = useState("");
    const [password, setPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [showOtpField, setShowOtpField] = useState(false);
    const [errors, setErrors] = useState({ customerId: "", password: "", otp: "" });
    const handleSetRememberMe = () => setRememberMe(!rememberMe);
    const navigate = useNavigate()

    useEffect(() => {
        const handleSignOut = () => {
          localStorage.clear();
          navigate("/customer-sign-in");
        };
        handleSignOut();
      }, []);

    const validateFields = () => {
        const newErrors = { customerId: "", password: "", otp: "" };
        let isValid = true;

        if (!password) {
            newErrors.password = "Password is required.";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
                var formdata = {
                    "org": org,
                    "customerId": customerId,
                    "password": password,
                };
                console.log(formdata);
                
                axios
                    .post(APIData.api + 'customer-onboard/user', formdata, {
                        headers: APIData.headers,
                    })
                    .then((response) => {
                        console.log(response.status);
                        if (response.status===200) {
                            const sessionData = {
                                ...response.data,
                                userType: "CUSTOMER"
                              };

                            localStorage.setItem("sessiondetails", JSON.stringify(sessionData));
                            navigate("/view-tickets");
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error!',
                                text: response.data.errorDesc,
                                confirmButtonText: 'Okay'
                            });
                        }
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: error.response.data,
                            confirmButtonText: 'Okay'
                        });
                        console.log(error);
                    });
    };

    return (
        <CoverLayout
            title="Customer Sign In"
            description="Enter your Customer Id and Password to sign in"
            image={curved9}
        >
            <SoftBox component="form" role="form" onSubmit={handleSubmit}>
                <SoftBox mb={2}>
                    <SoftBox mb={1} ml={0.5}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Customer Id
                        </SoftTypography>
                    </SoftBox>
                    <SoftInput
                        placeholder="Customer Id"
                        value={customerId}
                        onChange={(e) => {
                            validateFields();
                            return setCustomerId(e.target.value);
                        }}
                        // error={!!errors.email}
                        helperText={errors.customerId}
                    />
                    <SoftTypography style={{ color: 'red', fontSize: '12px' }}>
                        {errors.customerId}
                    </SoftTypography>
                </SoftBox>

                <SoftBox mb={2}>
                    <SoftBox mb={1} ml={0.5}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Password
                        </SoftTypography>
                    </SoftBox>
                    <SoftInput
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                            validateFields();
                            return setPassword(e.target.value);
                        }}
                        helperText={errors.password}
                    />
                    <SoftTypography style={{ color: 'red', fontSize: '12px' }}>
                        {errors.password}
                    </SoftTypography>
                </SoftBox>
                {showOtpField && (
                    <SoftBox mb={2}>
                        <SoftBox mb={1} ml={0.5}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                OTP
                            </SoftTypography>
                        </SoftBox>
                        <SoftInput
                            type="text"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            error={!!errors.otp}
                            helperText={errors.otp}
                        />
                    </SoftBox>
                )}
                <SoftBox mt={4} mb={1}>
                    <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                        {showOtpField ? "Verify OTP" : "Sign in"}
                    </SoftButton>
                </SoftBox>
                <SoftBox mt={3} textAlign="center">
                    <SoftTypography variant="button" color="text" fontWeight="regular">
                        Don&apos;t have an account?{" "}
                        <SoftTypography
                            component={Link}
                            to="/authentication/sign-up"
                            variant="button"
                            color="info"
                            fontWeight="medium"
                            textGradient
                        >
                            Sign up
                        </SoftTypography>
                    </SoftTypography>
                </SoftBox>
            </SoftBox>
        </CoverLayout>
    );
}

export default CustomerSignIn;
