import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Swal from "sweetalert2";
import axios from "axios";
import { APIData, org } from 'authentication/APIData';
import Grid from "@mui/material/Grid";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import ProcurementCard from "examples/Cards/ProcurementCard/procurement-card";
import { Tooltip } from "@mui/material";
import SoftButton from "components/SoftButton";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";

function ProcurementList() {
    const [procurements, setProcurements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tabValue, setTabValue] = useState(0);
    const [userdata, setUserData] = useState();
    const [permission, setPermission] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [currentUserId, setCurrentUserId] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
        setUserData(userData_Local);
        if (userData_Local && userData_Local.userType) {
            setIsSuperAdmin(userData_Local.userType === "SUPERADMIN");
            setCurrentUserId(userData_Local.user);
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(parseInt(accessLevel));
    }, []);

    useEffect(() => {
        handleTabChange(null, 0);
        fetchAllAssets();
    }, []);

    const handleTabChange = (event, newValue) => {
        if (!userdata) {
            return { showViewButton: false, showDeleteButton: false, showEditButton: false };
        }

        setTabValue(newValue);
        setLoading(true);

        switch (newValue) {
            case 0:
                fetchAllAssets();
                break;
            case 1:
                fetchEmployeeProcurement();
                break;
            case 2:
                fetchProcurements();
                break;
            default:
                break;
        }
    };

    const fetchAllAssets = () => {
        const url = `${APIData.api}procurement/?org=${org}`;
        axios.get(url, { headers: APIData.headers })
            .then((resp) => {
                setProcurements(resp.data);
                setLoading(false);
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Unable to Fetch",
                });
                setLoading(false);
            });
    };

    const fetchEmployeeProcurement = () => {
        const url = `${APIData.api}procurement/empid?empId=${currentUserId}&org=${org}`;
        axios.get(url, { headers: APIData.headers })
            .then((resp) => {
                setProcurements(resp.data);
                setLoading(false);
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Unable to Fetch",
                });
                setLoading(false);
            });
    };

    const fetchProcurements = () => {
        const types = ["Hardware", "Software", "Stationary"];
        const promises = types.map((type) =>
            axios.get(`${APIData.api}procurement/statusAndType?status=Initiated&type=${type}&org=${org}`,
                { headers: APIData.headers })
        );
        Promise.all(promises)
            .then((responses) => {
                const allProcurements = responses.reduce((acc, resp) => acc.concat(resp.data), []);
                setProcurements(allProcurements);
                setLoading(false);
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Unable to fetch procurements. Please try again.",
                });
                setLoading(false);
            });
    };

    const allocateProcurement = (status, procurement_id) => {
        setLoading(true);
        const url = `${APIData.api}procurement/?procurementId=${parseInt(procurement_id)}&status=${status}&org=${org}`;
        axios.put(url, {}, { headers: APIData.headers })
            .then((resp) => {
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: `Successfully ${status === "Procured" ? "approved" : "rejected"} the procurement`,
                });
                setLoading(false);
                handleTabChange(null, tabValue);
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: err.message || "An error occurred.",
                });
                setLoading(false);
            });
    };

    const handleApprove = (id) => {
        allocateProcurement("Procured", id);
    };

    const handleReject = (id) => {
        allocateProcurement("Rejected", id);
    };


    const shouldShowActionButtons = (emp_id) => {

        return (permission === 1111 || isSuperAdmin)
    };

    const handleRequestClick = () => {
        navigate("/procurement-request");
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox p={3}>
                <SoftBox mb={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <SoftTypography
                        variant="h4"
                        fontWeight="medium"
                        textAlign="left"
                        textTransform="capitalize"
                        style={{ margin: 0 }}
                    >
                        Procurement List
                    </SoftTypography>

                    <Tooltip title="New Procurement Request" arrow>
                        <SoftButton variant="gradient" color="info" size="small" sx={{ ml: 2 }} onClick={handleRequestClick}>Request</SoftButton>
                    </Tooltip>
                </SoftBox>

                <Card sx={{ mb: 3 }}>
                    <AppBar position="static" color="transparent">
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            sx={{
                                background: "transparent",
                                "& .MuiTab-root": {
                                    color: "text.primary",
                                }
                            }}
                        >
                            <Tab
                                label="All Procurement"
                                sx={{
                                    color: tabValue === 0 ? "info.main" : "text.secondary",
                                    fontWeight: tabValue === 0 ? "bold" : "normal",
                                    borderBottom: tabValue === 0 ? "2px solid" : "none",
                                    borderColor: "info.main",
                                }}
                            />

                            <Tab
                                label="My Procurement"
                                sx={{
                                    color: tabValue === 1 ? "info.main" : "text.secondary",
                                    fontWeight: tabValue === 1 ? "bold" : "normal",
                                    borderBottom: tabValue === 1 ? "2px solid" : "none",
                                    borderColor: "info.main",
                                }}
                            />
                            {(shouldShowActionButtons()) && (
                                <Tab
                                    label="Procurement Approval"
                                    sx={{
                                        color: tabValue === 2 ? "info.main" : "text.secondary",
                                        fontWeight: tabValue === 2 ? "bold" : "normal",
                                        borderBottom: tabValue === 2 ? "2px solid" : "none",
                                        borderColor: "info.main",
                                    }}
                                />
                            )}
                        </Tabs>
                    </AppBar>
                </Card>

                {loading ? (
                    <SoftTypography variant="body2" textAlign="center">
                        Loading procurements...
                    </SoftTypography>
                ) : (
                    <Grid container spacing={3}>
                        {procurements.map((procurement) => (
                            <Grid item xs={12} sm={6} md={4} key={procurement.id}>
                                <ProcurementCard
                                    assetName={procurement.asset_name}
                                    employeeId={procurement.emp_id}
                                    description={procurement.asset_description}
                                    type={procurement.type}
                                    status={procurement.status}
                                    onApprove={() => handleApprove(procurement.id)}
                                    onReject={() => handleReject(procurement.id)}
                                    tabValue={tabValue}
                                    showActionButtons={(procurement.emp_id) !== (currentUserId)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </SoftBox>
        </DashboardLayout>
    );
}

export default ProcurementList;