import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import { ClipboardCheck, CheckCircle } from 'lucide-react';
import { APIData, exitKeyWord, org } from '../authentication/APIData';
import { ResignationForm } from './ResignationForm';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Card from "@mui/material/Card";

const TimelineEvent = ({ date, title, approvedBy, description, isCompleted, isLast }) => {
  return (
    <SoftBox 
      display="flex" 
      alignItems="start" 
      mb={2}
      sx={{
        transform: isCompleted ? 'scale(1)' : 'scale(0.98)',
        opacity: isCompleted ? 1 : 0.8,
        transition: 'all 0.3s ease',
      }}
    >
      <SoftBox width="25%" pr={2} textAlign="right">
        <SoftTypography variant="caption" fontWeight="medium" color="primary">
          {date && new Date(date).toLocaleString()}
        </SoftTypography>
      </SoftBox>

      <SoftBox display="flex" flexDirection="column" alignItems="center" position="relative">
        <SoftBox
          width={40}
          height={40}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="50%"
          bgcolor={isCompleted ? 'info.main' : 'grey.200'}
          sx={{
            boxShadow: theme => `0 2px 4px ${theme.shadows[1]}`,
            transition: 'all 0.3s ease'
          }}
        >
          {isCompleted ? 
            <CheckCircle size={24} color="white" /> : 
            <ClipboardCheck size={24} color="grey" />
          }
        </SoftBox>
        {!isLast && (
          <SoftBox 
            width={2} 
            height="100%" 
            bgcolor={isCompleted ? 'info.main' : 'grey.300'} 
            position="absolute" 
            top={40}
            sx={{ transition: 'all 0.3s ease' }}
          />
        )}
      </SoftBox>

      <SoftBox width="75%" pl={2}>
        <Card>
          <SoftBox p={2}>
            <SoftTypography variant="h6" fontWeight="medium" color="info">
              {title}
            </SoftTypography>
            {approvedBy && (
              <SoftTypography variant="caption" color="text" mt={1} display="block">
                Approved By: {approvedBy}
              </SoftTypography>
            )}
            {description && (
              <SoftTypography variant="caption" color="text" mt={1} display="block">
                Details: {description}
              </SoftTypography>
            )}
          </SoftBox>
        </Card>
      </SoftBox>
    </SoftBox>
  );
};

TimelineEvent.propTypes = {
  date: PropTypes.string,
  title: PropTypes.string.isRequired,
  approvedBy: PropTypes.string,
  description: PropTypes.string,
  isCompleted: PropTypes.bool,
  isLast: PropTypes.bool
};

export function EmployeeExit() {
  const [lifecycleStatus, setLifecycleStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [empStatus, setEmpStatus] = useState([]);
  const [hasAppliedResignation, setHasAppliedResignation] = useState(false);
  const user = APIData.sessiondetails;

  const fetchExitLifeCycleStatuses = useCallback(async () => {
    try {
      const url = `${APIData.api}employee/life-cycle/type?type=${exitKeyWord}&org=${org}`;
      const response = await axios.get(url, { headers: APIData.headers });
      
      if (Array.isArray(response.data) && response.data.length > 0) {
        return response.data.sort((a, b) => a.id - b.id);
      }
      return [];
    } catch (error) {
      console.error('Error fetching lifecycle statuses:', error);
      toast.error('Unable to fetch lifecycle statuses');
      return [];
    }
  }, []);

  const fetchEmployeeExitStatus = useCallback(async () => {
    try {                        
      const url = `${APIData.api}employee/exit/emp-username?username=${user.userName}&type=${exitKeyWord}&org=${org}`;
      const response = await axios.get(url, { headers: APIData.headers });
      
      if (Array.isArray(response.data)) {
        return response.data.sort((a, b) => 
          (a.exitLifeCycle?.id || 0) - (b.exitLifeCycle?.id || 0)
        );
      } else if (response.data) {
        return [response.data];
      }
      return [];
    } catch (error) {
      console.error('Error fetching employee status:', error);
      toast.error('Unable to fetch user status');
      return [];
    }
  }, [user.userName]);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const employeeStatus = await fetchEmployeeExitStatus();
      const hasApplied = Array.isArray(employeeStatus) && employeeStatus.length > 0;
      
      setEmpStatus(employeeStatus);
      setHasAppliedResignation(hasApplied);

      if (hasApplied) {
        const lifecycleStatuses = await fetchExitLifeCycleStatuses();
        setLifecycleStatus(lifecycleStatuses);
      }
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error('Error loading exit process data');
    } finally {
      setLoading(false);
    }
  }, [fetchEmployeeExitStatus, fetchExitLifeCycleStatuses]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleFormSubmission = async () => {
    await loadData();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      
      <Grid container spacing={0} justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 100px)' }}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          {/* <Card> */}
            <SoftBox p={3} mb={1} textAlign="center">
              <SoftTypography variant="h4" fontWeight="medium">
                {user.name} Exit Process
              </SoftTypography>
            </SoftBox>

            <SoftBox p={3}>
              {hasAppliedResignation && lifecycleStatus.length > 0 ? (
                <SoftBox>
                  {lifecycleStatus.map((lifeCycle, index) => {
                    const empStat = index < empStatus.length ? empStatus[index] : null;
                    return (
                      <TimelineEvent
                        key={lifeCycle.id}
                        date={empStat?.updatedTime}
                        title={lifeCycle.statusName}
                        approvedBy={empStat?.approvedBy}
                        description={empStat?.description}
                        isCompleted={empStat?.status === 'Completed'}
                        isLast={index === lifecycleStatus.length - 1}
                      />
                    );
                  })}
                </SoftBox>
              ) : (
                <ResignationForm onSubmissionSuccess={handleFormSubmission} />
              )}
            </SoftBox>
          {/* </Card> */}
        </Grid>
      </Grid>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
}

export default EmployeeExit;