// import React, { useState, useEffect } from 'react';
// import Card from "@mui/material/Card";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftAvatar from "components/SoftAvatar";
// import SoftBadge from "components/SoftBadge";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";
// import { APIData } from 'authentication/APIData';
// import { Link } from 'react-router-dom';
// import SoftButton from 'components/SoftButton';
// import PropTypes from 'prop-types';

// const Name = ({ name, email }) => (
//     <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
//         <SoftBox mr={2}>
//             <SoftAvatar
//                 src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
//                 alt={name}
//                 size="sm"
//                 variant="rounded"
//             />
//         </SoftBox>
//         <SoftBox display="flex" flexDirection="column">
//             <SoftTypography variant="button" fontWeight="medium">
//                 {name}
//             </SoftTypography>
//             <SoftTypography variant="caption" color="secondary">
//                 {email}
//             </SoftTypography>
//         </SoftBox>
//     </SoftBox>
// );

// Name.propTypes = {
//     name: PropTypes.string.isRequired,
//     email: PropTypes.string.isRequired,
// };

// const TicketTable = () => {
//     const [ticketData, setTicketData] = useState([]);

//     useEffect(() => {
//         const fetchTicketData = async () => {
//             const url = `${APIData.api}Ticket?org=c4e`;

//             try {
//                 const response = await fetch(url, { headers: APIData.headers });
//                 const data = await response.json();
//                 setTicketData(data);
//             } catch (error) {
//                 console.error('Error fetching ticket data:', error);
//             }
//         };

//         fetchTicketData();
//     }, []);

//     const columns = [
//         { name: "Name", align: "left" },
//         { name: "Ticket ID", align: "left" },
//         { name: "Type", align: "left" },
//         { name: "Product", align: "left" },
//         { name: "Status", align: "left" },
//         { name: "Contact", align: "left" },
//         { name: "Priority", align: "left" },
//         { name: "SLA", align: "left" },
//         { name: "Action", align: "center" },
//     ];

//     const rows = ticketData.map((ticket) => ({
//         Name: <Name name={ticket.name} email={ticket.emailid} />,
//         Product: (
//             <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//                 {ticket.product}
//             </SoftTypography>
//         ),
//         Type: (
//             <SoftBadge
//                 variant="gradient"
//                 badgeContent={ticket.type.toLowerCase()}
//                 color="light"
//                 size="xs"
//                 container
//             />
//         ),
//         "Ticket ID": (
//             <SoftTypography variant="caption" color="text" fontWeight="medium">
//                 {ticket.ticket_id}
//             </SoftTypography>
//         ),
//         Status: (
//             <SoftBadge
//                 variant="gradient"
//                 badgeContent={ticket.status.toLowerCase()}
//                 color={ticket.status === "REQUESTED" ? "info" : "success" && ticket.status === "PENDING" ? "warning" : "success"}
//                 size="xs"
//                 container
//             />
//         ),
//         Priority: (
//             <SoftBadge
//                 variant="gradient"
//                 badgeContent={ticket.priority.toLowerCase()}
//                 color={ticket.priority === "CRITICAL" ? "error" : "secondary" && ticket.priority === "HIGH" ? "warning" : "secondary"}
//                 size="xs"
//                 container
//             />
//         ),
//         Contact: (
//             <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//                 {ticket.phone_number}
//             </SoftTypography>
//         ),
//         SLA: (
//             <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//                 {ticket.sla}
//             </SoftTypography>
//         ),
//         Action: (
//             <Link to={`/edit-ticket-status/${ticket.id}`}>
//                 <SoftTypography
//                     component="span"
//                     variant="button"
//                     color="info"
//                     fontWeight="medium"
//                 >
//                     Edit
//                 </SoftTypography>
//             </Link>
//         ),
//     }));

//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             <SoftBox py={3}>
//                 <SoftBox mb={3}>
//                     <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
//                         <SoftTypography variant="h6">Ticket List</SoftTypography>
//                     </SoftBox>
//                     <Card>
//                         <SoftBox
//                             sx={{
//                                 "& .MuiTableRow-root:not(:last-child)": {
//                                     "& td": {
//                                         borderBottom: ({ borders: { borderWidth, borderColor } }) =>
//                                             `${borderWidth[1]} solid ${borderColor}`,
//                                     },
//                                 },
//                             }}
//                         >
//                             <Table columns={columns} rows={rows} />
//                         </SoftBox>
//                     </Card>
//                 </SoftBox>
//             </SoftBox>
//             <Footer />
//         </DashboardLayout>
//     );
// };

// export default TicketTable;


import React, { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { APIData } from 'authentication/APIData';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Name = ({ name, email }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
            <SoftAvatar
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                alt={name}
                size="sm"
                variant="rounded"
            />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
                {name}
            </SoftTypography>
            <SoftTypography variant="caption" color="secondary">
                {email}
            </SoftTypography>
        </SoftBox>
    </SoftBox>
);

Name.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
};

const TicketTable = () => {
    const [ticketData, setTicketData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        const fetchTicketData = async () => {
            const url = `${APIData.api}Ticket?org=c4e`;

            try {
                const response = await fetch(url, { headers: APIData.headers });
                const data = await response.json();
                setTicketData(data);
            } catch (error) {
                console.error('Error fetching ticket data:', error);
            }
        };

        fetchTicketData();
    }, []);

    const filteredTickets = ticketData.filter(ticket =>
        ticket.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.emailid.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.ticket_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.product.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.priority.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.phone_number.includes(searchTerm)
    );

    const paginatedTickets = filteredTickets.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const columns = [
        { name: "Name", align: "left" },
        { name: "Ticket ID", align: "left" },
        { name: "Type", align: "left" },
        { name: "Product", align: "left" },
        { name: "Status", align: "left" },
        { name: "Contact", align: "left" },
        { name: "Priority", align: "left" },
        { name: "SLA", align: "left" },
        { name: "Action", align: "center" },
    ];

    const rows = paginatedTickets.map((ticket) => ({
        Name: <Name name={ticket.name} email={ticket.emailid} />,
        Product: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {ticket.product}
            </SoftTypography>
        ),
        Type: (
            <SoftBadge
                variant="gradient"
                badgeContent={ticket.type.toLowerCase()}
                color="light"
                size="xs"
                container
            />
        ),
        "Ticket ID": (
            <SoftTypography variant="caption" color="text" fontWeight="medium">
                {ticket.ticket_id}
            </SoftTypography>
        ),
        Status: (
            <SoftBadge
                variant="gradient"
                badgeContent={ticket.status.toLowerCase()}
                color={ticket.status === "REQUESTED" ? "info" : "success" && ticket.status === "PENDING" ? "warning" : "success"}
                size="xs"
                container
            />
        ),
        Priority: (
            <SoftBadge
                variant="gradient"
                badgeContent={ticket.priority.toLowerCase()}
                color={ticket.priority === "CRITICAL" ? "error" : "secondary" && ticket.priority === "HIGH" ? "warning" : "secondary"}
                size="xs"
                container
            />
        ),
        Contact: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {ticket.phone_number}
            </SoftTypography>
        ),
        SLA: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {ticket.sla}
            </SoftTypography>
        ),
        Action: (
            <Link to={`/edit-ticket-status/${ticket.id}`}>
                <SoftTypography
                    component="span"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                >
                    Edit
                </SoftTypography>
            </Link>
        ),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Ticket List</SoftTypography>
                            <SoftBox>
                                <SoftInput
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    size="small"
                                />
                            </SoftBox>
                        </SoftBox>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table columns={columns} rows={rows} />
                        </SoftBox>
                        <SoftBox
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            p={3}
                        >
                            <SoftBox display="flex" gap={1} alignItems="center">
                                <SoftTypography variant="caption" color="secondary">
                                    {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredTickets.length)} of {filteredTickets.length}
                                </SoftTypography>
                                <SoftButton
                                    variant="text"
                                    color="info"
                                    onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                    disabled={page === 0}
                                    iconOnly
                                >
                                    <KeyboardArrowLeft />
                                </SoftButton>
                                <SoftButton
                                    variant="text"
                                    color="info"
                                    onClick={() => setPage(prev => Math.min(Math.ceil(filteredTickets.length / itemsPerPage) - 1, prev + 1))}
                                    disabled={page >= Math.ceil(filteredTickets.length / itemsPerPage) - 1}
                                    iconOnly
                                >
                                    <KeyboardArrowRight />
                                </SoftButton>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default TicketTable;