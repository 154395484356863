// import React, { useEffect, useState } from 'react';
// import { Grid, IconButton, Container, Paper } from '@mui/material';
// import Swal from 'sweetalert2';
// import { IoIosAdd as AddIcon } from "react-icons/io";
// import axios from 'axios';
// import { APIData, org } from 'authentication/APIData';
// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
// import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
// import SoftBox from 'components/SoftBox';
// import SoftTypography from 'components/SoftTypography';
// import Footer from 'examples/Footer';
// import SoftButton from 'components/SoftButton';
// import CustomSelect from 'assets/theme/components/Select/CustomSelect';
// import SoftInput from 'components/SoftInput';

// const MessageBroadcast = () => {
//     const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
//     const [selectedClass, setSelectedClass] = useState('');
//     const [selectedStudent, setSelectedStudent] = useState(null);
//     const [fields, setFields] = useState([{ subject: '', description: '' }]);
//     const [briefDescriptions, setBriefDescriptions] = useState(['']);
//     const [remarks, setRemarks] = useState('');
//     const [studentData, setStudentData] = useState([]);

//     const fetchStudents = () => {
//         axios.get(`${APIData.api}studentadmissiondetails/?org=${org}`, { headers: APIData.headers })
//             .then((response) => {
//                 setStudentData(response.data);
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     };

//     useEffect(() => {
//         fetchStudents();
//     }, []);

//     const handleFieldChange = (index, event) => {
//         const values = [...fields];
//         values[index][event.target.name] = event.target.value;
//         setFields(values);
//     };

//     const handleAddField = () => {
//         if (fields.length < 3 && fields.every((f) => f.subject && f.description)) {
//             setFields([...fields, { subject: '', description: '' }]);
//         } else {
//             Swal.fire('Warning', 'Complete the Subject and description before adding the next subject', 'warning');
//         }
//     };

//     const handleBriefDescriptionChange = (index, event) => {
//         const values = [...briefDescriptions];
//         values[index] = event.target.value;
//         setBriefDescriptions(values);
//     };

//     const addBriefDescription = () => {
//         if (briefDescriptions.length < 2 && briefDescriptions.every((desc) => desc)) {
//             setBriefDescriptions([...briefDescriptions, '']);
//         } else {
//             Swal.fire('Warning', 'Complete existing brief descriptions before adding more.', 'warning');
//         }
//     };

//     const handleStudentChange = (selectedOption) => {
//         const selected = studentData.find(student => student.name === selectedOption.value);
//         setSelectedStudent(selected);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (!selectedStudent) {
//             Swal.fire('Warning', 'Please select a student', 'warning');
//             return;
//         }
//         for (let i = 0; i < fields.length; i++) {
//             const { subject, description } = fields[i];
//             if (subject.length > 250) {
//                 Swal.fire('Warning', 'Subject must be less than 250 characters', 'warning');
//                 return;
//             }
//             if (description.length > 250) {
//                 Swal.fire('Warning', 'Description must be less than 250 characters', 'warning');
//                 return;
//             }
//         }
//         for (let i = 0; i < briefDescriptions.length; i++) {
//             const desc = briefDescriptions[i];
//             if (desc.length > 250) {
//                 Swal.fire('Warning', 'Brief Descriptions must be less than 250 characters', 'warning');
//                 return;
//             }
//         }
//         if (remarks.length > 250) {
//             Swal.fire('Warning', 'Remarks must be less than 250 characters', 'warning');
//             return;
//         }



//         let url = `${APIData.tpapi}student-daily-update/notifications?`;

//         const params = {
//             to: selectedStudent.mobileNo || '',
//             studentName: selectedStudent.name || '',
//             remarks: remarks || ''
//         };

//         fields.forEach((field, index) => {
//             params[`subject${index + 1}`] = field.subject || '';
//             params[`description${index + 1}`] = field.description || '';
//         });

//         briefDescriptions.forEach((desc, index) => {
//             params[`briefDesc${index + 1}`] = desc || '';
//         });

//         console.log('Params:', params);

//         try {
//             const response = await axios.get(url, {
//                 params,
//                 headers: APIData.tpheaders,
//             });
//             Swal.fire('Success', 'Message sent successfully!', 'success');
//         } catch (error) {
//             if (error.response) {
//                 console.error('Response error:', error.response.data);
//                 Swal.fire('Error', `Failed to send message: ${error.response.data.message}`, 'error');
//             } else if (error.request) {
//                 console.error('Request error:', error.request);
//                 Swal.fire('Error', 'No response from server. Please try again.', 'error');
//             } else {
//                 console.error('Error:', error.message);
//                 Swal.fire('Error', 'An error occurred. Please try again.', 'error');
//             }
//         }
//     };

//     const studentOptions = studentData.map(student => ({ value: student.name, label: student.name }));

//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             <SoftBox pt={2} px={2}>
//                 <SoftBox mb={0.5} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                     <SoftTypography variant="h5">Dialy Report</SoftTypography>
//                 </SoftBox>
//             </SoftBox>
//             <Container maxWidth="md">
//                 <Paper elevation={3} sx={{ margin: '2rem 0', padding: '2rem', borderRadius: '10px' }}>
//                     <SoftTypography variant="h5" sx={{ textAlign: 'center', marginBottom: '1rem' }}>Dialy Report</SoftTypography>
//                     <form onSubmit={handleSubmit}>
//                         <Grid container spacing={3}>
//                             <Grid item xs={12}>
//                                 <SoftBox mb={1}>
//                                     <CustomSelect
//                                         options={studentOptions}
//                                         value={selectedStudent ? { value: selectedStudent.name, label: selectedStudent.name } : null}
//                                         onChange={handleStudentChange}
//                                         placeholder="Select Student"
//                                         isRequired
//                                     />
//                                 </SoftBox>
//                             </Grid>
//                             {selectedStudent && (
//                                 <Grid item xs={12}>
//                                     <SoftTypography variant="body1">Phone Number: {selectedStudent.mobileNo}</SoftTypography>
//                                 </Grid>
//                             )}
//                             {fields.map((field, index) => (
//                                 <React.Fragment key={index}>
//                                     <Grid item xs={12} md={5}>
//                                         <SoftTypography variant="h6">Subject</SoftTypography>
//                                         <SoftInput
//                                             placeholder="Subject"
//                                             value={field.subject}
//                                             onChange={(event) => handleFieldChange(index, event)}
//                                             name="subject"
//                                             required
//                                             error={field.subject.length > 250}
//                                             helperText={field.subject.length > 250 ? 'Subject must be less than 250 characters' : ''}
//                                         />
//                                     </Grid>
//                                     <Grid item xs={12} md={6}>
//                                         <SoftTypography variant="h6">Description</SoftTypography>
//                                         <SoftInput
//                                             placeholder="Description"
//                                             value={field.description}
//                                             onChange={(event) => handleFieldChange(index, event)}
//                                             name="description"
//                                             required
//                                             error={field.description.length > 250}
//                                             helperText={field.description.length > 250 ? 'Description must be less than 250 characters' : ''}
//                                         />
//                                     </Grid>
//                                     <Grid
//                                         item
//                                         xs={12}
//                                         md={1}
//                                         sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
//                                     >
//                                         {index === fields.length - 1 && fields.length < 3 && (
//                                             <div data-toggle="tooltip" data-placement="right" title="Add another Subject">
//                                                 <IconButton onClick={handleAddField} color="primary">
//                                                     <AddIcon />
//                                                 </IconButton>
//                                             </div>
//                                         )}
//                                     </Grid>
//                                 </React.Fragment>
//                             ))}
//                             {briefDescriptions.map((desc, index) => (
//                                 <Grid container item xs={12} key={index} alignItems="center">
//                                     <Grid item xs={11}>
//                                         <SoftTypography variant="h6">Brief Description</SoftTypography>
//                                         <SoftInput
//                                             placeholder={`Brief Description ${index + 1}`}
//                                             value={desc}
//                                             onChange={(event) => handleBriefDescriptionChange(index, event)}
//                                             fullWidth
//                                             multiline
//                                             maxRows={4}
//                                             required
//                                             error={desc.length > 250}
//                                             helperText={desc.length > 250 ? 'Brief Description must be less than 250 characters' : ''}
//                                         />
//                                     </Grid>
//                                     {index === briefDescriptions.length - 1 && briefDescriptions.length < 2 && (
//                                         <Grid item xs={1}>
//                                             <div data-toggle="tooltip" data-placement="right" title="Add another Description">
//                                                 <IconButton onClick={addBriefDescription} color="primary">
//                                                     <AddIcon />
//                                                 </IconButton>
//                                             </div>
//                                         </Grid>
//                                     )}
//                                 </Grid>
//                             ))}
//                             <Grid item xs={12}>
//                                 <SoftTypography variant="h6">Remarks</SoftTypography>
//                                 <SoftInput
//                                     placeholder="Remarks"
//                                     value={remarks}
//                                     onChange={(event) => setRemarks(event.target.value)}
//                                     fullWidth
//                                     multiline
//                                     maxRows={4}
//                                     required
//                                     error={remarks.length > 250}
//                                     helperText={remarks.length > 250 ? 'Remarks must be less than 250 characters' : ''}
//                                 />
//                             </Grid>
//                             <Grid item xs={12} sx={{ textAlign: 'center' }}>
//                                 <SoftButton type="submit" variant="gradient" color="info">
//                                     Send Message
//                                 </SoftButton>
//                             </Grid>
//                         </Grid>
//                     </form>
//                 </Paper>
//             </Container>
//             <Footer />
//         </DashboardLayout>
//     );
// };

// export default MessageBroadcast


import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Container, Paper, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import Swal from 'sweetalert2';
import { IoIosAdd as AddIcon } from "react-icons/io";
import axios from 'axios';
import { APIData, org } from 'authentication/APIData';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import Footer from 'examples/Footer';
import SoftButton from 'components/SoftButton';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import SoftInput from 'components/SoftInput';

const MessageBroadcast = () => {
    const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [fields, setFields] = useState([{ subject: '', description: '' }]);
    const [briefDescriptions, setBriefDescriptions] = useState(['']);
    const [remarks, setRemarks] = useState('');
    const [studentData, setStudentData] = useState([]);
    const [classOptions, setClassOptions] = useState([]);
    const [studentOptions, setStudentOptions] = useState([]);
    const [pdfUrl, setPdfUrl] = useState('');
    const [selectionType, setSelectionType] = useState('single');

    const fetchClassOptions = () => {
        axios.get(`${APIData.api}studentadmissiondetails/?org=${org}`, {
            headers: APIData.headers
        })
            .then((response) => {
                const uniqueClasses = [...new Set(response.data.map(item => item.studentClass))]
                    .filter(className => className)
                    .map(className => ({
                        value: className,
                        label: className
                    }));
                setClassOptions(uniqueClasses);
            })
            .catch((error) => {
                console.log(error);
                Swal.fire('Error', 'Failed to fetch class options', 'error');
            });
    };

    const fetchClassStudents = (className) => {
        return axios.get(`${APIData.api}studentadmissiondetails/studentClass`, {
            params: {
                org: org,
                StudentClass: className
            },
            headers: APIData.headers
        });
    };

    const fetchAllStudents = () => {
        axios.get(`${APIData.api}studentadmissiondetails/?org=${org}`, {
            headers: APIData.headers
        })
            .then((response) => {
                setStudentData(response.data);
                const studentOpts = response.data.map(student => ({
                    value: student.name,
                    label: `${student.name} (${student.mobileNo})`
                }));
                setStudentOptions(studentOpts);
            })
            .catch((error) => {
                console.log(error);
                Swal.fire('Error', 'Failed to fetch students', 'error');
            });
    };

    useEffect(() => {
        fetchClassOptions();
        fetchAllStudents();
    }, []);

    const handleClassChange = (selectedOption) => {
        setSelectedClass(selectedOption.value);
        // When a class is selected, fetch students for that class
        axios.get(`${APIData.api}studentadmissiondetails/studentClass`, {
            params: {
                org: org,
                StudentClass: selectedOption.value
            },
            headers: APIData.headers
        })
            .then((response) => {
                const studentOpts = response.data.map(student => ({
                    value: student.name,
                    label: `${student.name} (${student.mobileNo})`
                }));
                setStudentOptions(studentOpts);
                setSelectedStudent(null);
            })
            .catch((error) => {
                console.log(error);
                Swal.fire('Error', 'Failed to fetch class students', 'error');
            });
    };

    const handleFieldChange = (index, event) => {
        const values = [...fields];
        values[index][event.target.name] = event.target.value;
        setFields(values);
    };

    const handleAddField = () => {
        if (fields.length < 3 && fields.every((f) => f.subject && f.description)) {
            setFields([...fields, { subject: '', description: '' }]);
        } else {
            Swal.fire('Warning', 'Complete the Subject and description before adding the next subject', 'warning');
        }
    };

    const handleBriefDescriptionChange = (index, event) => {
        const values = [...briefDescriptions];
        values[index] = event.target.value;
        setBriefDescriptions(values);
    };

    const addBriefDescription = () => {
        if (briefDescriptions.length < 2 && briefDescriptions.every((desc) => desc)) {
            setBriefDescriptions([...briefDescriptions, '']);
        } else {
            Swal.fire('Warning', 'Complete existing brief descriptions before adding more.', 'warning');
        }
    };

    const handleStudentChange = (selectedOption) => {
        const selected = studentData.find(student => student.name === selectedOption.value);
        setSelectedStudent(selected);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (selectionType === 'single' && !selectedStudent) {
            Swal.fire('Warning', 'Please select a student', 'warning');
            return;
        }
        if (selectionType === 'classwise' && !selectedClass) {
            Swal.fire('Warning', 'Please select a class', 'warning');
            return;
        }

        for (let i = 0; i < fields.length; i++) {
            const { subject, description } = fields[i];
            if (subject.length > 250) {
                Swal.fire('Warning', 'Subject must be less than 250 characters', 'warning');
                return;
            }
            if (description.length > 250) {
                Swal.fire('Warning', 'Description must be less than 250 characters', 'warning');
                return;
            }
        }
        for (let i = 0; i < briefDescriptions.length; i++) {
            const desc = briefDescriptions[i];
            if (desc.length > 250) {
                Swal.fire('Warning', 'Brief Descriptions must be less than 250 characters', 'warning');
                return;
            }
        }
        if (remarks.length > 250) {
            Swal.fire('Warning', 'Remarks must be less than 250 characters', 'warning');
            return;
        }

        // Prepare base params
        const baseParams = {
            pdfUrl: pdfUrl || '',
            remarks: remarks || ''
        };

        let studentsToNotify = [];

        // Determine students based on selection type
        if (selectionType === 'single') {
            studentsToNotify = [selectedStudent];
        } else if (selectionType === 'classwise') {
            try {
                const response = await fetchClassStudents(selectedClass);
                studentsToNotify = response.data;
            } catch (error) {
                console.error('Error fetching class students:', error);
                Swal.fire('Error', 'Failed to fetch students for the selected class', 'error');
                return;
            }
        } else if (selectionType === 'all') {
            try {
                const response = await axios.get(`${APIData.api}studentadmissiondetails/?org=${org}`, {
                    headers: APIData.headers
                });
                studentsToNotify = response.data;
            } catch (error) {
                console.error('Error fetching all students:', error);
                Swal.fire('Error', 'Failed to fetch students', 'error');
                return;
            }
        }

        // Validate students list
        if (!studentsToNotify || studentsToNotify.length === 0) {
            Swal.fire('Warning', 'No students found to send message', 'warning');
            return;
        }

        // Send messages sequentially
        const successMessages = [];
        const errorMessages = [];

        for (const student of studentsToNotify) {
            const params = new URLSearchParams();

            // Add base params
            Object.entries(baseParams).forEach(([key, value]) => {
                if (value) params.append(key, value);
            });

            // Add student-specific params
            params.append('to', student.mobileNo || '');
            params.append('studentName', student.name || '');

            // Add subject and description params
            fields.forEach((field, index) => {
                if (field.subject) {
                    params.append(`subject${index + 1}`, field.subject);
                }
                if (field.description) {
                    params.append(`description${index + 1}`, field.description);
                }
            });

            // Add brief descriptions
            briefDescriptions.forEach((desc, index) => {
                if (desc) {
                    params.append(`briefDesc${index + 1}`, desc);
                }
            });

            try {
                const url = `${APIData.tpapi}student-daily-update/notifications`;
                await axios.get(url, {
                    params,
                    headers: APIData.tpheaders,
                });
                successMessages.push(student.name);
            } catch (error) {
                console.error(`Error sending message to ${student.name}:`, error);
                errorMessages.push(student.name);
            }
        }

        // Show overall result
        if (errorMessages.length === 0) {
            Swal.fire('Success', `Message sent successfully to ${successMessages.length} students!`, 'success');
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Partial Success',
                html: `
                    <p>Successfully sent to ${successMessages.length} students.</p>
                    <p>Failed to send to ${errorMessages.length} students.</p>
                    <small>Failed students: ${errorMessages.join(', ')}</small>
                `,
            });
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={2} px={2}>
                <SoftBox mb={0.5} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <SoftTypography variant="h5">Daily Report</SoftTypography>
                </SoftBox>
            </SoftBox>
            <Container maxWidth="md">
                <Paper elevation={3} sx={{ margin: '2rem 0', padding: '2rem', borderRadius: '10px' }}>
                    <SoftTypography variant="h5" sx={{ textAlign: 'center', marginBottom: '1rem' }}>Daily Report</SoftTypography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            {/* Selection Type */}
                            <Grid item xs={12}>
                                <SoftTypography variant="h6">Select Recipients</SoftTypography>
                                <RadioGroup
                                    row
                                    value={selectionType}
                                    onChange={(e) => setSelectionType(e.target.value)}
                                >
                                    <FormControlLabel
                                        value="single"
                                        control={<Radio />}
                                        label="Single Student"
                                    />
                                    <FormControlLabel
                                        value="classwise"
                                        control={<Radio />}
                                        label="Class-wise"
                                    />
                                    <FormControlLabel
                                        value="all"
                                        control={<Radio />}
                                        label="All Students"
                                    />
                                </RadioGroup>
                            </Grid>

                            {/* Class Dropdown for Class-wise Selection */}
                            {selectionType === 'classwise' && (
                                <Grid item xs={12}>
                                    <CustomSelect
                                        options={classOptions}
                                        value={selectedClass ? { value: selectedClass, label: selectedClass } : null}
                                        onChange={handleClassChange}
                                        placeholder="Select Class"
                                        isRequired
                                    />
                                </Grid>
                            )}

                            {/* Student Dropdown for Single Student Selection */}
                            {selectionType === 'single' && (
                                <Grid item xs={12}>
                                    <CustomSelect
                                        options={studentOptions.map(option => ({
                                            value: option.value,
                                            label: option.value // Just show the student name
                                        }))}
                                        value={selectedStudent ? { value: selectedStudent.name, label: selectedStudent.name } : null}
                                        onChange={handleStudentChange}
                                        placeholder="Select Student"
                                        isRequired
                                    />
                                </Grid>
                            )}

                            {/* Display Student Details for Single Student */}
                            {selectionType === 'single' && selectedStudent && (
                                <Grid item xs={12}>
                                    <SoftTypography variant="body1">Phone Number: {selectedStudent.mobileNo}</SoftTypography>
                                </Grid>
                            )}

                            {/* PDF URL Input */}
                            <Grid item xs={12}>
                                <SoftTypography variant="h6">PDF URL</SoftTypography>
                                <SoftInput
                                    placeholder="Enter PDF URL"
                                    value={pdfUrl}
                                    onChange={(event) => setPdfUrl(event.target.value)}
                                    fullWidth
                                />
                            </Grid>

                            {fields.map((field, index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={12} md={5}>
                                        <SoftTypography variant="h6">Subject</SoftTypography>
                                        <SoftInput
                                            placeholder="Subject"
                                            value={field.subject}
                                            onChange={(event) => handleFieldChange(index, event)}
                                            name="subject"
                                            required
                                            error={field.subject.length > 250}
                                            helperText={field.subject.length > 250 ? 'Subject must be less than 250 characters' : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SoftTypography variant="h6">Description</SoftTypography>
                                        <SoftInput
                                            placeholder="Description"
                                            value={field.description}
                                            onChange={(event) => handleFieldChange(index, event)}
                                            name="description"
                                            required
                                            error={field.description.length > 250}
                                            helperText={field.description.length > 250 ? 'Description must be less than 250 characters' : ''}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={1}
                                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {index === fields.length - 1 && fields.length < 3 && (
                                            <div data-toggle="tooltip" data-placement="right" title="Add another Subject">
                                                <IconButton onClick={handleAddField} color="primary">
                                                    <AddIcon />
                                                </IconButton>
                                            </div>
                                        )}
                                    </Grid>
                                </React.Fragment>
                            ))}
                            {briefDescriptions.map((desc, index) => (
                                <Grid container item xs={12} key={index} alignItems="center">
                                    <Grid item xs={11}>
                                        <SoftTypography variant="h6">Brief Description</SoftTypography>
                                        <SoftInput
                                            placeholder={`Brief Description ${index + 1}`}
                                            value={desc}
                                            onChange={(event) => handleBriefDescriptionChange(index, event)}
                                            fullWidth
                                            multiline
                                            maxRows={4}
                                            required
                                            error={desc.length > 250}
                                            helperText={desc.length > 250 ? 'Brief Description must be less than 250 characters' : ''}
                                        />
                                    </Grid>
                                    {index === briefDescriptions.length - 1 && briefDescriptions.length < 2 && (
                                        <Grid item xs={1}>
                                            <div data-toggle="tooltip" data-placement="right" title="Add another Description">
                                                <IconButton onClick={addBriefDescription} color="primary">
                                                    <AddIcon />
                                                </IconButton>
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            ))}

                            <Grid item xs={12}>
                                <SoftTypography variant="h6">Remarks</SoftTypography>
                                <SoftInput
                                    placeholder="Remarks"
                                    value={remarks}
                                    onChange={(event) => setRemarks(event.target.value)}
                                    fullWidth
                                    multiline
                                    maxRows={4}
                                    required
                                    error={remarks.length > 250}
                                    helperText={remarks.length > 250 ? 'Remarks must be less than 250 characters' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <SoftButton type="submit" variant="gradient" color="info">
                                    Send Message
                                </SoftButton>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
            <Footer />
        </DashboardLayout>
    );
};

export default MessageBroadcast;