import {Button,Container, Group,Text,TextInput, Select, Textarea,} from "@mantine/core";
import { useForm } from "@mantine/form";
import toast from "react-hot-toast";
import { postMcqRequest } from "../../Query/McqQuery";
import { org } from "authentication/APIData";
import Swal from "sweetalert2";

export default function QuestionAdd() {
  const form = useForm({
    initialValues: {
      subject: "",
      question: "",
      answer: "",
      mcq1: "",
      mcq2: "",
      mcq3: "",
      mcq4: "",
      options: "",
      grade: "",
      board: "",
      org: org,
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      ![
        form.values.mcq1,
        form.values.mcq2,
        form.values.mcq3,
        form.values.mcq4,
      ].includes(form.values.answer)
    ) {
      Swal.fire({
        icon: 'warning',
        title: 'Invalid Answer',
        text: 'Answer must be one of the provided MCQ options.',
        confirmButtonText: 'OK'
    });
      return;
    }
    const formData = {
      id: 0,
      subject: form.values.subject,
      question: form.values.question,
      answer: form.values.answer,
      board: form.values.board,
      options: [
        form.values.mcq1,
        form.values.mcq2,
        form.values.mcq3,
        form.values.mcq4,
      ],
      grade: form.values.grade,
      org: org,
    };
    // console.log(formData);
    const response = await postMcqRequest(formData)
      .then((resp) => {
        Swal.fire({
          icon: "success",
          title: "Added Successfully",
          showConfirmButton: false,
          timer: 1500
      });
        form.reset();
        form.resetTouched()
      })
      .catch((err) => {
        toast.error("");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: 'Unable to add the question',
      });
      });
  };

  var boards = ['State', 'CBSE', 'ICSE'];


  return (
    <div style={{ position: "relative" }}>
      <form
        onSubmit={handleSubmit}
        style={{ maxWidth: "600px", margin: "auto" }}
      >
        <Container
          style={{
            // backgroundColor: " #C4DFFF",
            boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <Text size="1.5rem" fw={"600"} style={{ textAlign: "center" }}>
            Add question
          </Text>
          <div>
            <Textarea
              label="Question"
              placeholder="Question"
              maxRows={6}
              minRows={2}
              required
              style={{ width: "100%" }}
              autosize
              name="question"
              // variant="filled"
              {...form.getInputProps("question")}
            />
            <TextInput
              label="Answer"
              placeholder="Answer "
              name="answer"
              required
              style={{ width: "100%" }}
              // variant="filled"

              {...form.getInputProps("answer")}
            />
            <TextInput
              label="Mcq1"
              placeholder="Option1"
              name="mcq1"
              required
              style={{ width: "100%" }}
              // variant="filled"
              {...form.getInputProps("mcq1")}
            />
            <TextInput
              label="Mcq2"
              placeholder="Option2"
              name="mcq2"
              required
              style={{ width: "100%" }}
              // variant="filled"
              {...form.getInputProps("mcq2")}
            />
            <TextInput
              label="Mcq3"
              placeholder="Option3"
              name="mcq3"
              required
              style={{ width: "100%" }}
              // variant="filled"
              {...form.getInputProps("mcq3")}
            />
            <TextInput
              label="Mcq4"
              placeholder="Option4"
              name="mcq4"
              required
              style={{ width: "100%" }}
              // variant="filled"
              {...form.getInputProps("mcq4")}
            />
            <TextInput
              label="Subject"
              placeholder="Subject"
              name="subject"
              required
              style={{ width: "100%" }}
              // variant="filled"
              {...form.getInputProps("subject")}
            />
            <TextInput
              label="Grade"
              placeholder="Grade 9th or 11th"
              name="grade"
              required
              style={{ width: "100%" }}
              // variant="filled"
              {...form.getInputProps("grade")}
            />
            <Select
              required
              mt="sm"
              label="Select Board"
              placeholder="Select Board"
              data={boards}
              {...form.getInputProps("board")}
            />
            <br />
          </div>
          <Group justify="center" mt="">
            {/* <Button type="submit" size="md">
              Add Question
            </Button> */}
            <Button
              type="submit"
              mt="sm"
              w={{ base: "100%" }}
              variant="gradient"
            >
              Add Question
            </Button>
          </Group>
        </Container>
      </form>
    </div>
  );
}
