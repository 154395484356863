// import React, { useState, useEffect } from 'react';
// import { Link } from "react-router-dom";
// import axios from 'axios';
// import Swal from 'sweetalert2';
// import { APIData, org } from 'authentication/APIData';
// import { IoArrowBack } from "react-icons/io5";
// import { FaEdit } from 'react-icons/fa';
// import { AiOutlineCloseCircle } from "react-icons/ai";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftBadge from "components/SoftBadge";
// import SoftInput from "components/SoftInput";
// import SoftButton from "components/SoftButton";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";
// import CustomSelect from "assets/theme/components/Select/CustomSelect";
// import {
//   Card,
//   Dialog,
//   DialogContent,
//   CircularProgress,
//   Backdrop,
//   Grid
// } from '@mui/material';

// const StatusPage = () => {
//   const [tasks, setTasks] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [reloading, setReloading] = useState(false);
//   const [selectedTask, setSelectedTask] = useState(null);
//   const [isDialogOpen, setIsDialogOpen] = useState(false);
//   const [fetchedPrivileges, setFetchedPrivileges] = useState(null);
//   const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
//   const fetchedDept = localStorage.getItem("Depart Details");

//   const statusOptions = [
//     { value: "active", label: "Active" },
//     { value: "inactive", label: "Inactive" }
//   ];

//   useEffect(() => {
//     if (sessiondetails) {
//       if (sessiondetails.userType === "MEMBER") {
//         if (Array.isArray(sessiondetails.privileges)) {
//           sessiondetails.privileges.forEach(privilege => {
//             if (privilege.dept === fetchedDept) {
//               setFetchedPrivileges(privilege.privileges);
//             }
//           });
//         }
//       } else if (sessiondetails.userType === "user") {
//         setFetchedPrivileges("1000");
//       } else if (sessiondetails.userType === "SUPERADMIN") {
//         setFetchedPrivileges("1111");
//       }
//     }
//   }, [sessiondetails, fetchedDept]);

//   const fetchData = async () => {
//     setLoading(true);
//     try {
//       const url = `${APIData.api}login-type/users?org=${org}`;
//       const response = await axios.get(url, { headers: APIData.headers });
//       setTasks(response.data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Error',
//         text: 'Failed to fetch data',
//         confirmButtonColor: '#3085d6'
//       });
//     }
//     setLoading(false);
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const handleUpdate = (task) => {
//     setSelectedTask(task);
//     setIsDialogOpen(true);
//   };

//   const handleSelectChange = (selectedOption) => {
//     if (selectedOption) {
//       setSelectedTask(prevTask => ({
//         ...prevTask,
//         status: selectedOption.value
//       }));
//     }
//   };

//   const handleUpdate2 = async (e) => {
//     e.preventDefault();
//     setReloading(true);
//     try {
//       const form = {
//         username: selectedTask.username,
//         password: selectedTask.password,
//         status: selectedTask.status,
//         role: selectedTask.role,
//         email_id: selectedTask.email_id,
//         phone_number: selectedTask.phone_number,
//         updated_by: sessiondetails?.userName,
//         org: org
//       };

//       const url = `${APIData.api}login-type/user`;
//       const response = await axios.post(url, form, { headers: APIData.headers });

//       if (response.status === 201 || response.status === 200) {
//         setTasks(prevTasks => 
//           prevTasks.map(task => 
//             task.username === selectedTask.username ? { ...task, ...form } : task
//           )
//         );
        
//         setIsDialogOpen(false);
//         Swal.fire({
//           icon: 'success',
//           title: 'Success',
//           text: 'Updated successfully',
//           confirmButtonColor: '#3085d6'
//         });
//       } else {
//         Swal.fire({
//           icon: 'error',
//           title: 'Error',
//           text: 'Update failed',
//           confirmButtonColor: '#3085d6'
//         });
//       }
//     } catch (error) {
//       console.error(error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Error',
//         text: 'Failed to update',
//         confirmButtonColor: '#3085d6'
//       });
//     }
//     setReloading(false);
//   };

//   const columns = [
//     { name: "User ID", align: "left" },
//     { name: "Status", align: "center" },
//     ...(fetchedPrivileges === "1111" ? [{ name: "Action", align: "center" }] : [])
//   ];

//   const rows = tasks.map((task) => ({
//     "User ID": (
//       <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
//         <SoftTypography variant="button" fontWeight="medium">
//           {task.username}
//         </SoftTypography>
//       </SoftBox>
//     ),
//     Status: (
//       <SoftBadge
//         variant="gradient"
//         badgeContent={task.status}
//         color={task.status === "active" ? "success" : "error"}
//         size="xs"
//         container
//       />
//     ),
//     Action: fetchedPrivileges === "1111" && (
//       <SoftTypography
//         component="a"
//         variant="button"
//         color="info"
//         fontWeight="medium"
//         onClick={() => handleUpdate(task)}
//         sx={{ cursor: 'pointer' }}
//       >
//         <FaEdit />
//       </SoftTypography>
//     ),
//   }));

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <SoftBox py={3}>
//         <SoftBox mb={3}>
//           <Card>
//             <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
//               <SoftTypography variant="h6">Status Management</SoftTypography>
//             </SoftBox>

//             {loading ? (
//               <SoftBox display="flex" justifyContent="center" p={3}>
//                 <CircularProgress />
//               </SoftBox>
//             ) : (
//               <SoftBox
//                 sx={{
//                   "& .MuiTableRow-root:not(:last-child)": {
//                     "& td": {
//                       borderBottom: ({ borders: { borderWidth, borderColor } }) =>
//                         `${borderWidth[1]} solid ${borderColor}`,
//                     },
//                   },
//                 }}
//               >
//                 <Table columns={columns} rows={rows} />
//               </SoftBox>
//             )}
//           </Card>
//         </SoftBox>
//       </SoftBox>

//       <Dialog
//         open={isDialogOpen}
//         onClose={() => setIsDialogOpen(false)}
//         maxWidth="sm"
//         fullWidth
//       >
//         <Backdrop
//           sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
//           open={reloading}
//         >
//           <CircularProgress color="inherit" />
//         </Backdrop>

//         <DialogContent>
//           <Grid container spacing={0} justifyContent="center" alignItems="center">
//             <Grid item xs={12}>
//               <Card sx={{ height: 'auto' }}>
//                 <SoftBox p={3} mb={1} display="flex" alignItems="center">
//                   <SoftBox onClick={() => setIsDialogOpen(false)} sx={{ cursor: 'pointer' }}>
//                     <AiOutlineCloseCircle />
//                   </SoftBox>
//                   <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
//                     Update Status Of {selectedTask?.username}
//                   </SoftTypography>
//                 </SoftBox>

//                 <SoftBox pt={1} pb={3} px={3}>
//                   <form onSubmit={handleUpdate2}>
//                     <SoftBox mb={2}>
//                       <SoftTypography variant="h6">User ID</SoftTypography>
//                       <SoftInput
//                         value={selectedTask?.username || ""}
//                         disabled
//                         size="small"
//                       />
//                     </SoftBox>

//                     <SoftBox mb={2}>
//                       <SoftTypography variant="h6" mb={1}>Status</SoftTypography>
//                       <CustomSelect
//                         options={statusOptions}
//                         placeholder="Select Status"
//                         onChange={handleSelectChange}
//                         value={selectedTask?.status}
//                       />
//                     </SoftBox>

//                     <SoftBox mb={2}>
//                       <SoftTypography variant="h6">Updated By</SoftTypography>
//                       <SoftInput
//                         value={sessiondetails?.userName}
//                         disabled
//                         size="small"
//                       />
//                     </SoftBox>

//                     <SoftBox mt={2} display="flex" justifyContent="space-between">
//                       <SoftButton 
//                         variant="gradient" 
//                         color="secondary" 
//                         onClick={() => setIsDialogOpen(false)}
//                       >
//                         Cancel
//                       </SoftButton>
//                       <SoftButton 
//                         type="submit" 
//                         variant="gradient" 
//                         color="info"
//                       >
//                         Update
//                       </SoftButton>
//                     </SoftBox>
//                   </form>
//                 </SoftBox>
//               </Card>
//             </Grid>
//           </Grid>
//         </DialogContent>
//       </Dialog>
//       <Footer />
//     </DashboardLayout>
//   );
// };

// export default StatusPage;

import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import { APIData, org } from 'authentication/APIData';
import { IoArrowBack } from "react-icons/io5";
import { FaEdit } from 'react-icons/fa';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftAvatar from "components/SoftAvatar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import {
  Card,
  Dialog,
  DialogContent,
  CircularProgress,
  Backdrop,
  Grid
} from '@mui/material';

const StatusPage = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reloading, setReloading] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [fetchedPrivileges, setFetchedPrivileges] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  
  const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
  const fetchedDept = localStorage.getItem("Depart Details");

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" }
  ];

  useEffect(() => {
    if (sessiondetails) {
      if (sessiondetails.userType === "MEMBER") {
        if (Array.isArray(sessiondetails.privileges)) {
          sessiondetails.privileges.forEach(privilege => {
            if (privilege.dept === fetchedDept) {
              setFetchedPrivileges(privilege.privileges);
            }
          });
        }
      } else if (sessiondetails.userType === "user") {
        setFetchedPrivileges("1000");
      } else if (sessiondetails.userType === "SUPERADMIN") {
        setFetchedPrivileges("1111");
      }
    }
  }, [sessiondetails, fetchedDept]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const url = `${APIData.api}login-type/users?org=${org}`;
      const response = await axios.get(url, { headers: APIData.headers });
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch data',
        confirmButtonColor: '#3085d6'
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUpdate = (task) => {
    setSelectedTask(task);
    setIsDialogOpen(true);
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedTask(prevTask => ({
        ...prevTask,
        status: selectedOption.value
      }));
    }
  };

  const handleUpdate2 = async (e) => {
    e.preventDefault();
    setReloading(true);
    try {
      const form = {
        username: selectedTask.username,
        password: selectedTask.password,
        status: selectedTask.status,
        role: selectedTask.role,
        email_id: selectedTask.email_id,
        phone_number: selectedTask.phone_number,
        updated_by: sessiondetails?.userName,
        org: org
      };

      const url = `${APIData.api}login-type/user`;
      const response = await axios.post(url, form, { headers: APIData.headers });

      if (response.status === 201 || response.status === 200) {
        setTasks(prevTasks => 
          prevTasks.map(task => 
            task.username === selectedTask.username ? { ...task, ...form } : task
          )
        );
        
        setIsDialogOpen(false);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Updated successfully',
          confirmButtonColor: '#3085d6'
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Update failed',
          confirmButtonColor: '#3085d6'
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to update',
        confirmButtonColor: '#3085d6'
      });
    }
    setReloading(false);
  };

  // Filter tasks based on search term
  const filteredTasks = tasks.filter(task =>
    task.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Get paginated tasks
  const paginatedTasks = filteredTasks.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );

  const columns = [
    { name: "User ID", align: "left" },
    { name: "Status", align: "center" },
    ...(fetchedPrivileges === "1111" ? [{ name: "Action", align: "center" }] : [])
  ];

  const rows = paginatedTasks.map((task) => ({
    "User ID": (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar
            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(task.username)}&background=random`}
            alt={task.username}
            size="sm"
            variant="rounded"
          />
        </SoftBox>
        <SoftTypography variant="button" fontWeight="medium">
          {task.username}
        </SoftTypography>
      </SoftBox>
    ),
    Status: (
      <SoftBadge
        variant="gradient"
        badgeContent={task.status}
        color={task.status === "active" ? "success" : "error"}
        size="xs"
        container
      />
    ),
    Action: fetchedPrivileges === "1111" && (
      <SoftTypography
        component="a"
        variant="button"
        color="info"
        fontWeight="medium"
        onClick={() => handleUpdate(task)}
        sx={{ cursor: 'pointer' }}
      >
        <FaEdit />
      </SoftTypography>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Status Management</SoftTypography>
              <SoftBox>
                <SoftInput
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  size="small"
                />
              </SoftBox>
            </SoftBox>

            {loading ? (
              <SoftBox display="flex" justifyContent="center" p={3}>
                <CircularProgress />
              </SoftBox>
            ) : (
              <>
                <SoftBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </SoftBox>
                <SoftBox
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  p={3}
                >
                  <SoftBox display="flex" gap={1} alignItems="center">
                    <SoftTypography variant="caption" color="secondary">
                      {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredTasks.length)} of {filteredTasks.length}
                    </SoftTypography>
                    <SoftButton
                      variant="text"
                      color="info"
                      onClick={() => setPage(prev => Math.max(0, prev - 1))}
                      disabled={page === 0}
                      iconOnly
                    >
                      <KeyboardArrowLeft />
                    </SoftButton>
                    <SoftButton
                      variant="text"
                      color="info"
                      onClick={() => setPage(prev => Math.min(Math.ceil(filteredTasks.length / itemsPerPage) - 1, prev + 1))}
                      disabled={page >= Math.ceil(filteredTasks.length / itemsPerPage) - 1}
                      iconOnly
                    >
                      <KeyboardArrowRight />
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </>
            )}
          </Card>
        </SoftBox>
      </SoftBox>

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={reloading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <DialogContent>
          <Grid container spacing={0} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Card sx={{ height: 'auto' }}>
                <SoftBox p={3} mb={1} display="flex" alignItems="center">
                  <SoftBox onClick={() => setIsDialogOpen(false)} sx={{ cursor: 'pointer' }}>
                    <AiOutlineCloseCircle />
                  </SoftBox>
                  <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                    Update Status Of {selectedTask?.username}
                  </SoftTypography>
                </SoftBox>

                <SoftBox pt={1} pb={3} px={3}>
                  <form onSubmit={handleUpdate2}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6">User ID</SoftTypography>
                      <SoftInput
                        value={selectedTask?.username || ""}
                        disabled
                        size="small"
                      />
                    </SoftBox>

                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" mb={1}>Status</SoftTypography>
                      <CustomSelect
                        options={statusOptions}
                        placeholder="Select Status"
                        onChange={handleSelectChange}
                        value={selectedTask?.status}
                      />
                    </SoftBox>

                    <SoftBox mb={2}>
                      <SoftTypography variant="h6">Updated By</SoftTypography>
                      <SoftInput
                        value={sessiondetails?.userName}
                        disabled
                        size="small"
                      />
                    </SoftBox>

                    <SoftBox mt={2} display="flex" justifyContent="space-between">
                      <SoftButton 
                        variant="gradient" 
                        color="secondary" 
                        onClick={() => setIsDialogOpen(false)}
                      >
                        Cancel
                      </SoftButton>
                      <SoftButton 
                        type="submit" 
                        variant="gradient" 
                        color="info"
                      >
                        Update
                      </SoftButton>
                    </SoftBox>
                  </form>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
};

export default StatusPage;