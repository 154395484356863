import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import axios from "axios";
import { APIData, org } from "authentication/APIData";
import SoftButton from "components/SoftButton";
import Footer from "examples/Footer";
import { Grid, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Swal from "sweetalert2";
import SoftInput from "components/SoftInput";

function ListSingleAssetGroup() {
    const { id } = useParams();
    const [assetDetails, setAssetList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userdata, setUserData] = useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState(false);
    const [role, setRole] = useState("");
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [userDepartment, setUserDepartment] = useState("");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            console.log(sessionDetails.user);

            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(accessLevel);
        const Department = localStorage.getItem('Depart Details');
        setUserDepartment(Department);
    }, []);

    const fetchAssetGroupList = () => {
        const url = `${APIData.api}asset-list/asset-group-id-org?assetGroupId=${id}&org=${org}`;
        axios
            .get(url, { headers: APIData.headers })
            .then((resp) => {
                setAssetList(resp.data);
                setLoading(false);
            })
            .catch((err) => {
                console.error("Error fetching asset group data:", err);
                setLoading(false);
            });
    };

    useEffect(() => {
        const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
        setUserData(userData_Local);
        if (userData_Local) {
            setRole(userData_Local.role);
        }
    }, []);

    useEffect(() => {
        fetchAssetGroupList();
    }, [id]);

    const filteredAssets = assetDetails.filter(asset => 
        asset.asset_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        asset.asset_id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        asset.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        asset.allocation.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleNewAssetClick = () => {
        navigate(`/add-asset-by-group/${id}`);
    };

    const handleBack = () => {
        navigate("/assets-list");
    };

    const handleAquireClick = (asset) => {
        setSelectedAsset(asset);
        setDialogOpen(true);
        setDescription("");
        setDescriptionError(false);
    };

    const handleRemoveClick = (asset) => {
        Swal.fire({
            title: `Are you sure you want to delete ${asset.asset_name}?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "success",
            cancelButtonColor: "error",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const url = `${APIData.api}asset-list/id?assetListId=${asset.asset_id}`;
                    await axios.delete(url, { headers: APIData.headers });
                    Swal.fire({
                        icon: "success",
                        title: "Deleted!",
                        text: `${asset.asset_name} has been deleted.`,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    fetchAssetGroupList();
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Failed",
                        text: "Could not delete the asset",
                        footer: error.message
                    });
                }
            }
        });
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedAsset(null);
        setDescriptionError(false);
    };

    const handleSubmit = async () => {
        if (!description) {
            setDescriptionError(true);
            return;
        }

        try {
            const allocationRequest = {
                allocation: "Requested",
                asset_id: selectedAsset.asset_id,
                asset_name: selectedAsset.asset_name,
                description: description,
                emp_id: userdata.userName,
                id: 0,
                issued_by: "",
                issued_date: "",
                org: org,
                return_date: "",
            };

            const url = `${APIData.api}employee-assets/`;
            const response = await axios.post(url, allocationRequest, { headers: APIData.headers });

            handleDialogClose();

            Swal.fire({
                icon: "success",
                title: "Success!",
                text: `Successfully requested for ${selectedAsset.asset_name}`,
                showConfirmButton: false,
                timer: 1500
            });

            setTimeout(() => {
                fetchAssetGroupList();
            }, 1500);

        } catch (error) {
            handleDialogClose();
            console.error("Error details:", error.response ? error.response.data : error.message);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data.errorDesc,
            });
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox mb={3}>
                <SoftBox pt={2} px={2}>
                    <SoftBox
                        mb={0.5}
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <Tooltip title="Back to Asset Group List" arrow>
                            <SoftButton sx={{ ml: 2 }} onClick={handleBack}>Back</SoftButton>
                        </Tooltip>
                        <SoftBox display="flex" alignItems="center" gap={2}>
                            <SoftBox>
                                <SoftInput
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    size="small"
                                />
                            </SoftBox>
                            <Tooltip title="Add new asset group" arrow>
                                <SoftButton variant="gradient" color="info" size="small" sx={{ ml: 2 }} onClick={handleNewAssetClick}>+ Add</SoftButton>
                            </Tooltip>
                        </SoftBox>
                    </SoftBox>
                    <SoftBox pt={2} mb={0.5} pl={2}>
                        <SoftTypography variant="h5" fontWeight="medium">
                            Stores
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox mb={1} pl={2}>
                        <SoftTypography variant="h6" fontWeight="regular" color="text">
                            Assets Lists By Group
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
                <SoftBox p={4}>
                    <Grid container alignItems="flex-start" spacing={3}>
                        {loading ? (
                            <Grid item xs={12}>
                                <SoftTypography variant="body1" textAlign="center">Loading... </SoftTypography>
                            </Grid>
                        ) : filteredAssets.length > 0 ? (
                            filteredAssets.map((asset) => (
                                <Grid item xs={12} sm={6} key={asset.asset_id}>
                                    <Card variant="outlined" sx={{ height: '350px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                        <CardContent>
                                            <SoftTypography pt={2} variant="h6" fontWeight="medium">
                                                Asset Name: {asset.asset_name}
                                            </SoftTypography>
                                            <SoftTypography variant="body2">
                                                Asset Id: {asset.asset_id}
                                            </SoftTypography>
                                            <SoftTypography variant="body2">
                                                Asset Group Id: {asset.asset_group_id}
                                            </SoftTypography>
                                            <SoftTypography variant="body2">
                                                Asset Status: {asset.asset_status}
                                            </SoftTypography>
                                            <SoftTypography variant="body2">
                                                Cost: ${asset.cost}
                                            </SoftTypography>
                                            <SoftTypography variant="body2">
                                                Type: {asset.type}
                                            </SoftTypography>
                                            <SoftTypography variant="body2">
                                                Allocation: {asset.allocation}
                                            </SoftTypography>
                                            <SoftTypography variant="body2">
                                                Product Id: {asset.product_id}
                                            </SoftTypography>
                                            <SoftBox
                                                mt={4}
                                                mb={1}
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                gap="8px"
                                            >
                                                <SoftButton
                                                    variant="gradient"
                                                    color="info"
                                                    onClick={() => handleAquireClick(asset)}
                                                    sx={{ flex: 1 }}
                                                >
                                                    Aquire
                                                </SoftButton>

                                                {(permission === 1111 || isSuperAdmin) && (
                                                    <SoftButton
                                                        variant="gradient"
                                                        color="error"
                                                        onClick={() => handleRemoveClick(asset)}
                                                        sx={{ flex: 1 }}
                                                    >
                                                        Remove
                                                    </SoftButton>
                                                )}
                                            </SoftBox>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        ) : (
                            <Grid item xs={12}>
                                <SoftTypography variant="body1" textAlign="center">
                                    No matching assets found.
                                </SoftTypography>
                            </Grid>
                        )}
                    </Grid>
                </SoftBox>
            </SoftBox>

            <Footer />

            <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth>
                <DialogTitle textAlign="center">Asset Request</DialogTitle>
                <DialogContent>
                    <SoftBox mb={2}>
                        <SoftTypography variant="h6">Asset ID</SoftTypography>
                        <FormControl fullWidth>
                            <SoftInput
                                value={selectedAsset ? selectedAsset.asset_id : ""}
                                disabled
                            />
                        </FormControl>
                    </SoftBox>

                    <SoftBox mb={2}>
                        <SoftTypography variant="h6">Asset Name</SoftTypography>
                        <FormControl fullWidth>
                            <SoftInput
                                value={selectedAsset ? selectedAsset.asset_name : ""}
                                disabled
                            />
                        </FormControl>
                    </SoftBox>

                    <SoftBox mb={2}>
                        <SoftTypography variant="h6">Description</SoftTypography>
                        <FormControl fullWidth>
                            <SoftInput
                                placeholder="Enter description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                error={descriptionError}
                                required
                            />
                        </FormControl>
                    </SoftBox>
                </DialogContent>
                <DialogActions>
                    <SoftButton onClick={handleDialogClose}>Cancel</SoftButton>
                    <SoftButton onClick={handleSubmit} variant="gradient" color="info">Submit</SoftButton>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
}

export default ListSingleAssetGroup;