import React, { useState, useEffect } from 'react';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftTypography from 'components/SoftTypography';
import axios from 'axios';
import Swal from 'sweetalert2';
import { APIData, org } from 'authentication/APIData';
import Table from 'examples/Tables/Table';
import SoftAvatar from 'components/SoftAvatar';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import SoftBadge from 'components/SoftBadge';
import { Link } from 'react-router-dom';
import Icon from "@mui/material/Icon";

const Invoice = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const navigate = useNavigate();
  const Name = ({ name, email }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar
          src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
          alt={name}
          size="sm"
          variant="rounded"
        />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
  Name.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };
  const fetchInvoices = () => {
    const url = APIData.api + `invoices?org=${org}`;
    axios
      .get(url, { headers: APIData.headers })
      .then((resp) => {
        console.log(resp.data);

        setInvoiceData(resp.data);
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Error Fetching Invoices',
          text: 'Please try again later.',
          confirmButtonText: 'OK'
        });
      });
  };

  const downloadInvoice = (invoiceId) => {
    const url = `${APIData.api}invoices/download/${invoiceId}`;

    axios
      .get(url, {
        headers: APIData.headers,
        responseType: 'blob'
      })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invoice_${invoiceId}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.error("Download error:", err);
      });
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  const navigateToAddInvoice = () => {
    navigate("/add-invoice");
  }


  const columns = [
    { name: "User", align: "left" },
    { name: "InvoiceType", align: "left" },
    { name: "PaymentStatus", align: "left" },
    { name: "Date", align: "left" },
    { name: "Action", align: "left" }
  ];
  const rows = invoiceData.map((invoice) => ({
    User: <Name name={invoice.toName} email={invoice.custEmailId} />,
    InvoiceType: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {invoice.invType}
      </SoftTypography>
    ),
    PaymentStatus: (
      <SoftBadge
        variant="gradient"
        badgeContent={invoice.paymentstatus.toLowerCase()}
        color={invoice.paymentstatus.toLowerCase() === "received" ? "success" : "secondary"}
        size="xs"
        container
      />
    ),
    Date: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {invoice.date}
      </SoftTypography>
    ),
    Action: (
      <>
        <Link>
          <SoftTypography
            component="a"
            variant="button"
            color="info"
            fontWeight="medium"
            onClick={() => downloadInvoice(invoice.invoiceid)}
          >
            Download
          </SoftTypography>
        </Link>
      </>
    ),
  }));
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 10, marginTop: 10 }}>
        <SoftButton variant="gradient" color="info" size="small" onClick={navigateToAddInvoice}>
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          &nbsp;Add Invoice
        </SoftButton>
      </div>
      {invoiceData != null && <Table columns={columns} rows={rows} />}
      <Footer />
    </DashboardLayout>
  );
};

export default Invoice;