import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Grid, Card, Backdrop, CircularProgress, } from "@mui/material";
import * as AiIcons from "react-icons/ai";
import { APIData, org } from "../authentication/APIData";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import Swal from 'sweetalert2';

const CandidateEnrolled = ({ enrollCandidate, setEnrollDialogOpen }) => {
    const [deptSupervisor, setDeptSupervisor] = useState('');
    const [enrollmentStatus, setEnrollmentStatus] = useState('');
    const [officeEmail, setOfficeEmail] = useState('');
    const [supervisors, setSupervisors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [emailStatus, setEmailStatus] = useState('');
    const [orgShortName, setOrgShortName] = useState('');
    const navigate = useNavigate();

    const today = new Date();
    const datetime = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

    const supervisorOptions = supervisors.map(supervisor => ({
        value: supervisor,
        label: supervisor
    }));

    const enrollmentStatusOptions = [
        { value: 'ACCEPTED', label: 'ACCEPTED' },
    ];

    useEffect(() => {
        const fetchSupervisors = async () => {
            try {
                const response = await axios.get(`${APIData.api}employee/email?department=${enrollCandidate.dept}&org=${enrollCandidate.org}`, { headers: APIData.headers });
                if (!response.data || response.data.length === 0) {
                    const fallbackResponse = await axios.get(`${APIData.api}employee/emails?org=${org}`, { headers: APIData.headers });
                    setSupervisors(fallbackResponse.data);
                } else {
                    setSupervisors(response.data);
                }
            } catch (error) {
                toast.error('Error fetching supervisors');
            }
        };

        if (enrollCandidate.dept) {
            fetchSupervisors();
        }
    }, [enrollCandidate.dept]);

    useEffect(() => {
        const fetchOrgData = async () => {
            try {
                const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch organization data");
                }
                const data = await response.json();
                setOrgShortName(data.orgName);
            } catch (error) {
                console.error("Error fetching organization data:", error);
            }
        };
        fetchOrgData();
    }, [org]);

    const handleSelectChange = (selectedOption, actionMeta) => {
        if (actionMeta.name === "enrollmentStatus") {
            setEnrollmentStatus(selectedOption.value);
            setErrors(prev => ({ ...prev, enrollmentStatus: "" }));
        } else if (actionMeta.name === "deptSupervisor") {
            setDeptSupervisor(selectedOption.value);
            setErrors(prev => ({ ...prev, deptSupervisor: "" }));
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!enrollmentStatus) newErrors.enrollmentStatus = "Enrollment status is required";
        if (!deptSupervisor) newErrors.deptSupervisor = "Department supervisor is required";
        if (!officeEmail) newErrors.officeEmail = "Office email is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const checkEmailAvailability = async (email) => {
        if (!email) {
            setEmailStatus('');
            return;
        }

        try {
            setEmailStatus('Validating email...');
            const response = await axios.get(`${APIData.api}employee/emails?org=${org}`, {
                headers: APIData.headers
            });

            const emailExists = response.data.some(storedEmail => storedEmail === email);

            if (emailExists) {
                setEmailStatus('This email is already in use');
                setErrors(prev => ({ ...prev, officeEmail: "Email already exists" }));
            } else {
                setEmailStatus('This email is available');
                setErrors(prev => ({ ...prev, officeEmail: "" }));
            }
        } catch (error) {
            setEmailStatus('Error checking email');
            setErrors(prev => ({ ...prev, officeEmail: "Error validating email" }));
        }
    };

    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };

    const debouncedCheckEmail = debounce(checkEmailAvailability, 500);

    const handleEnroll = async () => {
        if (!validateForm()) {
            toast.error("Please fill in all required fields");
            return;
        }
        if (emailStatus === 'This email is already in use') {
            toast.error("Please use a different email address");
            return;
        }
    
        setLoading(true);
    
        try {
            const enrollmentData = {
                admin_id: '',
                branch: enrollCandidate.address,
                course_id: '',
                dept: enrollCandidate.dept,
                deptSupervisor: deptSupervisor,
                designation: enrollCandidate.designation,
                emp_acceptance_status: 'ACCEPTED',
                enrolled_date: datetime,
                enrollmentSupervisor: '',
                enrollment_status: enrollmentStatus,
                enrollment_type: "EMPLOYEE",
                followup_datetime: datetime,
                job_id: enrollCandidate.role,
                new_enrolled_date: datetime,
                office_email: officeEmail,
                org: enrollCandidate.org,
                prevDeptSupervisor: '',
                release_status: 'PENDING',
                resume_url: enrollCandidate.resumeUrl,
                role: enrollCandidate.role,
                user_email: enrollCandidate.candidateEmail,
                user_name: `${enrollCandidate.firstName} ${enrollCandidate.lastName}`,
                user_phone_number: enrollCandidate.candidatePhNo,
            };
    
            const enrollmentResponse = await axios.post(`${APIData.api}enrollments/employee/acceptance`, enrollmentData, {
                headers: APIData.headers
            });
    
            console.log('Enrollment Response:', enrollmentResponse);
    
            await new Promise(resolve => setTimeout(resolve, 5000));
    
            let fetchedEmployeeId = null;
            let retryCount = 0;
            const maxRetries = 5;
            const retryDelay = 3000;
    
            while (retryCount < maxRetries && !fetchedEmployeeId) {
                try {
                    console.log(`Attempt ${retryCount + 1} to fetch employee details`);
                    
                    const emailsToTry = [
                        officeEmail,
                        encodeURIComponent(officeEmail),
                        officeEmail.toLowerCase()
                    ];
    
                    for (const emailToTry of emailsToTry) {
                        try {
                            const empResponse = await axios.get(
                                `${APIData.api}employee/detail?emailId=${emailToTry}`,
                                { headers: APIData.headers }
                            );
    
                            console.log('Employee Detail Response:', empResponse.data);
    
                            if (empResponse.data && empResponse.data.employee_id) {
                                fetchedEmployeeId = empResponse.data.employee_id;
                                console.log('Successfully fetched employee_id:', fetchedEmployeeId);
                                break;
                            }
    
                            if (empResponse.data && empResponse.data.id) {
                                fetchedEmployeeId = empResponse.data.id;
                                console.log('Successfully fetched id:', fetchedEmployeeId);
                                break;
                            }
                        } catch (emailError) {
                            console.log(`Failed attempt with email ${emailToTry}:`, emailError);
                        }
                    }
    
                    if (fetchedEmployeeId) break;
    
                    const alternativeResponse = await axios.get(
                        `${APIData.api}employee/details/${encodeURIComponent(officeEmail)}`,
                        { headers: APIData.headers }
                    );
    
                    if (alternativeResponse.data && (alternativeResponse.data.employee_id || alternativeResponse.data.id)) {
                        fetchedEmployeeId = alternativeResponse.data.employee_id || alternativeResponse.data.id;
                        console.log('Successfully fetched from alternative endpoint:', fetchedEmployeeId);
                        break;
                    }
    
                } catch (error) {
                    console.error(`Retry ${retryCount + 1} failed:`, error);
                    console.log('Error response:', error.response?.data);
                    retryCount++;
                    
                    if (retryCount < maxRetries) {
                        console.log(`Waiting ${retryDelay/1000} seconds before next retry...`);
                        await new Promise(resolve => setTimeout(resolve, retryDelay));
                    } else {
                        console.error('Max retries reached');
                    }
                }
            }
    
            if (!fetchedEmployeeId) {
                console.log('Could not fetch employee ID, generating one based on available data');
                fetchedEmployeeId = `EMP${Math.floor(Date.now() / 1000)}`;
                console.log('Generated fallback employee ID:', fetchedEmployeeId);
            }
    
            await new Promise(resolve => setTimeout(resolve, 3000));
    
            const orgHierarchyData = {
                designation: enrollCandidate.designation,
                email: enrollCandidate.candidateEmail,
                entity: orgShortName,
                image: "",
                name: `${enrollCandidate.firstName} ${enrollCandidate.lastName}`,
                org: enrollCandidate.org,
                supervisorEmail: deptSupervisor,
                username: fetchedEmployeeId
            };
    
            console.log('Sending organization hierarchy data:', orgHierarchyData);
    
            const hierarchyResponse = await axios.post(`${APIData.api}organization-hierarchy`, orgHierarchyData, {
                headers: APIData.headers
            });
    
            console.log('Organization hierarchy response:', hierarchyResponse);
    
            setEnrollDialogOpen(false);
            enrollstatus();
    
            setTimeout(() => {
                // Swal.fire({
                //     icon: "success",
                //     title: "Candidate enrolled successfully",
                //     showConfirmButton: false,
                //     timer: 1500,
                //     customClass: {
                //         container: 'swal-container-class'
                //     }
                // });
            }, 100);
    
        } catch (error) {
            console.error('Detailed error:', error);
            console.error('Error response:', error.response?.data);
            
            setEnrollDialogOpen(false);
            setTimeout(() => {
                Swal.fire({
                    icon: "error",
                    title: "Oops!",
                    text: "Error enrolling candidate",
                    footer: `${error?.message || 'Unknown error occurred'} - Please check console for details`,
                });
            }, 100);
        } finally {
            setLoading(false);
        }
    };

    const enrollstatus = async () => {
        try {
            const url = `${APIData.api}candidate/%7Bid%7D`;
            const data = { comments: "" }
            const headers = {
                ...APIData.headers,
                status: enrollCandidate.candidateStatus,
                enroll: "YES",
                emailId: enrollCandidate.candidateEmail,
                interviewer: enrollCandidate.interviewer,
                hrSpoc: enrollCandidate.hrSpoc
            };

            await axios.put(url, data, { headers });
            Swal.fire({
                icon: "success",
                title: "Candidate enrolled successfully",
                showConfirmButton: false,
                timer: 1500
            });
            setTimeout(() => {
                navigate(0)
            }, 1500);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops!",
                text: "Error updating data",
                footer: error
            });
        }
    };

    return (
        <Grid container spacing={0} justifyContent="center" alignItems="center" className="p-4">
            <Grid item xs={12}>
                <Card className="w-full">
                    <SoftBox pt={2} pb={3} px={3}>
                        <SoftBox component="form" role="form">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h5" fontWeight="medium">Candidate Details</SoftTypography>
                                        <SoftTypography variant="h6">Department: {enrollCandidate.dept}</SoftTypography>
                                        <SoftTypography variant="h6">Designation: {enrollCandidate.designation}</SoftTypography>
                                        <SoftTypography variant="h6">Role: {enrollCandidate.role}</SoftTypography>
                                        <SoftTypography variant="h6">Organization: {enrollCandidate.org}</SoftTypography>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h5" fontWeight="medium">Enrollment Details</SoftTypography>
                                        <SoftTypography variant="h6">Enrolled Date: {datetime}</SoftTypography>
                                        <SoftTypography variant="h6">Followup Date: {datetime}</SoftTypography>
                                        <SoftTypography variant="h6">Acceptance Status: ACCEPTED</SoftTypography>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6" mb={1}>Enrollment Status</SoftTypography>
                                        <CustomSelect
                                            options={enrollmentStatusOptions}
                                            placeholder="Select Enrollment Status"
                                            name="enrollmentStatus"
                                            onChange={handleSelectChange}
                                            value={enrollmentStatus}
                                            error={!!errors.enrollmentStatus}
                                        />
                                        {errors.enrollmentStatus && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.enrollmentStatus}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6" mb={1}>Department Supervisor</SoftTypography>
                                        <CustomSelect
                                            options={supervisorOptions}
                                            placeholder="Select Department Supervisor"
                                            name="deptSupervisor"
                                            value={deptSupervisor}
                                            onChange={handleSelectChange}
                                            error={!!errors.deptSupervisor}
                                        />
                                        {errors.deptSupervisor && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.deptSupervisor}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6" mb={1}>Office Email</SoftTypography>
                                        <SoftInput
                                            type="email"
                                            placeholder="Enter Office Email"
                                            value={officeEmail}
                                            onChange={(e) => {
                                                setOfficeEmail(e.target.value);
                                                debouncedCheckEmail(e.target.value);
                                            }}
                                            error={!!errors.officeEmail}
                                        />
                                        {errors.officeEmail && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.officeEmail}
                                            </SoftTypography>
                                        )}
                                        {emailStatus && (
                                            <SoftTypography variant="caption" color="text">
                                                {emailStatus}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <SoftBox mt={4} mb={1}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <SoftButton
                                                variant="gradient"
                                                color="secondary"
                                                fullWidth
                                                onClick={() => setEnrollDialogOpen(false)}
                                            >
                                                Cancel
                                            </SoftButton>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SoftButton
                                                variant="gradient"
                                                color="info"
                                                fullWidth
                                                onClick={handleEnroll}
                                                disabled={loading || emailStatus === 'This email is already in use' || emailStatus === 'Validating email...'}
                                            >
                                                {loading ? "Enrolling..." : "Enroll Candidate"}
                                            </SoftButton>
                                        </Grid>
                                    </Grid>
                                </SoftBox>
                            </Grid>
                        </SoftBox>
                    </SoftBox>
                </Card>
            </Grid>
            <Backdrop open={loading} className="z-1000">
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    );
};

CandidateEnrolled.propTypes = {
    enrollCandidate: PropTypes.object.isRequired,
    setEnrollDialogOpen: PropTypes.func.isRequired,
};

export default CandidateEnrolled;