/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Grid from "@mui/material/Grid";
// import SoftBox from "components/SoftBox";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import Footer from "examples/Footer";
// import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
// import Header from "layouts/profile/components/Header";
// import { useEffect, useState } from "react";
// import Swal from "sweetalert2";
// import { org } from "authentication/APIData";
// import { APIData } from "authentication/APIData";

// const formatDate = (dateString) => {
//   const date = new Date(dateString);
//   const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
//   const day = String(date.getDate()).padStart(2, '0');
//   const year = date.getFullYear();
//   return `${month}-${day}-${year}`;
// };

// function Overview() {
//   const [profileInfo, setProfileData] = useState({});
//   const [userdata, setUserData] = useState();



//   useEffect(() => {
//     const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
//     setUserData(userData_Local);
//     console.log(userData_Local);
//   }, []);

//   useEffect(() => {
//     if (userdata) {
//       const fetchData = async () => {
//         try {
//           let url = `${APIData.api}employee/${userdata.userName}`;
//           const response = await fetch(url, { headers: APIData.headers });
//           console.log(response);

//           const data = await response.json();
//           setProfileData(data);
//         } catch (error) {
//           Swal.fire({
//             title: "Error!",
//             text: `Failed to fetch "${org}" details`,
//             icon: "error",
//             confirmButtonText: "OK",
//           });
//         }
//       };

//       fetchData();
//     }
//   }, [userdata]);



//   return (
//     <DashboardLayout>
//       <Header />
//       <SoftBox mt={5} mb={3}>
//         <Grid container spacing={3}>
//           <Grid item xs={12} md={6} xl={4}>
//             <ProfileInfoCard
//               title="profile information"
//               info={{
//                 fullName: `${profileInfo.name ? profileInfo.name.charAt(0).toUpperCase() + profileInfo.name.slice(1).toLowerCase() : "Name"}`,
//                 mobile: `${profileInfo.phone_number}`,
//                 email: `${profileInfo.officeEmail || profileInfo.email}`,
//                 location: `${profileInfo.address}`,
//                 DOB: `${formatDate(profileInfo.dob)}`,
//                 joinedDate: `${formatDate(profileInfo.year_of_appointment)}`,
//                 Qualification: `${profileInfo.qualification}`,
//                 gender: `${profileInfo.gender}`,
//               }}

//               action={{ route: `/edit-profile/${profileInfo.employee_id}`, tooltip: "Edit Profile" }}
//             />
//           </Grid>

//           <Grid item xs={12} md={6} xl={4}>
//             <ProfileInfoCard
//               title="Department Information"
//               info={{
//                 Department: profileInfo.dept ? profileInfo.dept[0] : 'N/A',
//                 Designation: profileInfo.role_privileges && profileInfo.role_privileges[0] ? profileInfo.role_privileges[0].designation : 'N/A',
//                 Role: profileInfo.role_privileges && profileInfo.role_privileges[0] ? profileInfo.role_privileges[0].role : 'N/A',
//                 Supervisor: profileInfo.supervisor ? profileInfo.supervisor[0] : 'N/A',
//                 Branch: profileInfo.branch
//               }}
//               action={{}}
//               showAction={false}
//             />
//           </Grid>

//         </Grid>
//       </SoftBox>


//       <Footer />
//     </DashboardLayout>
//   );
// }

// export default Overview;


import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import IdCard1 from 'layouts/idCards/idCard1';
import IdCard2 from 'layouts/idCards/idCard2';
import IdCard3 from 'layouts/idCards/idCard3';
import IdCard4 from 'layouts/idCards/idCard4';
import IdCard5 from 'layouts/idCards/idCard5';
import Header from "layouts/profile/components/Header";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { org } from "authentication/APIData";
import { APIData } from "authentication/APIData";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};

function Overview() {
  const [profileInfo, setProfileData] = useState({});
  const [userdata, setUserData] = useState();
  const [orgDetails, setOrgDetails] = useState({
    orgName: '',
    orgAddress: '',
    logoUrl: '',
    idCardTemplate: '1'
  });

  useEffect(() => {
    const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
    setUserData(userData_Local);
  }, []);

  useEffect(() => {
    if (userdata) {
      const fetchProfileData = async () => {
        try {
          let url = `${APIData.api}employee/${userdata.userName}`;
          const response = await fetch(url, { headers: APIData.headers });
          const data = await response.json();
          setProfileData(data);
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: `Failed to fetch "${org}" details`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      };

      const fetchOrgData = async () => {
        try {
          const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`, {
            headers: APIData.headers
          });
          const orgData = await response.json();
          setOrgDetails({
            orgName: orgData.orgName || '',
            orgAddress: orgData.orgAddress || '',
            logoUrl: orgData.orgLogo || '',
            idCardTemplate: orgData.idCardTemplate || '1'
          });
        } catch (error) {
          console.error("Error fetching organization data:", error);
        }
      };

      fetchProfileData();
      fetchOrgData();
    }
  }, [userdata]);

  const renderIdCard = () => {
    const props = {
      name: profileInfo.name || 'Employee Name',
      dob: formatDate(profileInfo.dob),
      id: profileInfo.employee_id,
      photo: profileInfo.photoUrl,
      role: profileInfo.access_profiles,
      orgName: orgDetails.orgName,
      orgAddress: orgDetails.orgAddress,
      logoUrl: orgDetails.logoUrl
    };

    switch (orgDetails.idCardTemplate) {
      case "1":
        return <IdCard1 {...props} />;
      case "2":
        return <IdCard2 {...props} />;
      case "3":
        return <IdCard3 {...props} />;
      case "4":
        return <IdCard4 {...props} />;
      case "5":
        return <IdCard5 {...props} />;
      default:
        return <IdCard1 {...props} />;
    }
  };

  return (
    <DashboardLayout>
      <Header />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={4}>
            <ProfileInfoCard
              title="profile information"
              info={{
                fullName: `${profileInfo.name ? profileInfo.name.charAt(0).toUpperCase() + profileInfo.name.slice(1).toLowerCase() : "Name"}`,
                mobile: `${profileInfo.phone_number}`,
                email: `${profileInfo.officeEmail || profileInfo.email}`,
                location: `${profileInfo.address}`,
                DOB: `${formatDate(profileInfo.dob)}`,
                joinedDate: `${formatDate(profileInfo.year_of_appointment)}`,
                Qualification: `${profileInfo.qualification}`,
                gender: `${profileInfo.gender}`,
              }}
              action={{ route: `/edit-profile/${profileInfo.employee_id}`, tooltip: "Edit Profile" }}
            />
          </Grid>

          <Grid item xs={12} md={6} xl={4}>
            <ProfileInfoCard
              title="Department Information"
              info={{
                Department: profileInfo.dept ? profileInfo.dept[0] : 'N/A',
                Designation: profileInfo.role_privileges && profileInfo.role_privileges[0] ? profileInfo.role_privileges[0].designation : 'N/A',
                Role: profileInfo.role_privileges && profileInfo.role_privileges[0] ? profileInfo.role_privileges[0].role : 'N/A',
                Supervisor: profileInfo.supervisor ? profileInfo.supervisor[0] : 'N/A',
                Branch: profileInfo.branch
              }}
              action={{}}
              showAction={false}
            />
          </Grid>

          <Grid item xs={12} md={6} xl={4}>
            <SoftBox style={{ 
              border: '1px solid #e0e0e0', 
              borderRadius: '8px', 
              padding: '16px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              {renderIdCard()}
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Overview;