import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { APIData, org } from '../authentication/APIData';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Table from "examples/Tables/Table";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Swal from 'sweetalert2';
import SoftAvatar from 'components/SoftAvatar';
import PropTypes from 'prop-types';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';


const EmployeeName = ({ name, email }) => {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar
            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
            alt={name}
            size="sm"
            variant="rounded"
          />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {email}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  };
  
  EmployeeName.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };
  
const AttendanceTable = () => {
    const [attendance, setAttendance] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        fetchAttendance();
    }, []);

    const fetchAttendance = async () => {
        try {
            const response = await axios.get(`${APIData.api}attendance/org?org=${org}`, {
                headers: APIData.headers,
            });
            setAttendance(response.data);
        } catch (error) {
            console.error('Error fetching attendance:', error);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to fetch attendance data.",
                footer: error.message
            });
        }
    };

    const filteredAttendance = attendance.filter(record =>
        Object.values(record).some(value =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const paginatedAttendance = filteredAttendance.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const columns = [
        { name: "Employee", align: "left" },
        { name: "Phone", align: "center" },
        { name: "Login", align: "center" },
        { name: "Logout", align: "center" },
        { name: "Days Present", align: "center" },
        { name: "Month", align: "center" },
        { name: "Year", align: "center" }
    ];

    const rows = paginatedAttendance.map((record) => ({
        Employee: <EmployeeName name={record.empId} email={record.empEmail} />,
        "Phone": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {record.phoneNo}
            </SoftTypography>
        ),
        "Login": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {record.login}
            </SoftTypography>
        ),
        "Logout": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {record.logout}
            </SoftTypography>
        ),
        "Days Present": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {record.daysPresent}
            </SoftTypography>
        ),
        "Month": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {record.month}
            </SoftTypography>
        ),
        "Year": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {record.year}
            </SoftTypography>
        ),
    }));

    return (
        <DashboardLayout>
             <DashboardNavbar/>
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card sx={{ maxWidth: '1200px', margin: '0 auto' }}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftBox display="flex" alignItems="center">
                                <SoftTypography variant="h6" sx={{ ml: 2 }}>Attendance Records</SoftTypography>
                            </SoftBox>
                            <SoftBox display="flex" gap={2}>
                                <SoftInput
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    size="small"
                                />
                            </SoftBox>
                        </SoftBox>

                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table columns={columns} rows={rows} />
                        </SoftBox>

                        <SoftBox p={3}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <SoftTypography variant="caption" color="secondary">
                                        {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, filteredAttendance.length)} of {filteredAttendance.length}
                                    </SoftTypography>
                                </Grid>
                                <Grid item>
                                    <SoftBox display="flex" gap={2}>
                                        <SoftButton
                                            variant="text"
                                            color="info"
                                            size="large"
                                            onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                            disabled={page === 0}
                                        >
                                            <KeyboardArrowLeft />
                                        </SoftButton>
                                        <SoftButton
                                            variant="text"
                                            color="info"
                                            size="large"
                                            onClick={() => setPage(prev => Math.min(Math.ceil(filteredAttendance.length / rowsPerPage) - 1, prev + 1))}
                                            disabled={(page + 1) * rowsPerPage >= filteredAttendance.length}
                                        >
                                            <KeyboardArrowRight />
                                        </SoftButton>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
        </DashboardLayout>
    );
};

export default AttendanceTable;