import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftInput from "components/SoftInput";
import Table from "examples/Tables/Table";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { APIData, org } from "authentication/APIData";
import Grid from "@mui/material/Grid";
import { Tooltip, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import axios from "axios";
import TabNavigation from "./tabNvigation";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const Name = ({ name }) => (
  <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
    <SoftBox mr={2}>
      <SoftAvatar src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`} alt={name} size="sm" variant="rounded" />
    </SoftBox>
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="button" fontWeight="medium">
        {name}
      </SoftTypography>
    </SoftBox>
  </SoftBox>
);

Name.propTypes = {
  name: PropTypes.string.isRequired,
};

const Description = ({ description = '' }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const MAX_CHARS = 20;
  const isLongDescription = description?.length > MAX_CHARS;

  const handleToggleDescription = (e) => {
    e.preventDefault();
    setShowFullDescription(!showFullDescription);
  };

  const truncatedText = isLongDescription
    ? `${description.substring(0, MAX_CHARS).trim()}...`
    : description;

  return (
    <div className="flex flex-col">
      <span className="text-sm text-gray-600 font-medium">
        {showFullDescription ? description : truncatedText}
      </span>
      {isLongDescription && (
        <span onClick={handleToggleDescription} style={{ color: "#1E90FF", cursor: "pointer", fontWeight: "500", marginTop: "4px" }}>
          {showFullDescription ? 'Show Less' : 'Read More'}
        </span>
      )}
    </div>
  );
};

Description.propTypes = {
  description: PropTypes.string,
};

Description.defaultProps = {
  description: '',
};

const AssetsRequests = () => {
  const [assetData, setAssetData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [status, setStatus] = useState("Requested");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
    if (sessionDetails && sessionDetails.user) {
      setCurrentUser(sessionDetails.userName);
    }
    fetchData();
  }, [status]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    setStatus(newValue === 0 ? "Requested" : "Return Requested");
  };

  const handleOpenDialog = (asset) => {
    setSelectedAsset(asset);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedAsset(null);
  };

  const handleStatusUpdate = async (allocationStatus) => {
    if (selectedAsset) {
      setLoading(true);
      const url = `${APIData.api}employee-assets/?asset_id=${selectedAsset.asset_id}&allocation_status=${allocationStatus}&issuedBy=${currentUser}`;
      
      try {
        await axios.put(url, {}, { headers: APIData.headers });
        handleCloseDialog();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Successfully ${allocationStatus} the asset`,
        });
        fetchData();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while updating asset status",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const url = `${APIData.api}employee-assets/status?status=${status}&org=${org}`;
    try {
      const response = await axios.get(url, { headers: APIData.headers });
      setAssetData(response.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Unable to fetch assets",
      });
    } finally {
      setLoading(false);
    }
  };

  const filteredAssets = assetData.filter(asset =>
    asset.asset_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    asset.emp_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
    asset.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    asset.allocation.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (asset.issued_by && asset.issued_by.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Add pagination calculation
  const paginatedAssets = filteredAssets.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );

  const approvalColumns = [
    { name: "Asset_Name", align: "left" },
    { name: "Employee_Id", align: "left" },
    { name: "Description", align: "center" },
    { name: "Allocation", align: "center" },
    { name: "Action", align: "left" },
  ];

  const returnColumns = [
    { name: "Asset_Name", align: "left" },
    { name: "Employee_Id", align: "left" },
    { name: "Description", align: "center" },
    { name: "Issued By", align: "center" },
    { name: "Issued Date", align: "center" },
    { name: "Allocation", align: "center" },
    { name: "Action", align: "left" },
  ];

  const rows = paginatedAssets.map((asset) => ({
    Asset_Name: <Name name={asset.asset_name} />,
    Employee_Id: <SoftTypography variant="caption" color="secondary" fontWeight="medium">{asset.emp_id}</SoftTypography>,
    Description: <Description description={asset.description} />,
    Allocation: <SoftTypography variant="caption" color="secondary" fontWeight="medium">{asset.allocation}</SoftTypography>,
    Action: (
      <SoftBox>
        <SoftButton
          component="a"
          variant="text"
          color="info"
          fontWeight="medium"
          onClick={(e) => {
            e.preventDefault();
            handleOpenDialog(asset);
          }}
        >
          Edit
        </SoftButton>
      </SoftBox>
    ),
    ...(tabValue !== 0 && {
      "Issued By": <SoftTypography variant="caption" color="secondary" fontWeight="medium">{asset.issued_by}</SoftTypography>,
      "Issued Date": <SoftTypography variant="caption" color="secondary" fontWeight="medium">{asset.issued_date}</SoftTypography>,
    }),
  }));

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/assets-list");
  };

  return (
    <DashboardLayout>
      <TabNavigation/>
      <SoftBox py={3} mb={3}>
        <SoftBox>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <SoftTypography variant="h5" fontWeight="medium" sx={{ ml: 3 }}>
              Assets Requests
            </SoftTypography>
          </SoftBox>
          <Card sx={{ mb: 2 }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={6} lg={16} sx={{ ml: "0" }}>
                <AppBar position="static">
                  <Tabs value={tabValue} onChange={handleSetTabValue} sx={{ background: "transparent" }}>
                    <Tab sx={{
                    color: tabValue === 0 ? "info.main" : "text.secondary",
                    fontWeight: tabValue === 0 ? "bold" : "normal",
                    borderBottom: tabValue === 0 ? "4px solid" : "none",
                    borderColor: "info.main",
                  }} label="Approval Request" />
                    <Tab sx={{
                    color: tabValue === 1 ? "info.main" : "text.secondary",
                    fontWeight: tabValue === 1 ? "bold" : "normal",
                    borderBottom: tabValue === 1 ? "4px solid" : "none",
                    borderColor: "info.main",
                  }} label="Return Request" />
                  </Tabs>
                </AppBar>
              </Grid>
            </Grid>
          </Card>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Assets List</SoftTypography>
              <SoftBox>
                <SoftInput
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  size="small"
                />
              </SoftBox>
            </SoftBox>
            <SoftBox sx={{ "& .MuiTableRow-root:not(:last-child)": { "& td": { borderBottom: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}` } } }}>
              <Table columns={tabValue === 0 ? approvalColumns : returnColumns} rows={rows} />
              <SoftBox
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                p={3}
              >
                <SoftBox display="flex" gap={1} alignItems="center">
                  <SoftTypography variant="caption" color="secondary">
                    {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredAssets.length)} of {filteredAssets.length}
                  </SoftTypography>
                  <SoftButton
                    variant="text"
                    color="info"
                    onClick={() => setPage(prev => Math.max(0, prev - 1))}
                    disabled={page === 0}
                    iconOnly
                  >
                    <KeyboardArrowLeft />
                  </SoftButton>
                  <SoftButton
                    variant="text"
                    color="info"
                    onClick={() => setPage(prev => Math.min(Math.ceil(filteredAssets.length / itemsPerPage) - 1, prev + 1))}
                    disabled={page >= Math.ceil(filteredAssets.length / itemsPerPage) - 1}
                    iconOnly
                  >
                    <KeyboardArrowRight />
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold', textAlign: 'center' }}>
          Asset Details
        </DialogTitle>
        <DialogContent sx={{ padding: 3 }}>
          <SoftTypography variant="h6" sx={{ marginBottom: 2 }}>
            {selectedAsset?.asset_name}
          </SoftTypography>
          <SoftBox display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
            <SoftTypography variant="body1" sx={{ marginRight: 1, fontWeight: 'medium' }}>
              Requested By:
            </SoftTypography>
            <Description description={selectedAsset?.emp_id} />
          </SoftBox>
          <SoftBox display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
            <SoftTypography variant="body1" sx={{ marginRight: 1, fontWeight: 'medium' }}>
              Description:
            </SoftTypography>
            <Description description={selectedAsset?.description} />
          </SoftBox>
          <SoftTypography variant="body1" sx={{ fontWeight: 'medium', marginTop: 2 }}>
            Current Allocation: <SoftTypography variant="body1" color="info" component="span">{selectedAsset?.allocation}</SoftTypography>
          </SoftTypography>
        </DialogContent>
        {selectedAsset?.emp_id === currentUser ? (
          <DialogActions sx={{ justifyContent: 'center', paddingBottom: 2 }}>
            <SoftTypography variant="body1" color="error">
              Kindly contact administration for approval
            </SoftTypography>
          </DialogActions>
        ) : (
          <DialogActions sx={{ justifyContent: 'space-around', paddingBottom: 2 }}>
            {tabValue === 0 ? (
              <>
                <SoftButton variant="gradient" color="success" onClick={() => handleStatusUpdate("Allocated")}>
                  Approve
                </SoftButton>
                <SoftButton variant="gradient" color="error" onClick={() => handleStatusUpdate("Rejected")}>
                  Reject
                </SoftButton>
              </>
            ) : (
              <SoftButton variant="gradient" color="success" onClick={() => handleStatusUpdate("Returned")}>
                Approve Return
              </SoftButton>
            )}
          </DialogActions>
        )}
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
};

export default AssetsRequests;