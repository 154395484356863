import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { APIData, exitKeyWord, org } from "../authentication/APIData";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { AiOutlineCloseCircle } from "react-icons/ai";

const MIN_DESCRIPTION_LENGTH = 30;

export function ResignationForm({ onSubmissionSuccess }) {
  const user = APIData.sessiondetails;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");

  const validateDescription = () => {
    if (description.length < MIN_DESCRIPTION_LENGTH) {
      setError(`Description must be at least ${MIN_DESCRIPTION_LENGTH} characters long`);
      return false;
    }
    setError("");
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateDescription()) {
      return;
    }

    setIsSubmitting(true);
    setError("");

    const formBody = {
      id: 0,
      empEmail: user.email,
      empUserName: user.userName,
      description,
      status: "Resignation letter",
      approvedBy: "",
      type: exitKeyWord,
      createdTime: "",
      updatedTime: "",
      org: org,
    };

    try {
      const url = `${APIData.api}employee/exit`;
      const response = await axios.post(url, formBody, { headers: APIData.headers });
      console.log('Submission response:', response.data);
      
      toast.success("Resignation submitted successfully");
      
      if (onSubmissionSuccess) {
        await onSubmissionSuccess();
      }
    } catch (error) {
      console.error("Submit error:", error);
      toast.error(error.response?.data?.message || "Unable to submit resignation. Please try again.");
      setError("Submission failed. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card>
      <SoftBox p={3} mb={1} display="flex" alignItems="center">
        <Link to="/jobEditButtons">
          <AiOutlineCloseCircle size={24} />
        </Link>
        <SoftTypography 
          variant="h5" 
          fontWeight="medium" 
          style={{ flex: 1, textAlign: 'center' }}
        >
          Submit Resignation Form
        </SoftTypography>
      </SoftBox>

      <SoftBox pt={2} pb={3} px={3}>
        <SoftBox component="form" role="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" fontWeight="medium" mb={1}>
                  Username
                </SoftTypography>
                <SoftInput
                  size="small"
                  value={user.userName}
                  disabled
                  fullWidth
                />
              </SoftBox>
            </Grid>

            <Grid item xs={12} md={6}>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" fontWeight="medium" mb={1}>
                  Email
                </SoftTypography>
                <SoftInput
                  size="small"
                  value={user.email}
                  disabled
                  fullWidth
                />
              </SoftBox>
            </Grid>

            <Grid item xs={12}>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" fontWeight="medium" mb={1}>
                  Resignation Details
                </SoftTypography>
                <SoftInput
                  multiline
                  rows={5}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    if (error) validateDescription();
                  }}
                  error={!!error}
                  fullWidth
                />
                <SoftTypography 
                  variant="caption" 
                  color={error ? "error" : "dark"}
                >
                  {error || `${description.length}/${MIN_DESCRIPTION_LENGTH} characters minimum`}
                </SoftTypography>
              </SoftBox>
            </Grid>

            <Grid item xs={12}>
              <SoftBox mt={4} mb={1}>
                <SoftButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit Resignation"}
                </SoftButton>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Card>
  );
}

ResignationForm.propTypes = {
  onSubmissionSuccess: PropTypes.func,
};

export default ResignationForm;