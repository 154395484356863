// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import Card from "@mui/material/Card";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftAvatar from "components/SoftAvatar";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";
// import SoftButton from 'components/SoftButton';
// import { Tooltip } from '@mui/material';
// import { useNavigate } from "react-router-dom";
// import { APIData } from 'authentication/APIData';
// import { org } from 'authentication/APIData';


// const Name = ({ name }) => (
//   <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
//     <SoftBox mr={2}>
//       <SoftAvatar
//         src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
//         alt={name}
//         size="sm"
//         variant="rounded"
//       />
//     </SoftBox>
//     <SoftBox display="flex" flexDirection="column">
//       <SoftTypography variant="button" fontWeight="medium">
//         {name}
//       </SoftTypography>
//     </SoftBox>
//   </SoftBox>
// );



// Name.propTypes = {
//   name: PropTypes.string.isRequired,
// };

// const Organization = ({ orgName }) => (
//   <SoftBox display="flex" flexDirection="column">

//     <SoftTypography variant="caption" color="secondary">
//       {orgName}
//     </SoftTypography>
//   </SoftBox>
// );

// Organization.propTypes = {
//   orgName: PropTypes.string.isRequired,
// };

// const OrgPolicies = () => {
//   const [policyDataList, setPolicyData] = useState([]);
//   const [dialogOpen, setDialogOpen] = useState(false);
//   const navigate = useNavigate();

//   const handleNewClick = () => {
//     navigate("/add-new-policy");
//   };


//   const handleOpenDialog = () => {
//     setDialogOpen(true);
//   }
//   const handleCloseDialog = () => {
//     setDialogOpen(false);
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       console.log("PolicyData");
//       let url = `${APIData.api}policies/`;
//       url += `?org=${org}`;
//       const response = await fetch(url, { headers: APIData.headers });
//       const data = await response.json();
//       setPolicyData(data);
//     };
//     fetchData();
//   }, [status]);

//   const columns = [
//     { name: "Name", align: "left" },
//     { name: "Organization", align: "left" },
//     { name: "Policy Type", align: "center" },
//     { name: "Audience", align: "center" },
//     { name: "Policy Date", align: "center" },
//     { name: "Action", align: "center" },
//   ];

//   const rows = policyDataList.map((policy) => ({
//     Name: <Name name={policy.policy_name} />,
//     Organization: <Organization orgName={policy.org} />,
//     "Policy Type": (
//       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//         {policy.policy_type}
//       </SoftTypography>
//     ),
//     Audience: (
//       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//         {policy.policy_audience}
//       </SoftTypography>
//     ),
//     "Policy Date": (
//       <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//         {policy.policy_date}
//       </SoftTypography>
//     ),
//     Action: (
//       <Link to='/policy-data' state={policy}> view </Link>
//     ),
//   }));

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <SoftBox mb={0.5} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
//         <SoftBox style={{ display: 'flex', alignItems: 'center' }}>
//           <Tooltip title="Add new organization policy" arrow>
//             <SoftButton variant="gradient" color={"info"} sx={{ ml: 2 }} onClick={handleNewClick}>+ Add Policy</SoftButton>
//           </Tooltip>
//         </SoftBox>
//       </SoftBox>
//       <SoftBox py={3}>
//         <SoftBox mb={3}>
//           <Card>
//             <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
//               <SoftTypography variant="h6">Org Policies</SoftTypography>
//             </SoftBox>
//             <SoftBox
//               sx={{
//                 "& .MuiTableRow-root:not(:last-child)": {
//                   "& td": {
//                     borderBottom: ({ borders: { borderWidth, borderColor } }) =>
//                       `${borderWidth[1]} solid ${borderColor}`,
//                   },
//                 },
//               }}
//             >
//               <Table columns={columns} rows={rows} />
//             </SoftBox>
//           </Card>
//         </SoftBox>
//       </SoftBox>
//       <Footer />
//     </DashboardLayout>
//   );
// };

// export default OrgPolicies;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from "@mui/material/Card";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import SoftButton from 'components/SoftButton';
import SoftInput from "components/SoftInput";
import { Tooltip } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { AiOutlinePlusCircle } from "react-icons/ai";

const Name = ({ name }) => (
  <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
    <SoftBox mr={2}>
      <SoftAvatar
        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
        alt={name}
        size="sm"
        variant="rounded"
      />
    </SoftBox>
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="button" fontWeight="medium">
        {name}
      </SoftTypography>
    </SoftBox>
  </SoftBox>
);

Name.propTypes = {
  name: PropTypes.string.isRequired,
};

const Organization = ({ orgName }) => (
  <SoftBox display="flex" flexDirection="column">
    <SoftTypography variant="caption" color="secondary">
      {orgName}
    </SoftTypography>
  </SoftBox>
);

Organization.propTypes = {
  orgName: PropTypes.string.isRequired,
};

const OrgPolicies = () => {
  const [policyDataList, setPolicyData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  
  // New state variables for search and pagination
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleNewClick = () => {
    navigate("/add-new-policy");
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  }
  
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      console.log("PolicyData");
      let url = `${APIData.api}policies/`;
      url += `?org=${org}`;
      const response = await fetch(url, { headers: APIData.headers });
      const data = await response.json();
      setPolicyData(data);
    };
    fetchData();
  }, []);

  // Filter policies based on search term
  const filteredPolicies = policyDataList.filter(policy =>
    policy.policy_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    policy.org.toLowerCase().includes(searchTerm.toLowerCase()) ||
    policy.policy_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
    policy.policy_audience.toLowerCase().includes(searchTerm.toLowerCase()) ||
    policy.policy_date.includes(searchTerm)
  );

  // Sort policies
  const sortedPolicies = [...filteredPolicies].sort((a, b) => {
    if (!sortField) return 0;
    const aValue = a[sortField];
    const bValue = b[sortField];
    return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : (aValue < bValue ? 1 : -1);
  });

  // Paginate policies
  const paginatedPolicies = sortedPolicies.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );

  const columns = [
    { name: "Name", align: "left" },
    { name: "Organization", align: "left" },
    { name: "Policy Type", align: "center" },
    { name: "Audience", align: "center" },
    { name: "Policy Date", align: "center" },
    { name: "Action", align: "center" },
  ];

  const rows = paginatedPolicies.map((policy) => ({
    Name: <Name name={policy.policy_name} />,
    Organization: <Organization orgName={policy.org} />,
    "Policy Type": (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {policy.policy_type}
      </SoftTypography>
    ),
    Audience: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {policy.policy_audience}
      </SoftTypography>
    ),
    "Policy Date": (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {policy.policy_date}
      </SoftTypography>
    ),
    Action: (
      <Link to='/policy-data' state={policy}> view </Link>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Org Policies</SoftTypography>
              <SoftBox display="flex" alignItems="center" gap={2}>
                <SoftBox >
                  <SoftInput
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    size="small"
                  />
                </SoftBox>
                  <SoftButton variant="gradient" color={"info"} sx={{ ml: 2 }} onClick={handleNewClick} startIcon={<AiOutlinePlusCircle />}>
                  Add Policy
                  </SoftButton>
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rows} />
            </SoftBox>
            <SoftBox
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              p={3}
            >
              <SoftBox display="flex" gap={1} alignItems="center">
                <SoftTypography variant="caption" color="secondary">
                  {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, sortedPolicies.length)} of {sortedPolicies.length}
                </SoftTypography>
                <SoftButton
                  variant="text"
                  color="info"
                  onClick={() => setPage(prev => Math.max(0, prev - 1))}
                  disabled={page === 0}
                  iconOnly
                >
                  <KeyboardArrowLeft />
                </SoftButton>
                <SoftButton
                  variant="text"
                  color="info"
                  onClick={() => setPage(prev => Math.min(Math.ceil(sortedPolicies.length / itemsPerPage) - 1, prev + 1))}
                  disabled={page >= Math.ceil(sortedPolicies.length / itemsPerPage) - 1}
                  iconOnly
                >
                  <KeyboardArrowRight />
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
};

export default OrgPolicies;