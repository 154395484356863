import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { APIData } from "authentication/APIData";
import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { AiOutlineDownload, AiFillDelete, AiOutlinePlusCircle } from "react-icons/ai";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftAvatar from "components/SoftAvatar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Container from "@mui/material/Container";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { org } from "authentication/APIData";
import Swal from 'sweetalert2';

const DocumentsDataTable = () => {
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [tasks, setTasks] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [permission, setPermission] = useState(false);
  const [userDepartment, setUserDepartment] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
    if (sessionDetails && sessionDetails.user) {
      setCurrentUser(sessionDetails.user);
      setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
    }
    const accessLevel = localStorage.getItem('Access Level');
    setPermission(accessLevel);
    const Department = localStorage.getItem('Depart Details');
    setUserDepartment(Department);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${APIData.api}file?org=${org}`, {
        headers: {
          ...APIData.headers,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.status === 200) {
        setTasks(response.data || []);
        setLoading(false);
      } else {
        toast.error(response.data.errorDesc || "Failed to fetch documents");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error occurred while fetching documents.");
      setLoading(false);
    }
  };

  const handleSort = (field) => {
    let direction = "asc";
    if (sortField === field && sortDirection === "asc") {
      direction = "desc";
    }
    setSortField(field);
    setSortDirection(direction);
  };

  const handleDownload = (data) => {
    const url = APIData.api + 'file/' + data.id;
    window.location.href = url;
  };

  const handleDelete = async (data) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });
  
    if (result.isConfirmed) {
      try {
        const url = APIData.api + 'file/' + data.id;
        const response = await axios.delete(url, { headers: APIData.headers });
  
        if (response.data.status.toLowerCase() === "success") {
          toast.success(response.data.description);
          
          setTasks(prevTasks => prevTasks.filter(t => t.id !== data.id));
  
          Swal.fire({
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            icon: 'success',
            confirmButtonText: 'OK',
          });
        } else {
          toast.error(response.data.errorDesc);
        }
      } catch (error) {
        toast.error("Error occurred while deleting file.");
      }
    } else {
      toast.info("Deletion cancelled.");
    }
  };

  const filteredTasks = (tasks || []).filter(task =>
    task.fileName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    task.uploaded_by.toLowerCase().includes(searchTerm.toLowerCase()) ||
    task.file_size.toString().includes(searchTerm) ||
    new Date(task.uploaded_date).toLocaleDateString().includes(searchTerm)
  );

  const sortedTasks = [...filteredTasks].sort((a, b) => {
    if (!sortField) return 0;
    const aValue = a[sortField];
    const bValue = b[sortField];
    return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : (aValue < bValue ? 1 : -1);
  });

  const paginatedTasks = sortedTasks.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );

  const columns = [
    { name: "File Name", align: "left" },
    { name: "File Size", align: "left" },
    { name: "Date of Upload", align: "left" },
    { name: "Uploaded By", align: "left" },
    { name: "Actions", align: "center" }
  ];

  const rows = paginatedTasks.map((task) => ({
    "File Name": (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar
            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(task.fileName)}&background=random`}
            alt={task.fileName}
            size="sm"
            variant="rounded"
          />
        </SoftBox>
        <SoftTypography variant="button" fontWeight="medium">
          {task.fileName}
        </SoftTypography>
      </SoftBox>
    ),
    "File Size": (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {(task.file_size / 1024).toFixed(2)} KB
      </SoftTypography>
    ),
    "Date of Upload": (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {new Date(task.uploaded_date).toLocaleDateString()}
      </SoftTypography>
    ),
    "Uploaded By": (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {task.uploaded_by}
      </SoftTypography>
    ),
    "Actions": (
      <SoftBox display="flex" gap={1} justifyContent="center">
        <SoftButton
          variant="text"
          color="info"
          onClick={() => handleDownload(task)}
          iconOnly
        >
          <AiOutlineDownload />
        </SoftButton>
        {(permission === 1111 || isSuperAdmin) && (
          <SoftButton
            variant="text"
            color="error"
            onClick={() => handleDelete(task)}
            iconOnly
          >
            <AiFillDelete />
          </SoftButton>
        )}
      </SoftBox>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Documents</SoftTypography>
              <SoftBox display="flex" alignItems="center" gap={2}>
                <SoftBox >
                  <SoftInput
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    size="small"
                  />
                </SoftBox>
                {(permission==="1100" ||permission==="1110") || (permission==="1111" || isSuperAdmin) ? <Link to="/upload-document" className="text-decoration-none">
                  <SoftButton variant="gradient" color="info" size="small" startIcon={<AiOutlinePlusCircle />}>
                    Upload
                  </SoftButton>
                </Link>:""}
              </SoftBox>
            </SoftBox>

            <SoftBox sx={{ overflow: 'auto' }}>
              {loading ? (
                <SoftBox display="flex" justifyContent="center" p={3}>
                  <CircularProgress />
                </SoftBox>
              ) : (
                <>
                  <Table columns={columns} rows={rows} />
                  <SoftBox
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    p={3}
                  >
                    <SoftBox display="flex" gap={1} alignItems="center">
                      <SoftTypography variant="caption" color="secondary">
                        {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, sortedTasks.length)} of {sortedTasks.length}
                      </SoftTypography>
                      <SoftButton
                        variant="text"
                        color="info"
                        onClick={() => setPage(prev => Math.max(0, prev - 1))}
                        disabled={page === 0}
                        iconOnly
                      >
                        <KeyboardArrowLeft />
                      </SoftButton>
                      <SoftButton
                        variant="text"
                        color="info"
                        onClick={() => setPage(prev => Math.min(Math.ceil(sortedTasks.length / itemsPerPage) - 1, prev + 1))}
                        disabled={page >= Math.ceil(sortedTasks.length / itemsPerPage) - 1}
                        iconOnly
                      >
                        <KeyboardArrowRight />
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </>
              )}
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
};

DocumentsDataTable.propTypes = {
  documents: PropTypes.array,
};

export default DocumentsDataTable;