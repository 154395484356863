import React, { useEffect, useState } from 'react';
import { APIData, org } from 'authentication/APIData';
import axios from 'axios';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

import { Container, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import ProfileInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard';
import Footer from 'examples/Footer';

const Members = () => {
    const [data, setData] = useState([]);
    const [statusFilter, setStatusFilter] = useState("ALL");

    const handleStatusChange = (event, newStatus) => {
        if (newStatus) {
            setStatusFilter(newStatus);
        }
    };

    const fetchData = (filter) => {
        const url = filter === 'ALL' 
            ? `employee/details?org=${org}` 
            : `employee/empdetailsByDepartment?department=${filter}&org=${org}`;
        axios
            .get(APIData.api + url, { headers: APIData.headers })
            .then((resp) => {
                setData(resp.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        fetchData(statusFilter);
    }, [statusFilter]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={2} px={2}>
                <SoftBox mb={0.5} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <SoftTypography variant="h5">Members</SoftTypography>
                </SoftBox>
            </SoftBox>
            <Container sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                <ToggleButtonGroup value={statusFilter} exclusive onChange={handleStatusChange}>
                    <ToggleButton value="ALL" sx={{ border: '1px solid #007a99' }}>
                        ALL
                    </ToggleButton>
                    <ToggleButton value="HR" sx={{ border: '1px solid #007a99' }}>
                        HR
                    </ToggleButton>
                    <ToggleButton value="TECHNICAL" sx={{ border: '1px solid #007a99' }}>
                        TECHNICAL
                    </ToggleButton>
                    <ToggleButton value="FINANCE" sx={{ border: '1px solid #007a99' }}>
                        FINANCE
                    </ToggleButton>
                    <ToggleButton value="SALES" sx={{ border: '1px solid #007a99' }}>
                        SALES
                    </ToggleButton>
                    <ToggleButton value="ACADEMY" sx={{ border: '1px solid #007a99' }}>
                        ACADEMY
                    </ToggleButton>
                </ToggleButtonGroup>
            </Container>
            <SoftBox mt={5} mb={3}>
                {data.length === 0 ? (
                    <Typography variant="h6" align="center">
                        No members found for this department. Please select another department or &quot;ALL&quot; to view all members.
                    </Typography>
                ) : (
                    <Grid container spacing={3}>
                        {data.map((employee) => (
                            <Grid item xs={12} md={6} xl={4} key={employee.id}>
                                <ProfileInfoCard
                                    info={{
                                        fullName: `${employee.name}`,
                                        mobile: `${employee.phone_number}`,
                                        email: `${employee.email}`,
                                        location: `${employee.address}`,
                                        DOB: `${employee.dob}`,
                                        joinedDate: `${employee.year_of_appointment}`,
                                        Qualification: `${employee.qualification}`,
                                        gender: `${employee.gender}`,
                                    }}
                                    action={{}}
                                    showAction={false}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </SoftBox>
            <Footer/>
        </DashboardLayout>
    );
};

export default Members;
