import PropTypes from "prop-types";
import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SoftBox from "components/SoftBox";
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";
import { useSoftUIController } from "context";
import { NavLink } from "react-router-dom";


function SidenavCollapse({ color, icon, name, children, active, noCollapse, route, ...rest }) {
  const [controller] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const [isOpen, setIsOpen] = useState(false);

  const hasChildren = children && children.length > 0;

  const handleClick = () => {
    if (hasChildren) {
      setIsOpen(!isOpen);
    }
  };

  const CollapseContent = () => (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ListItemIcon
          sx={(theme) => collapseIconBox(theme, { active, transparentSidenav, color })}
        >
          {typeof icon === "string" ? (
            <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
          ) : (
            icon
          )}
        </ListItemIcon>

        <ListItemText
          primary={name}
          sx={(theme) => collapseText(theme, { miniSidenav, transparentSidenav, active })}
        />
      </div>
      
      {hasChildren && (
        <Icon sx={{ ml: 1 }}>
          {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </Icon>
      )}
    </div>
  );

  return (
    <>
      <ListItem component="li">
        <SoftBox 
          {...rest} 
          sx={(theme) => ({
            ...collapseItem(theme, { active, transparentSidenav }),
            cursor: 'pointer',
            width: '100%'
          })}
        >
          {route ? (
            <NavLink 
              to={route}
              style={{ 
                textDecoration: 'none', 
                color: 'inherit',
                width: '100%'
              }}
              onClick={handleClick}
            >
              <CollapseContent />
            </NavLink>
          ) : (
            <div onClick={handleClick} style={{ width: '100%' }}>
              <CollapseContent />
            </div>
          )}
        </SoftBox>
      </ListItem>
      
      {hasChildren && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <SoftBox pl={3}>
            {children}
          </SoftBox>
        </Collapse>
      )}
    </>
  );
}

SidenavCollapse.defaultProps = {
  color: "info",
  active: false,
  noCollapse: false,
  children: null,
  route: null,
};

SidenavCollapse.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  route: PropTypes.string,
};

export default SidenavCollapse;