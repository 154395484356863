import React, { useState } from 'react';
import { Grid, CircularProgress, Backdrop } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Card } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { Icon } from 'react-icons-kit';
import * as AiIcons from "react-icons/ai";
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import { eye } from 'react-icons-kit/feather/eye';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import Swal from 'sweetalert2';

export const CustomerOnboard = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const name = JSON.parse(localStorage.getItem("sessiondetails"));
  const username = name.userName;

  const validationRules = {
    email: (value) => {
      if (!value) return 'Email is required';
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) return 'Invalid email format';
      return '';
    },
    phone: (value) => {
      if (!value.trim()) return "Phone number is required";
      if (!/^\d{10}$/.test(value)) return "Phone number must be 10 digits";
      if (parseInt(value[0]) < 6) return "Phone number must start with 6 or greater";
      return '';
    },
    password: (value) => {
      if (!value) return 'Password is required';
      if (value.length < 8) return 'Password must be at least 8 characters';
      if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/.test(value)) {
        return 'Password must contain at least one uppercase letter, one lowercase letter, and one number';
      }
      return '';
    },
    warrantyEndDate: (value, formData) => {
      if (!value) return 'Warranty end date is required';
      if (formData.warrantyStartDate && value < formData.warrantyStartDate) {
        return 'End date must be after start date';
      }
      return '';
    },
    default: (value) => !value ? 'This field is required' : ''
  };

  const getCurrentDateTimeString = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const date = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${date}T${hours}:${minutes}`;
  };

  const [formData, setFormData] = useState({
    customerId: '',
    email: '',
    password: '',
    phone: '',
    productName: '',
    createdBy: username,
    createdDate: getCurrentDateTimeString(),
    companyName: '',
    warrantyStartDate: '',
    warrantyEndDate: '',
    org: org
  });

  const validateField = (name, value) => {
    const validationRule = validationRules[name] || validationRules.default;
    return validationRule(value, formData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    // Real-time validation
    const fieldError = validateField(name, value);
    setErrors(prev => ({
      ...prev,
      [name]: fieldError
    }));
  };

  const validateAllFields = () => {
    const newErrors = {};
    Object.keys(formData).forEach(field => {
      const error = validateField(field, formData[field]);
      if (error) {
        newErrors[field] = error;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateAllFields()) {
      Swal.fire({
        icon: 'warning',
        title: 'Incomplete Fields',
        text: 'Please fill in all required fields correctly.',
        confirmButtonText: 'OK'
    });      setLoading(false);
      return;
    }

    try {
      const url = APIData.api + 'customer-onboard';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          ...APIData.headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to submit form data');
      }
      Swal.fire({
        icon: "success",
        title: "Customer created successfully",
        showConfirmButton: false,
        timer: 1500
    });
      navigate('/customer-support');
    } catch (error) {
      console.error( error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response ,
    });
    } finally {
      setLoading(false);
    }
  };

  const renderInputField = (label, name, type = "text", placeholder, caption, inputProps = {}) => (
    <SoftBox mb={2}>
      <SoftTypography variant="h6">{label} *</SoftTypography>
      <SoftInput
        type={type === "password" ? (showPassword ? 'text' : 'password') : type}
        placeholder={placeholder}
        name={name}
        value={formData[name]}
        onChange={handleChange}
        error={!!errors[name]}
        required
        {...inputProps}
        {...(type === "password" && {
          icon: {
            component: <Icon
              icon={showPassword ? eyeOff : eye}
              onClick={() => setShowPassword(!showPassword)}
              style={{ cursor: 'pointer' }}
            />,
            direction: "right",
          }
        })}
      />
      {errors[name] && (
        <SoftTypography variant="caption" color="error">
          {errors[name]}
        </SoftTypography>
      )}
      {caption && (
        <SoftTypography variant="caption" color="text">
          {caption}
        </SoftTypography>
      )}
    </SoftBox>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
        <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item xs={12} sm={8}>
          <Card sx={{ height: 'auto' }}>
            <SoftBox p={3} mb={1} display="flex" alignItems="center">
              <Link to="/customer-support">
                <AiIcons.AiOutlineCloseCircle />
              </Link>
              <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                Customer Details
              </SoftTypography>
            </SoftBox>

            <SoftBox pt={1} pb={3} px={3}>
              <SoftBox component="form" onSubmit={handleSubmit}>
                {renderInputField("Company Name", "companyName", "text", "Company Name")}
                {renderInputField("Email", "email", "email", "Email", "Credentials will be sent to this mail")}
                {renderInputField("Phone Number", "phone", "text", "Phone Number", null, {
                  onKeyPress: (e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  },
                  maxLength: 10
                })}
                {renderInputField("Product Name", "productName", "text", "Product Name")}
                {renderInputField("Software Name", "software", "text", "Software Name")}
                {renderInputField("Customer ID", "customerId", "text", "Customer ID", "ID for customer to login for Support")}
                {renderInputField("Password", "password", "password", "Password", "Password for login")}
                {renderInputField("Warranty Start Date", "warrantyStartDate", "date")}
                {renderInputField("Warranty End Date", "warrantyEndDate", "date")}

                <SoftBox mt={4} mb={1}>
                  <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                    Submit
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default CustomerOnboard;