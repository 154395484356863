import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { APIData, org } from '../authentication/APIData';
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import breakpoints from "assets/theme/base/breakpoints";
import SoftButton from 'components/SoftButton';
import { Link } from 'react-router-dom';
import SoftInput from 'components/SoftInput';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const Name = ({ name, email }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
            <SoftAvatar
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                alt={name}
                size="sm"
                variant="rounded"
            />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
                {name}
            </SoftTypography>
            <SoftTypography variant="caption" color="secondary">
                {email}
            </SoftTypography>
        </SoftBox>
    </SoftBox>
);

Name.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
};

const Organization = ({ salesType, orgName }) => (
    <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="caption" fontWeight="medium" color="text">
            {salesType}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
            {orgName}
        </SoftTypography>
    </SoftBox>
);

Organization.propTypes = {
    salesType: PropTypes.string.isRequired,
    orgName: PropTypes.string.isRequired,
};

const ExpensesDetails = () => {
    const [expensData, setExpensData] = useState([]);
    const [status, setStatus] = useState("All");
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [userDepartment, setUserDepartment] = useState("");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);


    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            console.log(sessionDetails.user);
            
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(accessLevel);
        const Department = localStorage.getItem('Depart Details');
        setUserDepartment(Department);
    }, []);

    useEffect(() => {
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }
        window.addEventListener("resize", handleTabsOrientation);
        handleTabsOrientation();
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
        setPage(0);
    };

    useEffect(() => {
        const fetchData = async () => {
            let department = "";

            if (isSuperAdmin || userDepartment === "FINANCE") {
                switch (tabValue) {
                    case 1:
                        department = "TECHNICAL";
                        break;
                    case 2:
                        department = "HR";
                        break;
                    case 3:
                        department = "SALES";
                        break;
                    case 4:
                        department = "FINANCE";
                        break;
                    case 5:
                        department = "ACADEMICS";
                        break;
                    case 0:
                    default:
                        department = "ALL";
                }
            } else {
                department = userDepartment;
            }

            const baseUrl = `${APIData.api}`;
            const url = department === 'ALL'
                ? `${baseUrl}expenses?org=${org}`
                : `${baseUrl}expenses/details?org=${org}&department=${department}`;

            try {
                const response = await fetch(url, { headers: APIData.headers });
                const data = await response.json();
                setExpensData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [tabValue, isSuperAdmin, userDepartment]);

    const filteredExpenses = expensData.filter(expense =>
        expense.created_by.toLowerCase().includes(searchTerm.toLowerCase()) ||
        expense.exp_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        expense.month.toLowerCase().includes(searchTerm.toLowerCase()) ||
        expense.cost.toString().includes(searchTerm) ||
        expense.exp_date.includes(searchTerm)
    );

    const paginatedExpenses = filteredExpenses.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const columns = [
        { name: "User", align: "left" },
        { name: "Expense_Type", align: "left" },
        { name: "Month", align: "left" },
        { name: "Cost", align: "center" },
        { name: "Date", align: "center" },
        { name: "Amount", align: "left" },
        { name: "Status", align: "left" },
        ...(permission === "1100" || permission === "1000") ? [] : [{ name: "Action", align: "center" }],
    ];

    const rows = paginatedExpenses.map((expens) => ({
        User: <Name name={expens.created_by} email={expens.customerEmail} />,
        Expense_Type: <Organization salesType={expens.exp_type} />,
        Month: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {expens.month}
            </SoftTypography>
        ),
        Cost: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {expens.cost}
            </SoftTypography>
        ),
        Date: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {expens.exp_date}
            </SoftTypography>
        ),
        Amount: (
            <SoftBadge
                variant="gradient"
                badgeContent={expens.approval.toLowerCase()}
                color={expens.approval === "PAID" ? "success" : "secondary"}
                size="xs"
                container
            />
        ),
        Status: (
            <SoftBadge
                variant="gradient"
                badgeContent={expens.status.toLowerCase()}
                color={expens.status === "APPROVED" ? "success" : "secondary"}
                size="xs"
                container
            />
        ),
        Action: (
            <>
                {!(permission === "1100" || permission === "1000") && currentUser !== expens.created_by && (
                    <Link to={`/edit-expense/${expens.id}`}>
                        <SoftTypography
                            component="a"
                            variant="button"
                            color="secondary"
                            fontWeight="medium"
                        >
                            Edit
                        </SoftTypography>
                    </Link>
                )}
            </>
        ),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                <Card>
                        {isSuperAdmin || userDepartment === "FINANCE" ? (
                            <Grid container spacing={3} alignItems="center">
                                <Grid item xs={12} md={6} lg={16} sx={{ ml: "0" }}>
                                    <AppBar position="static">
                                        <Tabs
                                            orientation={tabsOrientation}
                                            value={tabValue}
                                            onChange={handleSetTabValue}
                                            sx={{ background: "transparent" }}
                                        >
                                            <Tab
                                                label="ALL"
                                                sx={{
                                                    color: tabValue === 0 ? "info.main" : "text.secondary",
                                                    fontWeight: tabValue === 0 ? "bold" : "normal",
                                                    borderBottom: tabValue === 0 ? "2px solid" : "none",
                                                    borderColor: "info.main",
                                                }}
                                            />
                                            <Tab
                                                label="Technical"
                                                sx={{
                                                    color: tabValue === 1 ? "info.main" : "text.secondary",
                                                    fontWeight: tabValue === 1 ? "bold" : "normal",
                                                    borderBottom: tabValue === 1 ? "2px solid" : "none",
                                                    borderColor: "info.main",
                                                }}
                                            />
                                            <Tab
                                                label="HR"
                                                sx={{
                                                    color: tabValue === 2 ? "info.main" : "text.secondary",
                                                    fontWeight: tabValue === 2 ? "bold" : "normal",
                                                    borderBottom: tabValue === 2 ? "2px solid" : "none",
                                                    borderColor: "info.main",
                                                }}
                                            />
                                            <Tab
                                                label="Sales"
                                                sx={{
                                                    color: tabValue === 3 ? "info.main" : "text.secondary",
                                                    fontWeight: tabValue === 3 ? "bold" : "normal",
                                                    borderBottom: tabValue === 3 ? "2px solid" : "none",
                                                    borderColor: "info.main",
                                                }}
                                            />
                                            <Tab
                                                label="Finance"
                                                sx={{
                                                    color: tabValue === 4 ? "info.main" : "text.secondary",
                                                    fontWeight: tabValue === 4 ? "bold" : "normal",
                                                    borderBottom: tabValue === 4 ? "2px solid" : "none",
                                                    borderColor: "info.main",
                                                }}
                                            />
                                            <Tab
                                                label="Academics"
                                                sx={{
                                                    color: tabValue === 5 ? "info.main" : "text.secondary",
                                                    fontWeight: tabValue === 5 ? "bold" : "normal",
                                                    borderBottom: tabValue === 5 ? "2px solid" : "none",
                                                    borderColor: "info.main",
                                                }}
                                            />
                                        </Tabs>
                                    </AppBar>
                                </Grid>
                            </Grid>
                        ) : null}
                    </Card>
                    <SoftBox mt={2}>
                        <Card>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                <SoftTypography variant="h6">Expenses Details</SoftTypography>
                                <SoftBox display="flex" alignItems="center" gap={2}>
                                    <SoftBox>
                                        <SoftInput
                                            placeholder="Search..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            size="small"
                                        />
                                    </SoftBox>
                                    <Link to='/add-expenses'>
                                        <SoftButton variant="gradient" color="info" size="small">+ Add</SoftButton>
                                    </Link>
                                </SoftBox>
                            </SoftBox>
                            <SoftBox
                                sx={{
                                    "& .MuiTableRow-root:not(:last-child)": {
                                        "& td": {
                                            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                `${borderWidth[1]} solid ${borderColor}`,
                                        },
                                    },
                                }}
                            >
                                <Table columns={columns} rows={rows} />
                                <SoftBox
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    p={3}
                                >
                                    <SoftBox display="flex" gap={1} alignItems="center">
                                        <SoftTypography variant="caption" color="secondary">
                                            {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredExpenses.length)} of {filteredExpenses.length}
                                        </SoftTypography>
                                        <SoftButton
                                            variant="text"
                                            color="info"
                                            onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                            disabled={page === 0}
                                            iconOnly
                                        >
                                            <KeyboardArrowLeft />
                                        </SoftButton>
                                        <SoftButton
                                            variant="text"
                                            color="info"
                                            onClick={() => setPage(prev => Math.min(Math.ceil(filteredExpenses.length / itemsPerPage) - 1, prev + 1))}
                                            disabled={page >= Math.ceil(filteredExpenses.length / itemsPerPage) - 1}
                                            iconOnly
                                        >
                                            <KeyboardArrowRight />
                                        </SoftButton>
                                    </SoftBox>
                                </SoftBox>
                            </SoftBox>
                        </Card>
                    </SoftBox>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default ExpensesDetails;
