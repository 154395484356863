import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Card, CircularProgress, Backdrop } from '@mui/material';
import { Mail, User, Phone } from "lucide-react";
import axios from 'axios';
import { APIData } from 'authentication/APIData';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Swal from 'sweetalert2';
import * as AiIcons from "react-icons/ai";
import { Link } from 'react-router-dom';
import { org } from 'authentication/APIData';

const InternalJobApply = () => {
  const location = useLocation();
  const { jobId, Role, branch, designation, department } = location.state || {};

  const [formData, setFormData] = React.useState({
    email: '',
    name: '',
    phone: '',
    supervisor: ''
  });
  const navigate=useNavigate()
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    const sessionDetails = JSON.parse(localStorage.getItem('sessiondetails'));
    const fetchedDept = localStorage.getItem("Depart Details");

    if (sessionDetails) {
      let fetchedSupervisor = null;
      sessionDetails.privileges?.forEach(supervisor => {
        if (supervisor.dept === fetchedDept) {
          fetchedSupervisor = supervisor.supervisor;
        }
      });

      setFormData({
        email: sessionDetails.email || '',
        name: sessionDetails.user || '',
        phone: sessionDetails.phoneNumber || '',
        supervisor: fetchedSupervisor || ''
      });
    }
  }, []);

  const validateField = (name, value) => {
    const validations = {
      email: (val) => {
        if (!val.trim()) return "Email is required";
        if (!/\S+@\S+\.\S+/.test(val)) return "Invalid email format";
        return '';
      },
      name: (val) => !val.trim() ? "Name is required" : '',
      phone: (val) => {
        if (!val.trim()) return "Phone number is required";
        if (!/^\d{10}$/.test(val)) return "Phone number must be 10 digits";
        return '';
      }
    };

    return validations[name] ? validations[name](value) : '';
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach(field => {
      if (field !== 'supervisor') {
        const error = validateField(field, formData[field]);
        if (error) {
          newErrors[field] = error;
        }
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    setIsSubmitting(true);
    const today = new Date();
    const datetime = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();

    const jobApplicationData = {
      admin_id: APIData.admin,
      branch,
      course_id: '',
      designation,
      deptSupervisor: '',
      accept_status: 'PENDING',
      enrollmentSupervisor: '',
      new_enrolled_date: "",
      job_id: jobId,
      enrolled_date: today,
      enrollment_status: "APPLIED",
      prevDeptSupervisor: formData.supervisor,
      release_status: 'PENDING',
      enrollment_type: "EMPLOYEE",
      followup_datetime: datetime,
      office_email:formData.email,
      resume_url: "",
      org: org,
      role: jobId,
      dept: department,
      user_email: formData.email,
      user_name: formData.name,
      user_phone_number: formData.phone,
    };
 console.log(jobApplicationData);
 
    try {
      const response = await axios.post(
        `${APIData.api}enrollments/employee/internal-job-acceptance`,
        jobApplicationData,
        { headers: APIData.headers }
      );

      if (response.data.status.toString().toLowerCase() === "success") {
        Swal.fire({
          icon: "success",
          title: "Application Submitted",
          text: response.data.description,
          showConfirmButton: false,
          timer: 1500
        });
        navigate('/dashboard')
      } else {
        Swal.fire({
          icon: "error",
          title: "Submission Failed",
          text: response.data.errorDesc
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "70vh" }}>
        <Backdrop open={isSubmitting} style={{ zIndex: 9999, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item xs={12} sm={8}>
          <Card sx={{ height: 'auto' }}>
            <SoftBox p={3} mb={1} display="flex" alignItems="center">
              <Link to="/dashboard">
                <AiIcons.AiOutlineCloseCircle />
              </Link>
              <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                Apply for the Job
              </SoftTypography>
            </SoftBox>

            <SoftBox pt={1} pb={3} px={3}>
              <SoftBox component="form" onSubmit={handleSubmit}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Email *</SoftTypography>
                  <SoftInput
                    type="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                    error={!!errors.email}
                    icon={<Mail />}
                  />
                  {errors.email && (
                    <SoftTypography variant="caption" color="error">
                      {errors.email}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Name *</SoftTypography>
                  <SoftInput
                    placeholder="Name"
                    value={formData.name}
                    onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                    error={!!errors.name}
                    icon={<User />}
                  />
                  {errors.name && (
                    <SoftTypography variant="caption" color="error">
                      {errors.name}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Phone Number *</SoftTypography>
                  <SoftInput
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
                    error={!!errors.phone}
                    icon={<Phone />}
                    inputProps={{
                      maxLength: 10,
                      pattern: "[0-9]*",
                      inputMode: "numeric"
                    }}
                  />
                  {errors.phone && (
                    <SoftTypography variant="caption" color="error">
                      {errors.phone}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mt={4} mb={1}>
                  <SoftButton 
                    type="submit" 
                    variant="gradient" 
                    color="info" 
                    fullWidth
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit Application'}
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>

          <SoftBox mt={3} textAlign="center">
            <SoftTypography variant="body2" color="text">
              Thank you for your interest in our job opportunity. We will get back to you soon!
            </SoftTypography>
          </SoftBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default InternalJobApply;