import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import curved9 from "assets/images/curved-images/white-curved.jpeg";
import axios from "axios";
import { APIData, org } from 'authentication/APIData';
import { toast } from "react-toastify";
import Swal from 'sweetalert2';

function OrgSignIn() {
  const [rememberMe, setRememberMe] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpField, setShowOtpField] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "", otp: "" });
  const navigate = useNavigate();

  useEffect(() => {
    const handleSignOut = () => {
      localStorage.clear();
      navigate("/authentication/org-sign-in");
    };
    handleSignOut();
  }, []);

  const validateFields = () => {
    const newErrors = { email: "", password: "", otp: "" };
    let isValid = true;

    if (!email) {
      newErrors.email = "Email or Username is required"
      isValid = false;
    }

    if (!password) {
      newErrors.password = "Password is required.";
      isValid = false;
    }

    if (showOtpField && !otp) {
      newErrors.otp = "OTP is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleOtpVerification = async (event) => {
    event.preventDefault();
    if (validateFields()) {
      const formData = {
        "email_id": email.includes('@') ? email : "",
        "username": email.includes('@') ? "" : email,
        "org": org,
        "otp": otp,
        "otpExpirationTime": "",
        "password": password,
        "phone_number": "",
        "role": "",
        "status": "",
        "updated_by": "",
      };
      try {
        const response = await axios.post(APIData.api + "login-type/validate-otp", formData, {
          headers: APIData.headers,
        });
        if (response.data.status.toLowerCase() === "success") {
          localStorage.setItem("sessiondetails", JSON.stringify(response.data));
          navigate("/select-dept", { replace: true });
        } else {
          toast(response.data.errorDesc);
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: "OTP Verification Failed. Contact Administrator.",
          confirmButtonText: 'Okay'
        });
        console.error(error);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateFields()) {
      const formData = {
        "email_id": email.includes('@') ? email : "",
        "username": email.includes('@') ? "" : email,
        "org": org,
        "otp": "",
        "otpExpirationTime": "",
        "password": password,
        "phone_number": "",
        "role": "",
        "status": "",
        "updated_by": "",
      };
     console.log(formData);
     
      try {
        const response = await axios.post(APIData.api + "login-type/login", formData, {
          headers: APIData.headers,
        });
        // console.log(response.data.status);
        if (response.status === 201 && response.data.status === "Created") {
          setShowOtpField(true);
        }
        else {
          localStorage.setItem("sessiondetails", JSON.stringify(response.data));
          const userType = response.data.userType;
          if (userType === "STUDENT") {
            window.location.reload();
          } else {
            navigate("/select-dept", { replace: true });
          }
        }
      } catch (error) {
        console.log(error.status);
        if (error.status === 400) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: "Ensure you have entered right email & password or contact Administrator",
            confirmButtonText: 'Okay'
          });
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: error.response.data,
            confirmButtonText: 'Okay'
          });
        }
      }
    }
  };

  return (
    <CoverLayout
      title="Org Sign In"
      description="Enter your email or username and password to sign in"
      image={curved9}
    >
      <SoftBox component="form" role="form" onSubmit={showOtpField ? handleOtpVerification : handleSubmit}>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email or Username
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="text"
            placeholder="Email or Username"
            value={email}
            onChange={(e) => {
              validateFields();
              return setEmail(e.target.value);
            }}
            // error={!!errors.email}
            helperText={errors.email}
          />
          <SoftTypography style={{ color: 'red', fontSize: '12px' }}>
            {errors.email}
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => {
              validateFields();
              return setPassword(e.target.value);
            }}
            // error={!!errors.password}
            helperText={errors.password}
          />
          <SoftTypography style={{ color: 'red', fontSize: '12px' }}>
            {errors.password}
          </SoftTypography>
        </SoftBox>
        {showOtpField && (
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                OTP
              </SoftTypography>
            </SoftBox>
            <SoftInput
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => {
                validateFields();
                return setOtp(e.target.value);
              }}
              error={!!errors.otp}
              helperText={errors.otp}
            />
            <SoftTypography style={{ color: 'red', fontSize: '12px' }}>
              {errors.otp}
            </SoftTypography>
          </SoftBox>
        )}

        <SoftBox mt={4} mb={1}>
          <SoftButton type="submit" variant="gradient" color="info" fullWidth>
            {showOtpField ? "Verify OTP" : "Sign in"}
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            <SoftTypography
              component={Link}
              to="/getPassword"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Click Here!
            </SoftTypography>
            {" "}for Get Your Password
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default OrgSignIn;
