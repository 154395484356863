import React, { useState, useEffect } from 'react';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import { Card, Grid, FormControl, Tooltip, CircularProgress, Backdrop } from "@mui/material";
import { APIData, org } from 'authentication/APIData';
import axios from 'axios';
import { toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';
import * as AiIcons from "react-icons/ai";
import Swal from 'sweetalert2';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';

function UploadDocument() {
  const [courses, setCourses] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const url = `${APIData.api}courses/?org=${org}`;
      const response = await fetch(url, { headers: APIData.headers });
      const data = await response.json();
      setCourses(data.sort((a, b) => (a.course_id > b.course_id ? 1 : -1)));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching courses:", error);
      setLoading(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!selectedFile) {
      newErrors.selectedFile = "Please select a file";
    }

    if (!fileType) {
      newErrors.fileType = "Please select a file type";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const handleUpload = () => {
    if (!validateForm()) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Form',
        text: 'Please fill in all required fields.',
      });
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("course", fileType);
    formData.append("uploaded_by", sessiondetails.user);
    formData.append("org", org);

    axios
      .post(APIData.api + "file/", formData, { headers: APIData.headers })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Upload Successful",
            text: `${response.data.fileName} uploaded successfully`,
            showConfirmButton: false,
            timer: 1500
          });

          setTimeout(() => {
            navigate("/document-list");
          }, 1500);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Upload Failed',
          text: 'An error occurred while uploading the file.',
        });
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderFileDetails = () => {
    if (!selectedFile) return null;

    return (
      <SoftBox mt={2}>
        <SoftTypography variant="h6">File Details:</SoftTypography>
        <SoftTypography variant="body2">File Name: {selectedFile.name}</SoftTypography>
        <SoftTypography variant="body2">File Type: {selectedFile.type}</SoftTypography>
        <SoftTypography variant="body2">
          Last Modified: {new Date(selectedFile.lastModified).toDateString()}
        </SoftTypography>
      </SoftBox>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4" mt={3}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <Card className="w-full">
            <SoftBox p={3} mb={1} display="flex" alignItems="center">
              <Tooltip title="Close" arrow>
                <Link to="/document-list">
                  <AiIcons.AiOutlineCloseCircle />
                </Link>
              </Tooltip>
              <SoftTypography mb={2} variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                Upload Document
              </SoftTypography>
            </SoftBox>

            <SoftBox component="form" role="form" px={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SoftBox>
                    <SoftTypography variant="h6">Select File</SoftTypography>
                    <FormControl fullWidth>
                      <SoftInput
                        type="file"
                        onChange={handleFileChange}
                        size="small"
                      />
                    </FormControl>
                    {errors.selectedFile && (
                      <SoftTypography variant="caption" color="error">
                        {errors.selectedFile}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </Grid>

                <Grid item xs={12}>
                  <SoftBox>
                    <SoftTypography variant="h6">Course Type</SoftTypography>
                    <FormControl fullWidth>
                      <select
                        onChange={handleTypeChange}
                        value={fileType}
                        style={{ width: "100%", padding: "8px", fontSize: "14px" }}
                      >
                        <option value="" disabled>
                          Select An Option
                        </option>
                        {courses?.map((item, index) => (
                          <option key={index} value={item.course_id}>
                            {item.course_id}
                          </option>
                        ))}
                      </select>
                    </FormControl>

                    {errors.fileType && (
                      <SoftTypography variant="caption" color="error">
                        {errors.fileType}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </Grid>

                <Grid item xs={12}>
                  {renderFileDetails()}
                </Grid>

                <Grid item xs={12}>
                  <SoftBox display="flex" justifyContent="center" mt={4} mb={2}>
                    <SoftButton
                      onClick={handleUpload}
                      variant="gradient"
                      color="info"
                      fullWidth
                      disabled={loading}
                    >
                      {loading ? "Uploading..." : "Upload"}
                    </SoftButton>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default UploadDocument;