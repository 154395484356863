import React, { useState, useEffect } from 'react';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { v4 as uuidv4 } from 'uuid';
import SoftTypography from 'components/SoftTypography';
import { Grid } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { APIData, org } from 'authentication/APIData';
import SoftAvatar from 'components/SoftAvatar';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import * as AiIcons from "react-icons/ai";
import { toWords } from 'number-to-words';

const AddInvoice = () => {
  const locale = 'en-US';
  const currency = 'INR';
  const [selectedOption, setSelectedOption] = useState(null);
  const [showInvoiceFields, setShowInvoiceFields] = useState(false);
  const [showPartialInvoiceFields, setShowPartialInvoiceFields] = useState(false);
  const [showQuotationFields, setShowQuotationFields] = useState(false);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [emailError, setEmailError] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('PENDING');
  const navigate = useNavigate();
  const Name = ({ name, email }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar
          src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
          alt={name}
          size="sm"
          variant="rounded"
        />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
  Name.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };


  const [state, setState] = useState({
    taxRate: 0.00,
    cgstRate: "",
    sgstRate: "",
    paidAmount: "",
    parentInvoiceid: "",
    partialPaid1: "",
    partialPaid2: "",
    partialPaid3: "",
    lineItems: [
      {
        item: "",
        quantity: "",
        unitPrice: "",
        itemTotal: "",
        slno: "",
      },
    ],
    companyAddress: '',
    dueDate: "",
    invoiceDate: new Date().toISOString().split('T')[0],
  });

  const handleInvoiceChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLineItemChange = (elementIndex) => (event) => {
    const { name, value } = event.target;

    const newLineItems = state.lineItems.map((item, index) => {
      if (index === elementIndex) {
        const updatedItem = {
          ...item,
          [name]: value,
          itemTotal: (name === 'quantity' || name === 'unitPrice') ?
            (item.quantity * item.unitPrice) : item.itemTotal,
        };
        return updatedItem;
      }
      return item;
    });

    setState({ ...state, lineItems: newLineItems });
  };

  const handleAddLineItem = () => {
    setState({
      ...state,
      lineItems: [
        ...state.lineItems,
        {
          item: "",
          quantity: "",
          unitPrice: "",
          itemTotal: "",
          slno: uuidv4(),
        }
      ],
    });
  };

  const handleRemoveLineItem = (elementIndex) => () => {
    setState({
      ...state,
      lineItems: state.lineItems.filter((item, index) => index !== elementIndex),
    });
  };

  const handleInvTypeChange = (selectedOption) => {
    setSelectedOption(selectedOption);

    const value = selectedOption?.value || "";
    setState((prevState) => ({
      ...prevState,
      invType: value,
    }));

    setShowQuotationFields(value === 'QUOTATIONS' || value === 'PROFORMA');
    setShowPartialInvoiceFields(value === 'PARTIAL_INVOICE');
    setShowInvoiceFields(value === 'INVOICE');
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);

    if (value && !/\S+@\S+\.\S+/.test(value)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  };

  const handleNameChange = (event) => {
    const { value } = event.target;
    setName(value);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  const calcLineItemsTotal = () => {
    return state.lineItems.reduce((prev, cur) => prev + cur.quantity * cur.unitPrice, 0);
  };

  const calcCGSTAmount = () => {
    return calcLineItemsTotal() * (state.cgstRate / 100);
  };

  const calcSGSTAmount = () => {
    return calcLineItemsTotal() * (state.sgstRate / 100);
  };

  const calcTaxTotal = () => {
    return calcCGSTAmount() + calcSGSTAmount();
  };

  const calcGrandTotal = () => {
    return calcLineItemsTotal() + calcTaxTotal();
  };

  const handlePaymentStatusChange = (selectedOption) => {
    setPaymentStatus(selectedOption?.value || 'PENDING');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!state.companyAddress || !email || !name || !state.invoiceDate || !state.dueDate || !state.sgstRate || !state.cgstRate) {
      Swal.fire({
        icon: 'error',
        title: 'Message',
        text: 'Please fill all the fields.',
      });
      return;
    }

    if (selectedOption.value == "PARTIAL_INVOICE") {
      if (!state.parentInvoiceid || !state.partialPaid1 || !state.partialPaid2 || !state.partialPaid3 || !state.paidAmount) {
        Swal.fire({
          icon: 'error',
          title: 'Message',
          text: 'Please fill all the fields.',
        });
        return;
      }
    }
    
    if (selectedOption.value == "INVOICE") {
      if (!state.paidAmount) {
        Swal.fire({
          icon: 'error',
          title: 'Message',
          text: 'Please fill all the fields.',
        });
        return;
      }
    }

    const formData = {
      invoiceid: 0,
      parentInvoiceid: state.parentInvoiceid,
      custEmailId: email,
      date: state.invoiceDate,
      due_date: state.dueDate,
      toName: name,
      toAddress: state.companyAddress,
      amount: calcLineItemsTotal(),
      balance: (calcGrandTotal() - state.paidAmount),
      amountWords: toWords(calcGrandTotal() - state.paidAmount),
      total: calcGrandTotal(),
      subTotal: calcLineItemsTotal(),
      cgstPercentage: state.cgstRate,
      cgstAmount: calcCGSTAmount(),
      sgstPercentage: state.sgstRate,
      sgstAmount: calcSGSTAmount(),
      totalTax: calcTaxTotal(),
      paidAmount: state.paidAmount,
      partialPaid1: state.partialPaid1,
      partialPaid2: state.partialPaid2,
      partialPaid3: state.partialPaid3,
      org: org,
      invType: selectedOption.value,
      items: state.lineItems,
      paymentstatus: paymentStatus,
    };
    // console.log(formData);
    try {
      const url = APIData.api + `invoices/`;
      const response = await axios.post(url, formData, { headers: APIData.headers });

      console.log(response.data);
      navigate("/list-invoice");
      Swal.fire({
        icon: 'success',
        title: 'Form data submitted successfully',
        showConfirmButton: false,
        timer: 1500,
      });

      setState({
        taxRate: 0.00,
        cgstRate: 0.00,
        sgstRate: 0.00,
        lineItems: [
          {
            id: 'initial',
            name: '',
            description: '',
            quantity: 0,
            price: 0.00,
          },
        ],
        companyAddress: '',
        dueDate: "",
        invoiceDate: new Date().toISOString().split('T')[0],
      });

      setEmail('');
      setName('');
      setPaymentStatus('PENDING');

    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response?.message || 'An error occurred.',
      });
    }
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Link to="/list-invoice">
        <AiIcons.AiOutlineCloseCircle />
      </Link>
      <div style={styles.invoice}>
        <div style={styles.brand}>
          <img
            src="https://i.ibb.co/khsCrdF/YUgdhr-MGgkg-ASSABJAAEk-ACSAAJIAEkg-ASQABJAAkg-ACSABJIAEk-AASQAJIAAkg-ASSABJAAEk-ACSAAJIAEkg-ASQABJA.png"
            alt="Logo"
            style={styles.logo}
          />
        </div>
        <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
          <Grid item xs={12} sm={4}>
            <SoftTypography variant="h6">Select Invoice Type</SoftTypography>
            <CustomSelect
              options={[
                { value: "QUOTATIONS", label: "QUOTATIONS" },
                { value: "PROFORMA", label: "PROFORMA" },
                { value: "PARTIAL_INVOICE", label: "PARTIAL INVOICE" },
                { value: "INVOICE", label: "INVOICE" }
              ]}
              placeholder="Select Invoice Type"
              name="invType"
              onChange={handleInvTypeChange}
              value={selectedOption}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SoftTypography variant="h6">Customer Email ID</SoftTypography>
            <SoftInput
              variant="outlined"
              fullWidth
              type="email"
              size="small"
              value={email}
              onChange={handleEmailChange}
              error={!!emailError}
              required
            />
            {emailError && (
              <SoftTypography variant="caption" color="error">
                {emailError}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <SoftTypography variant="h6">Customer Name</SoftTypography>
            <SoftInput
              variant="outlined"
              fullWidth
              required
              size="small"
              value={name}
              onChange={handleNameChange}
            />
          </Grid>
        </Grid>

        <div style={styles.addresses}>
          <div style={styles.from}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <SoftTypography variant="h6">Address</SoftTypography>
                <SoftBox>
                  <SoftInput
                    type="text"
                    value={state.companyAddress}
                    placeholder="Enter Address"
                    name="companyAddress"
                    onChange={handleInvoiceChange}
                  />
                </SoftBox>
              </Grid>

              <Grid item xs={12} sm={6}>
                <SoftTypography marginBottom={1} variant="h6">Payment Status</SoftTypography>
                <CustomSelect
                  options={[
                    { value: 'PENDING', label: 'PENDING' },
                    { value: 'RECEIVED', label: 'RECEIVED' },
                  ]}
                  placeholder="Select Payment Status"
                  required
                  name="paymentstatus"
                  onChange={handlePaymentStatusChange}
                  value={{ value: paymentStatus, label: paymentStatus }}
                />
              </Grid>
            </Grid>
          </div>



          <div>
            <div style={styles.valueTable}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SoftTypography variant="body2">Invoice Date</SoftTypography>
                  <SoftInput
                    type="date"
                    name="invoiceDate"
                    value={state.invoiceDate}
                    onChange={handleInvoiceChange}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SoftTypography variant="body2">Due Date</SoftTypography>
                  <SoftInput
                    type="date"
                    name="dueDate"
                    value={state.dueDate}
                    onChange={handleInvoiceChange}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>

        <SoftTypography variant="h6">Invoice</SoftTypography>
        <div style={styles.lineItems}>
          {state.lineItems.map((item, index) => (
            <div key={item.id} style={styles.lineItem}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <SoftTypography variant="body2">Item Name</SoftTypography>
                  <SoftInput
                    name="item"
                    placeholder="Item Name"
                    value={item.item}
                    onChange={handleLineItemChange(index)}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <SoftTypography variant="body2">Item Description</SoftTypography>
                  <SoftInput
                    name="description"
                    placeholder="Item Description"
                    value={item.description}
                    onChange={handleLineItemChange(index)}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <SoftTypography variant="body2">Quantity</SoftTypography>
                  <SoftInput
                    name="quantity"
                    type="number"
                    placeholder="Quantity"
                    value={item.quantity}
                    onChange={handleLineItemChange(index)}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <SoftTypography variant="body2">Price</SoftTypography>
                  <SoftInput
                    name="unitPrice"
                    type="number"
                    step="0.01"
                    placeholder="Price"
                    value={item.unitPrice}
                    onChange={handleLineItemChange(index)}
                    fullWidth
                    required
                  />
                </Grid>

                {index !== 0 && (
                  <Grid item xs={12} sm={1}>
                    <SoftBox mt={3} mb={1}>
                      <SoftButton variant="gradient" color="secondary" onClick={handleRemoveLineItem(index)}>
                        Remove
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                )}
              </Grid>
            </div>
          ))}
        </div>

        <SoftBox mt={2} mb={1} display="flex" justifyContent="center" alignItems="center">
          <SoftButton variant="gradient" color="secondary" onClick={handleAddLineItem}>
            Add Item
          </SoftButton>
        </SoftBox>

        <div style={styles.totalContainer}>
          {selectedOption != null && selectedOption.value == "PARTIAL_INVOICE" && <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <SoftTypography variant="body2">Parent Invoice ID</SoftTypography>
              <SoftInput
                name="parentInvoiceid"
                placeholder="Parent Invoice ID"
                value={state.parentInvoiceid}
                onChange={handleInvoiceChange}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <SoftTypography variant="body2">Partial Paid 1</SoftTypography>
              <SoftInput
                name="partialPaid1"
                type="number"
                placeholder="Partial Paid 1"
                step="1"
                value={state.partialPaid1}
                onChange={handleInvoiceChange}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <SoftTypography variant="body2">Partial Paid 2</SoftTypography>
              <SoftInput
                name="partialPaid2"
                type="number"
                placeholder="Partial Paid 2"
                step="1"
                value={state.partialPaid2}
                onChange={handleInvoiceChange}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <SoftTypography variant="body2">Partial Paid 3</SoftTypography>
              <SoftInput
                name="partialPaid3"
                type="number"
                placeholder="Partial Paid 3"
                step="1"
                value={state.partialPaid3}
                onChange={handleInvoiceChange}
                fullWidth
                required
              />
            </Grid>
          </Grid>}
          {selectedOption != null && (selectedOption.value == "INVOICE" || selectedOption.value == "PARTIAL_INVOICE") && <form>
            <div style={styles.valueTable}>
              <div style={styles.row}>
                <div style={styles.label}>Paid Amount</div>
                <div style={styles.value}>
                  <SoftInput
                    name="paidAmount"
                    type="number"
                    step="1"
                    value={state.paidAmount}
                    onChange={handleInvoiceChange}
                    style={styles.input}
                    required
                  />
                </div>
              </div>
            </div>
          </form>}

          <form>
            <div style={styles.valueTable}>
              <div style={styles.row}>
                <div style={styles.label}>CGST Rate (%)</div>
                <div style={styles.value}>
                  <SoftInput
                    name="cgstRate"
                    type="number"
                    step="0.01"
                    value={state.cgstRate}
                    onChange={handleInvoiceChange}
                    style={styles.input}
                    required
                  />
                </div>
              </div>
            </div>
          </form>

          <form>
            <div style={styles.valueTable}>
              <div style={styles.row}>
                <div style={styles.label}>SGST Rate (%)</div>
                <div style={styles.value}>
                  <SoftInput
                    name="sgstRate"
                    type="number"
                    step="0.01"
                    value={state.sgstRate}
                    onChange={handleInvoiceChange}
                    style={styles.input}
                    required
                  />
                </div>
              </div>
            </div>
          </form>

          <form>
            <div style={styles.valueTable}>

              {selectedOption != null && (selectedOption.value == "INVOICE" || selectedOption.value == "PARTIAL_INVOICE") && <div style={styles.row}>
                <div style={styles.label}>Balance Amount</div>
                <div style={styles.value}> {formatCurrency((calcGrandTotal() - state.paidAmount))}</div>
              </div>}

              <div style={styles.row}>
                <div style={styles.label}>Subtotal</div>
                <div style={styles.value}>{formatCurrency(calcLineItemsTotal())}</div>
              </div>

              <div style={styles.row}>
                <div style={styles.label}>CGST ({state.cgstRate}%)</div>
                <div style={styles.value}>{formatCurrency(calcCGSTAmount())}</div>
              </div>

              <div style={styles.row}>
                <div style={styles.label}>SGST ({state.sgstRate}%)</div>
                <div style={styles.value}>{formatCurrency(calcSGSTAmount())}</div>
              </div>

              <div style={styles.row}>
                <div style={styles.label}>Total Tax</div>
                <div style={styles.value}>{formatCurrency(calcTaxTotal())}</div>
              </div>

              <div style={styles.row}>
                <div style={styles.label}>Total Due</div>
                <div style={styles.value}>{formatCurrency(calcGrandTotal())}</div>
              </div>
            </div>
          </form>
        </div>

        <SoftBox mt={2} mb={1} display="flex" justifyContent="center" alignItems="center">
          <SoftButton variant="gradient" color="info" onClick={handleSubmit}>
            Submit
          </SoftButton>
        </SoftBox>
      </div>
      <Footer />
    </DashboardLayout>
  );
};

const styles = {
  invoice: {
    maxWidth: '100%',
    margin: '2rem auto',
    border: '1px solid #ccc',
    padding: '1rem',
    borderRadius: '8px',
    background: '#fff',
    boxSizing: 'border-box',
    width: '90%',
  },
  brand: {
    marginBottom: '1rem',
    textAlign: 'center',
  },
  logo: {
    display: 'inline-block',
    maxWidth: '50px',
    height: 'auto',
    borderRadius: '8px',
  },
  addresses: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  from: {
    width: '50%',
    padding: '1rem',
    background: '#efefef',
    borderRadius: '8px',
    marginBottom: '2.5rem',
  },
  valueTable: {
    marginTop: '1rem',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    flexWrap: 'wrap',
  },
  label: {
    fontWeight: 'bold',
    flexBasis: '30%',
  },
  value: {
    textAlign: 'right',
    flexBasis: '65%',
  },
  lineItems: {
    marginBottom: '2rem',
  },
  lineItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    flexWrap: 'wrap',
  },
  input: {
    flex: 1,
    marginRight: '0.5rem',
    padding: '0.5rem',
    borderRadius: '4px',
    border: '1px solid #ccc',
    minWidth: '150px',
    boxSizing: 'border-box',
    marginBottom: '0.5rem',
  },
  removeButton: {
    padding: '0.5rem 1rem',
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    flexShrink: 0,
    marginTop: '1rem',
  },
  addLineItem: {
    marginBottom: '2rem',
    textAlign: 'center',
  },
  addButton: {
    padding: '0.5rem 1rem',
    backgroundColor: 'green',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    minWidth: '150px',
  },
  totalContainer: {
    marginBottom: '2rem',
  },
  pay: {
    marginTop: '2rem',
    textAlign: 'center',
  },
  payNow: {
    padding: '1rem 2rem',
    backgroundColor: '#00b0ff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    minWidth: '200px',
  },
  footer: {
    marginTop: '3rem',
    borderTop: '1px solid #ccc',
    paddingTop: '2rem',
  },
  comments: {
    marginBottom: '1rem',
  },
  closing: {
    marginTop: '1rem',
    textAlign: 'center',
  },
};

export default AddInvoice;