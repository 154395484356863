// import React, { useState, useEffect } from 'react';
// import Card from "@mui/material/Card";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftAvatar from "components/SoftAvatar";
// import SoftBadge from "components/SoftBadge";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import Table from "examples/Tables/Table";
// import { APIData } from 'authentication/APIData';
// import { Link, useNavigate } from 'react-router-dom';
// import SoftButton from 'components/SoftButton';
// import PropTypes from 'prop-types';
// import PageLayout from 'examples/LayoutContainers/PageLayout';
// import { Tooltip } from '@mui/material';
// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

// const Name = ({ name, email }) => (
//     <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
//         <SoftBox mr={2}>
//             <SoftAvatar
//                 src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
//                 alt={name}
//                 size="sm"
//                 variant="rounded"
//             />
//         </SoftBox>
//         <SoftBox display="flex" flexDirection="column">
//             <SoftTypography variant="button" fontWeight="medium">
//                 {name}
//             </SoftTypography>
//             <SoftTypography variant="caption" color="secondary">
//                 {email}
//             </SoftTypography>
//         </SoftBox>
//     </SoftBox>
// );

// Name.propTypes = {
//     name: PropTypes.string.isRequired,
//     email: PropTypes.string.isRequired,
// };

// const ViewTickets = () => {
//     const [ticketData, setTicketData] = useState([]);
//     const [userdata, setUserData] = useState();
//     const navigate=useNavigate();

//     useEffect(() => {
//         const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
//         setUserData(userData_Local);
//     }, []);

//     useEffect(() => {
//         if (userdata && userdata.customerId) {
//             const customerId = userdata.customerId.toLowerCase();
//             const fetchTicketData = async () => {
//                 const url = `${APIData.api}Ticket/consumerId?consumerId=${customerId}`;

//                 try {
//                     const response = await fetch(url, { headers: APIData.headers });
//                     const data = await response.json();
//                     setTicketData(data);
//                 } catch (error) {
//                     console.error('Error fetching ticket data:', error);
//                 }
//             };

//             fetchTicketData();
//         }
//     }, [userdata]);

//     const columns = [
//         { name: "Name", align: "left" },
//         { name: "Ticket ID", align: "left" },
//         { name: "Type", align: "left" },
//         { name: "Product", align: "left" },
//         { name: "Status", align: "left" },
//         { name: "Contact", align: "left" },
//         { name: "Priority", align: "left" },
//         { name: "SLA", align: "left" },
//         { name: "Action", align: "center" },
//     ];

//     const rows = ticketData.map((ticket) => ({
//         Name: <Name name={ticket.name} email={ticket.emailid} />,
//         Product: (
//             <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//                 {ticket.product}
//             </SoftTypography>
//         ),
//         Type: (
//             <SoftBadge
//                 variant="gradient"
//                 badgeContent={ticket.type.toLowerCase()}
//                 color="light"
//                 size="xs"
//                 container
//             />
//         ),
//         "Ticket ID": (
//             <SoftTypography variant="caption" color="text" fontWeight="medium">
//                 {ticket.ticket_id}
//             </SoftTypography>
//         ),
//         Status: (
//             <SoftBadge
//                 variant="gradient"
//                 badgeContent={ticket.status.toLowerCase()}
//                 color={
//                     ticket.status === "REQUESTED" ? "info" :
//                     ticket.status === "PENDING" ? "warning" :
//                     "success"
//                 }
//                 size="xs"
//                 container
//             />
//         ),
//         Priority: (
//             <SoftBadge
//                 variant="gradient"
//                 badgeContent={ticket.priority.toLowerCase()}
//                 color={
//                     ticket.priority === "CRITICAL" ? "error" :
//                     ticket.priority === "HIGH" ? "warning" :
//                     "secondary"
//                 }
//                 size="xs"
//                 container
//             />
//         ),
//         Contact: (
//             <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//                 {ticket.phone_number}
//             </SoftTypography>
//         ),
//         SLA: (
//             <SoftTypography variant="caption" color="secondary" fontWeight="medium">
//                 {ticket.sla}
//             </SoftTypography>
//         ),
//         Action: (
//             <Link to={`/view-ticket-status/${ticket.id}`}>
//                 <SoftTypography
//                     component="span"
//                     variant="button"
//                     color="info"
//                     fontWeight="medium"
//                 >
//                     View
//                 </SoftTypography>
//             </Link>
//         ),
//     }));

//     const handleNewQueryClick = () => {
//         navigate("/ticket-form");
//       };

//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             <SoftBox py={3}>
//                 <SoftBox mb={3}>
//                     <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
//                         <SoftTypography variant="h6">Ticket List</SoftTypography>
//                         <Tooltip title="Raise a New Query/Ticket" arrow>
//                         <SoftButton sx={{ ml: 2 }} onClick={handleNewQueryClick}>+ Add</SoftButton>
//                         </Tooltip>
//                     </SoftBox>
//                     <Card>
//                         <SoftBox
//                             sx={{
//                                 "& .MuiTableRow-root:not(:last-child)": {
//                                     "& td": {
//                                         borderBottom: ({ borders: { borderWidth, borderColor } }) =>
//                                             `${borderWidth[1]} solid ${borderColor}`,
//                                     },
//                                 },
//                             }}
//                         >
//                             <Table columns={columns} rows={rows} />
//                         </SoftBox>
//                     </Card>
//                 </SoftBox>
//             </SoftBox>
//             <Footer />
//         </DashboardLayout>
//     );
// };

// export default ViewTickets;


import React, { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import SoftInput from "components/SoftInput";
import SoftButton from 'components/SoftButton';
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { APIData } from 'authentication/APIData';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const Name = ({ name, email }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
            <SoftAvatar
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                alt={name}
                size="sm"
                variant="rounded"
            />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
                {name}
            </SoftTypography>
            <SoftTypography variant="caption" color="secondary">
                {email}
            </SoftTypography>
        </SoftBox>
    </SoftBox>
);

Name.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
};

const ViewTickets = () => {
    const [ticketData, setTicketData] = useState([]);
    const [userdata, setUserData] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [itemsPerPage] = useState(5);
    const navigate = useNavigate();

    useEffect(() => {
        const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
        setUserData(userData_Local);
    }, []);

    useEffect(() => {
        if (userdata && userdata.customerId) {
            const customerId = userdata.customerId.toLowerCase();
            const fetchTicketData = async () => {
                const url = `${APIData.api}Ticket/consumerId?consumerId=${customerId}`;

                try {
                    const response = await fetch(url, { headers: APIData.headers });
                    const data = await response.json();
                    setTicketData(data);
                } catch (error) {
                    console.error('Error fetching ticket data:', error);
                }
            };

            fetchTicketData();
        }
    }, [userdata]);

    const handleNewQueryClick = () => {
        navigate("/ticket-form");
    };

    const filteredData = ticketData.filter(ticket =>
        ticket.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.ticket_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.product.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.priority.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedData = filteredData.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const columns = [
        { name: "Name", align: "left" },
        { name: "Ticket ID", align: "left" },
        { name: "Type", align: "left" },
        { name: "Product", align: "left" },
        { name: "Status", align: "left" },
        { name: "Contact", align: "left" },
        { name: "Priority", align: "left" },
        { name: "SLA", align: "left" },
        { name: "Action", align: "center" },
    ];

    const rows = paginatedData.map((ticket) => ({
        Name: <Name name={ticket.name} email={ticket.emailid} />,
        Product: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {ticket.product}
            </SoftTypography>
        ),
        Type: (
            <SoftBadge
                variant="gradient"
                badgeContent={ticket.type.toLowerCase()}
                color="light"
                size="xs"
                container
            />
        ),
        "Ticket ID": (
            <SoftTypography variant="caption" color="text" fontWeight="medium">
                {ticket.ticket_id}
            </SoftTypography>
        ),
        Status: (
            <SoftBadge
                variant="gradient"
                badgeContent={ticket.status.toLowerCase()}
                color={
                    ticket.status === "REQUESTED" ? "info" :
                    ticket.status === "PENDING" ? "warning" :
                    "success"
                }
                size="xs"
                container
            />
        ),
        Priority: (
            <SoftBadge
                variant="gradient"
                badgeContent={ticket.priority.toLowerCase()}
                color={
                    ticket.priority === "CRITICAL" ? "error" :
                    ticket.priority === "HIGH" ? "warning" :
                    "secondary"
                }
                size="xs"
                container
            />
        ),
        Contact: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {ticket.phone_number}
            </SoftTypography>
        ),
        SLA: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {ticket.sla}
            </SoftTypography>
        ),
        Action: (
            <Link to={`/view-ticket-status/${ticket.id}`}>
                <SoftTypography
                    component="span"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                >
                    View
                </SoftTypography>
            </Link>
        ),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Ticket List</SoftTypography>
                            <SoftBox display="flex" alignItems="center" gap={2}>
                                <SoftBox>
                                    <SoftInput
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                            setPage(0); // Reset to first page when searching
                                        }}
                                        size="small"
                                    />
                                </SoftBox>
                                <Tooltip title="Raise a New Query/Ticket" arrow>
                                    <SoftButton variant="gradient" color="info" size="small" onClick={handleNewQueryClick}>+ Add</SoftButton>
                                </Tooltip>
                            </SoftBox>
                        </SoftBox>
                        <SoftBox sx={{ overflow: 'auto' }}>
                            <Table columns={columns} rows={rows} />
                            <SoftBox
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                                p={3}
                            >
                                <SoftBox display="flex" gap={1} alignItems="center">
                                    <SoftTypography variant="caption" color="secondary">
                                        {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredData.length)} of {filteredData.length}
                                    </SoftTypography>
                                    <SoftButton
                                        variant="text"
                                        color="info"
                                        onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                        disabled={page === 0}
                                        iconOnly
                                    >
                                        <KeyboardArrowLeft />
                                    </SoftButton>
                                    <SoftButton
                                        variant="text"
                                        color="info"
                                        onClick={() => setPage(prev => Math.min(Math.ceil(filteredData.length / itemsPerPage) - 1, prev + 1))}
                                        disabled={page >= Math.ceil(filteredData.length / itemsPerPage) - 1}
                                        iconOnly
                                    >
                                        <KeyboardArrowRight />
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default ViewTickets;