import React, { useState, useEffect } from "react";
import axios from 'axios';
import { APIData, org } from '../authentication/APIData';
import PropTypes from 'prop-types';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBadge from "components/SoftBadge";
import SoftInput from "components/SoftInput";
import Table from "examples/Tables/Table";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    Backdrop, CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SoftAvatar from "components/SoftAvatar";
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import Swal from "sweetalert2";

const currentDate = new Date().toISOString().split('T')[0];

const Name = ({ name, email }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
            <SoftAvatar
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                alt={name}
                size="sm"
                variant="rounded"
            />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
                {name}
            </SoftTypography>
            <SoftTypography variant="caption" color="secondary">
                {email}
            </SoftTypography>
        </SoftBox>
    </SoftBox>
);

Name.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
};

const EmployeeEnrollmentsData = () => {
    const [loading, setLoading] = useState(false);
    const [reloading, setReloading] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [enrolldata, setEnrolldata] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [userDepartment, setUserDepartment] = useState("");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    const [formData, setFormData] = useState({
        status: ''
    });
    const [formErrors, setFormErrors] = useState({
        deptSupervisor: '',
        status: ''
    });

    const statusOptions = [
        { value: 'APPLIED', label: 'APPLIED' },
        { value: 'ACCEPTED', label: 'ACCEPTED' },
        { value: 'COMPLETED', label: 'COMPLETED' }
    ];

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(parseInt(accessLevel));
        const Department = localStorage.getItem('Depart Details');
        setUserDepartment(Department);
    }, []);

    useEffect(() => {
        if (typeof isSuperAdmin !== 'undefined' && userDepartment !== '') {
            filterEnrollments("all");
        }
    }, [isSuperAdmin, userDepartment]);

    const handleSelectChange = (selectedOption, actionMeta) => {
        if (selectedOption) {
            setFormData(prevFormData => ({
                ...prevFormData,
                [actionMeta.name]: selectedOption.value,
            }));
            if (actionMeta.name === 'status') {
                setSelectedStatus(selectedOption.value);
                setFormErrors(prev => ({ ...prev, status: '' }));
            }
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [actionMeta.name]: '',
            }));
        }
    };

    const filterEnrollments = async (value) => {
        try {
            setLoading(true);
            let url;
            if (value === 'all') {
                url = isSuperAdmin
                    ? APIData.api + `enrollments/status/dept?org=${org}&enrollment_type=EMPLOYEE`
                    : APIData.api + `enrollments/status/dept?org=${org}&department=${userDepartment}&enrollment_type=EMPLOYEE`;
            } else {
                const status = value.toUpperCase();
                url = isSuperAdmin
                    ? APIData.api + `enrollments/status/dept?org=${org}&status=${status}&enrollment_type=EMPLOYEE`
                    : APIData.api + `enrollments/status/dept?org=${org}&status=${status}&department=${userDepartment}&enrollment_type=EMPLOYEE`;
            }

            const response = await axios.get(url, { headers: APIData.headers });
            setEnrolldata(response.data);
            setPage(0); // Reset to first page when filtering
        } catch (error) {
            console.error("Error filtering enrollments:", error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to filter enrollments"
            });
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = (task) => {
        setSelectedTask(task);
        setIsDialogOpen(true);
        setSelectedStatus(task.enrollment_status);
        setFormErrors({
            deptSupervisor: '',
            status: ''
        });
    };

    const validateForm = () => {
        let isValid = true;
        const errors = {
            deptSupervisor: '',
            status: ''
        };

        if (!selectedTask?.deptSupervisor) {
            errors.deptSupervisor = 'Department Supervisor is required';
            isValid = false;
        }

        if (!selectedStatus) {
            errors.status = 'Status is required';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleUpdate2 = async () => {
        if (!validateForm()) {
            return;
        }

        setReloading(true);

        try {
            const form = {
                admin_id: selectedTask.admin_id || "",
                branch: selectedTask.branch || "",
                designation: selectedTask.designation || "",
                job_id: selectedTask.job_id || "",
                enrolled_date: selectedTask.enrolled_date || currentDate,
                enrollment_status: selectedStatus,
                enrollment_type: "EMPLOYEE",
                followup_datetime: selectedTask.followup_datetime || "",
                office_email: selectedTask.office_email || "",
                user_email: selectedTask.user_email,
                user_name: selectedTask.user_name,
                role: selectedTask.role || "",
                dept: selectedTask.dept || "",
                user_phone_number: selectedTask.user_phone_number || "",
                deptSupervisor: selectedTask.deptSupervisor || "",
                resume_url: selectedTask.resume_url || "",
                prevDeptSupervisor: selectedTask.prevDeptSupervisor || "",
                release_status: selectedTask.release_status || "",
                org: org,
                new_enrolled_date: currentDate,
                course_id: "",
                emp_acceptance_status: "PENDING",
                enrollmentSupervisor: "",
            };

            const url = `${APIData.api}enrollments/?orgId=${org}`;

            const response = await axios.post(url, form, {
                headers: APIData.headers
            });

            if (response.status === 201 || response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: 'Updated successfully',
                    showConfirmButton: false,
                    timer: 1500
                });
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }
        } catch (error) {
            console.error("API Error:", error);
            Swal.fire({
                icon: "error",
                title: "Update Failed",
                text: error.response?.data?.errorDesc || "An error occurred while updating"
            });
        } finally {
            setReloading(false);
            setIsDialogOpen(false);
        }
    };

    // Filter function for search
    const filteredEnrollments = (enrolldata || []).filter(enrollment =>
        enrollment.user_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        enrollment.user_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        enrollment.job_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        enrollment.dept.toLowerCase().includes(searchTerm.toLowerCase()) ||
        enrollment.enrollment_status.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Paginated data
    const paginatedEnrollments = filteredEnrollments.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const getColumns = () => {
        const baseColumns = [
            { name: "User Details", align: "left" },
            { name: "Role", align: "center" },
            { name: "Department", align: "center" },
            { name: "Status", align: "center" },
            { name: "Enrolled Date", align: "center" }
        ];

        // Add Action column if user has permission
        if (!(permission == 1110 || permission == 1100 || permission == 1000)) {
            baseColumns.push({ name: "Action", align: "center" });
        }

        return baseColumns;
    };

    const getRows = () => {
        return paginatedEnrollments.map((task) => {
            const baseRow = {
                "User Details": <Name name={task.user_name} email={task.user_email} />,
                "Role": (
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                        {task.job_id}
                    </SoftTypography>
                ),
                "Department": (
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                        {task.dept}
                    </SoftTypography>
                ),
                "Status": (
                    <SoftBadge
                        variant="gradient"
                        badgeContent={task.enrollment_status.toLowerCase()}
                        color={task.enrollment_status === "ACCEPTED" ? "success" :
                            task.enrollment_status === "COMPLETED" ? "success" : "info"}
                        size="xs"
                        container
                    />
                ),
                "Enrolled Date": (
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                        {task.enrolled_date}
                    </SoftTypography>
                )
            };

            // Add Action if user has permission
            if (!(permission == 1110 || permission == 1100 || permission == 1000)) {
                baseRow["Action"] = (
                    <SoftBox display="flex" alignItems="center" mt={-1}>
                        <SoftTypography
                            variant="button"
                            color="info"
                            fontWeight="medium"
                            onClick={() => handleUpdate(task)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer'
                            }}
                        >
                            <EditIcon sx={{ mr: 1 }} fontSize="small" />
                            Edit
                        </SoftTypography>
                    </SoftBox>
                );
            }

            return baseRow;
        });
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} md={6} lg={16} sx={{ ml: "0" }}>
                                <AppBar position="static">
                                    <Tabs
                                        value={tabValue}
                                        onChange={(e, newValue) => {
                                            setTabValue(newValue);
                                            const values = ['all', 'applied', 'accepted', 'completed'];
                                            filterEnrollments(values[newValue]);
                                        }}
                                        sx={{ background: "transparent" }}
                                    >
                                        {['All', 'Applied', 'Accepted', 'Completed'].map((label, index) => (
                                            <Tab
                                                key={label}
                                                label={label}
                                                sx={{
                                                    color: tabValue === index ? "info.main" : "text.secondary",
                                                    fontWeight: tabValue === index ? "bold" : "normal",
                                                    borderBottom: tabValue === index ? "2px solid" : "none",
                                                    borderColor: "info.main",
                                                }}
                                            />
                                        ))}
                                    </Tabs>
                                </AppBar>
                            </Grid>
                        </Grid>
                    </Card>
                    <SoftBox mt={2}>
                        <Card>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                <SoftTypography variant="h6">Employee Enrollments</SoftTypography>
                                <SoftBox>
                                    <SoftInput
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        size="small"
                                    />
                                </SoftBox>
                            </SoftBox>

                            {loading ? (
                                <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
                                    <CircularProgress />
                                </SoftBox>
                            ) : (
                                <SoftBox
                                    sx={{
                                        "& .MuiTableRow-root:not(:last-child)": {
                                            "& td": {
                                                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                    `${borderWidth[1]} solid ${borderColor}`,
                                            },
                                        },
                                    }}
                                >
                                    <Table columns={getColumns()} rows={getRows()} />
                                    <SoftBox
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        p={3}
                                    >
                                        <SoftBox display="flex" gap={1} alignItems="center">
                                            <SoftTypography variant="caption" color="secondary">
                                                {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredEnrollments.length)} of {filteredEnrollments.length}
                                            </SoftTypography>
                                            <SoftButton
                                                variant="text"
                                                color="info"
                                                onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                                disabled={page === 0}
                                                iconOnly
                                            >
                                                <KeyboardArrowLeft />
                                            </SoftButton>
                                            <SoftButton
                                                variant="text"
                                                color="info"
                                                onClick={() => setPage(prev => Math.min(Math.ceil(filteredEnrollments.length / itemsPerPage) - 1, prev + 1))}
                                                disabled={page >= Math.ceil(filteredEnrollments.length / itemsPerPage) - 1}
                                                iconOnly
                                            >
                                                <KeyboardArrowRight />
                                            </SoftButton>
                                        </SoftBox>
                                    </SoftBox>
                                </SoftBox>
                            )}
                        </Card>
                    </SoftBox>
                </SoftBox>

                <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="md">
                    <Backdrop open={reloading} style={{ color: '#fff', zIndex: 1300 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <DialogTitle>
                        <SoftTypography variant="body1" textAlign="center">
                            Update Status Of {selectedTask?.user_name}
                        </SoftTypography>
                    </DialogTitle>
                    <DialogContent style={{ padding: '20px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <ul style={{ listStyle: 'none', padding: 0 }}>
                                    <li>
                                        <SoftTypography component="span" fontWeight="bold" variant="h6">Email Id: </SoftTypography>
                                        <SoftTypography component="span" variant="h6">{selectedTask?.user_email}</SoftTypography>
                                    </li>
                                    <li>
                                        <SoftTypography component="span" fontWeight="bold" variant="h6">Job ID: </SoftTypography>
                                        <SoftTypography component="span" variant="h6">{selectedTask?.job_id}</SoftTypography>
                                    </li>
                                    <li>
                                        <SoftTypography component="span" fontWeight="bold" variant="h6">Department: </SoftTypography>
                                        <SoftTypography component="span" variant="h6">{selectedTask?.dept}</SoftTypography>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ul style={{ listStyle: 'none', padding: 0 }}>
                                    <li>
                                        <SoftTypography component="span" fontWeight="bold" variant="h6">Office Email: </SoftTypography>
                                        <SoftTypography component="span" variant="h6">{selectedTask?.office_email}</SoftTypography>
                                    </li>
                                    <li>
                                        <SoftTypography component="span" fontWeight="bold" variant="h6">Enrollment Status: </SoftTypography>
                                        <SoftTypography component="span" variant="h6">{selectedTask?.enrollment_status}</SoftTypography>
                                    </li>
                                    <li>
                                        <SoftTypography component="span" fontWeight="bold" variant="h6">Enrolled Date: </SoftTypography>
                                        <SoftTypography component="span" variant="h6">{selectedTask?.enrolled_date}</SoftTypography>
                                    </li>
                                </ul>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <SoftTypography variant="h6" mb={1}>Dept Supervisor<span style={{ color: 'red' }}>*</span></SoftTypography>
                                <SoftBox mb={2}>
                                    <SoftInput
                                        label="Dept Supervisor"
                                        value={selectedTask?.deptSupervisor || ""}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        error={!!formErrors.deptSupervisor}
                                        onChange={(e) => {
                                            setSelectedTask({
                                                ...selectedTask,
                                                deptSupervisor: e.target.value
                                            });
                                            if (e.target.value) {
                                                setFormErrors(prev => ({ ...prev, deptSupervisor: '' }));
                                            }
                                        }}
                                    />
                                    {formErrors.deptSupervisor && (
                                        <SoftTypography variant="caption" color="error">
                                            {formErrors.deptSupervisor}
                                        </SoftTypography>
                                    )}
                                </SoftBox>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6" mb={1}>Status<span style={{ color: 'red' }}>*</span></SoftTypography>
                                    <CustomSelect
                                        options={statusOptions}
                                        placeholder="Choose Status"
                                        name="status"
                                        onChange={handleSelectChange}
                                        value={statusOptions.find(option => option.value === selectedStatus)}
                                    />
                                    {formErrors.status && (
                                        <SoftTypography variant="caption" color="error">
                                            {formErrors.status}
                                        </SoftTypography>
                                    )}
                                </SoftBox>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <SoftBox display="flex" justifyContent="center" gap={2} width="100%" p={2}>
                            <SoftButton
                                variant="gradient"
                                fullWidth
                                color="secondary"
                                onClick={() => setIsDialogOpen(false)}
                            >
                                Cancel
                            </SoftButton>
                            <SoftButton
                                variant="gradient"
                                fullWidth
                                color="info"
                                onClick={handleUpdate2}
                            >
                                Save
                            </SoftButton>
                        </SoftBox>
                    </DialogActions>
                </Dialog>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default EmployeeEnrollmentsData;