import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from "@mui/material/Card";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import CircularProgress from "@mui/material/CircularProgress";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { APIData, org } from '../authentication/APIData';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Name = ({ name, email }) => {
    const [showFullName, setShowFullName] = useState(false);
    const truncationLength = 30;

    const displayedName = showFullName || name.length <= truncationLength
        ? name
        : `${name.substring(0, truncationLength)}...`;

    return (
        <SoftBox display="flex" alignItems="center" py={0.5}>
            <SoftBox mr={2}>
                <SoftAvatar
                    src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                    alt={name}
                    size="sm"
                    variant="rounded"
                />
            </SoftBox>
            <SoftBox display="flex" flexDirection="column">
                <SoftTypography variant="button" fontWeight="medium">
                    {displayedName}
                </SoftTypography>
                {name.length > truncationLength && (
                    <SoftTypography
                        variant="caption"
                        color="info"
                        onClick={() => setShowFullName(!showFullName)}
                        style={{ cursor: "pointer" }}
                    >
                        {showFullName ? "Show Less" : "Read More"}
                    </SoftTypography>
                )}
                <SoftTypography variant="caption" color="secondary">
                    {email}
                </SoftTypography>
            </SoftBox>
        </SoftBox>
    );
};

Name.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
};

const Description = ({ description }) => {
    const [showFullDescription, setShowFullDescription] = useState(false);
    const isLongDescription = description.length > 20;

    const displayedDescription = showFullDescription || !isLongDescription
        ? description
        : `${description.substring(0, 20)}...`;

    return (
        <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {displayedDescription}
            </SoftTypography>
            {isLongDescription && (
                <SoftTypography
                    variant="caption"
                    color="info"
                    onClick={() => setShowFullDescription(!showFullDescription)}
                    style={{ cursor: "pointer" }}
                >
                    {showFullDescription ? "Show Less" : "Read More"}
                </SoftTypography>
            )}
        </SoftBox>
    );
};

Description.propTypes = {
    description: PropTypes.string.isRequired,
};

const PasswordReq = () => {
    const [passwordData, setPasswordData] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [userDepartment, setUserDepartment] = useState("");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const navigate = useNavigate();
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        setCurrentUser(sessionDetails.user);
        if (sessionDetails && sessionDetails.user) {
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(parseInt(accessLevel));
        const Department = localStorage.getItem('Depart Details');
        setUserDepartment(Department);
    }, []);

    const fetchData = async () => {
        setLoading(true);
        // const baseUrl = `${APIData.api}passwordaccess/supervisor?supervisor_id=${sessionDetails.user}&org=${org}`;
        const baseUrl = `${APIData.api}passwordaccess/details?org=${org}`;
        try {
            const response = await fetch(baseUrl, { headers: APIData.headers });
            const data = await response.json();
            setPasswordData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to fetch password data.',
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filteredPasswords = passwordData.filter(pass =>
        pass.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        pass.requested_by.toLowerCase().includes(searchTerm.toLowerCase()) ||
        pass.reason.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedPasswords = filteredPasswords.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to delete the password for "${id}"?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            try {
                Swal.fire({
                    title: 'Deleting...',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
                const response = await fetch(
                    `${APIData.api}passwordaccess/delete?id=${id}`,
                    {
                        method: 'DELETE',
                        headers: { ...APIData.headers, 'Content-Type': 'application/json' },
                    }
                );
                if (response.ok) {
                    setPasswordData(prevData =>
                        prevData.filter(item => item.id !== id)
                    );
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Password deleted successfully',
                    });
                } else {
                    const errorData = await response.json();
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: errorData.message || 'Failed to delete password',
                    });
                }
            } catch (error) {
                console.error('Error deleting password:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to delete password. Please try again.',
                });
            }
        }
    };

    const handleEdit = (pass) => {
        navigate('/update-password', { state: pass });
    };

    const columns = [
        { name: "Title", align: "left" },
        { name: "Requested_by", align: "left" },
        { name: "Reason", align: "left" },
        { name: "Status", align: "left" },
        ...(permission === "1110" || permission === "1111" || isSuperAdmin ? [{ name: "Action", align: "center" }] : [])
    ];

    const rows = paginatedPasswords.map((pass) => ({
        Title: <Name name={pass.title} email={pass.created_by} />,
        Requested_by: <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {pass.requested_by}
        </SoftTypography>,
        Reason: <Description description={pass.reason} />,
        Status: <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {pass.status}
        </SoftTypography>,
        Action: (
            <>
                {(permission === "1110" || permission === "1111" || isSuperAdmin) ? <SoftTypography
                    component="a"
                    variant="button"
                    color="secondary"
                    fontWeight="medium"
                    onClick={() => handleEdit(pass)}
                    style={{ cursor: "pointer" }}
                >
                    Edit
                </SoftTypography> : " "}
                {(permission === "1111" || isSuperAdmin) ?
                    <SoftTypography
                        component="a"
                        variant="button"
                        color="error"
                        fontWeight="medium"
                        sx={{ marginLeft: "10px", cursor: "pointer" }}
                        onClick={() => handleDelete(pass.id)}
                    >
                        Delete
                    </SoftTypography> : " "}
            </>
        )
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Passwords Request</SoftTypography>
                            <SoftBox>
                                <SoftInput
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    size="small"
                                />
                            </SoftBox>
                        </SoftBox>

                        <SoftBox sx={{ overflow: 'auto' }}>
                            {loading ? (
                                <SoftBox display="flex" justifyContent="center" p={3}>
                                    <CircularProgress />
                                </SoftBox>
                            ) : (
                                <>
                                    <SoftBox
                                        sx={{
                                            "& .MuiTableRow-root:not(:last-child)": {
                                                "& td": {
                                                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                        `${borderWidth[1]} solid ${borderColor}`,
                                                },
                                            },
                                        }}
                                    >
                                        <Table columns={columns} rows={rows} />
                                    </SoftBox>
                                    <SoftBox
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        p={3}
                                    >
                                        <SoftBox display="flex" gap={1} alignItems="center">
                                            <SoftTypography variant="caption" color="secondary">
                                                {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, filteredPasswords.length)} of {filteredPasswords.length}
                                            </SoftTypography>
                                            <SoftButton
                                                variant="text"
                                                color="info"
                                                onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                                disabled={page === 0}
                                                iconOnly
                                            >
                                                <KeyboardArrowLeft />
                                            </SoftButton>
                                            <SoftButton
                                                variant="text"
                                                color="info"
                                                onClick={() => setPage(prev => Math.min(Math.ceil(filteredPasswords.length / itemsPerPage) - 1, prev + 1))}
                                                disabled={page >= Math.ceil(filteredPasswords.length / itemsPerPage) - 1}
                                                iconOnly
                                            >
                                                <KeyboardArrowRight />
                                            </SoftButton>
                                        </SoftBox>
                                    </SoftBox>
                                </>
                            )}
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default PasswordReq;