import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Icon from "@mui/material/Icon";
import Swal from 'sweetalert2';
import SoftButton from "components/SoftButton";
import { Dialog, DialogContent, DialogTitle, FormControl, DialogActions, MenuItem, Select, FormHelperText, Checkbox, FormControlLabel } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { APIData, org } from "authentication/APIData";
import axios from "axios";

function EmailServices() {
  const [showDialog, setShowDialog] = useState(false);
  const [emailData, setEmailData] = useState([]);
  const [email, setEmail] = useState("");
  const [dropdownValue, setDropdownValue] = useState("");
  const [errorMessage, setErrorMessage] = useState({ email: "", dropdown: "" });
  const [selectedEmails, setSelectedEmails] = useState([]);

  const [currentUser, setCurrentUser] = useState(null);
  const [permission, setPermission] = useState(false);
  const [userDepartment, setUserDepartment] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
    if (sessionDetails && sessionDetails.user) {
      setCurrentUser(sessionDetails.email);
      setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
    }
    const accessLevel = localStorage.getItem('Access Level');
    setPermission(parseInt(accessLevel));
    const Department = localStorage.getItem('Depart Details');
    setUserDepartment(Department);
  }, []);
  const handleEditClick = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
    setEmail("");
    setDropdownValue("");
    setErrorMessage({ email: "", dropdown: "" });
  };

  const fetchEmailData = async () => {
    try {
      const url = `${APIData.api}asset-email/all?org=${org}`;
      const response = await axios.get(url, { headers: APIData.headers });
      setEmailData(response.data);
    } catch (error) {
      console.error('Error fetching email data:', error);
    }
  };

  const handleAdd = async () => {
    let isValid = true;
    const newErrorMessage = { email: "", dropdown: "" };
    if (!email) {
      newErrorMessage.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrorMessage.email = "Email is not valid.";
      isValid = false;
    }

    if (!dropdownValue) {
      newErrorMessage.dropdown = "Please select a valid option.";
      isValid = false;
    }

    if (!isValid) {
      setErrorMessage(newErrorMessage);
      return;
    }
    var emailArray = [{
      "id": 0,
      "emailId": email,
      "client": "client",
      "password": "",
      "purpose": dropdownValue,
      "createdDate": "",
      "updatedDate": "",
      "updatedBy": "",
      "org": org
    }];
    try {
      const response = await axios.post(`${APIData.api}asset-email/create`, emailArray, { headers: APIData.headers });
      console.log(response.status);
      if (response.status == 200 || response.status == 201) {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: response.data.description,
          confirmButtonText: 'Okay'
        });
        fetchEmailData();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: response.data.errorDesc,
          confirmButtonText: 'Okay'
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error.response.data,
        confirmButtonText: 'Okay'
      });
    }
    closeDialog();
  };

  const handleDelete = async () => {
    if (selectedEmails.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'No Selection',
        text: 'Please select at least one email to delete.',
      });
      return;
    }
    else {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: "Are you sure! continue to delete Emails",
        confirmButtonText: 'Okay',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          selectedEmails.forEach(async (id) => {
            try {
              const response = await axios.delete(`${APIData.api}asset-email/delete-by-id?id=${id}`, { headers: APIData.headers });
              console.log("Deleted email with ID:", id);
            } catch (error) {
              console.error("Error deleting email with ID:", id, error);
            }
          });
          window.location.reload();
        } else if (result.isDismissed) { }
      });
    }
  };
  const toggleEmailSelection = (emailId) => {
    setSelectedEmails((prev) =>
      prev.includes(emailId)
        ? prev.filter((id) => id !== emailId)
        : [...prev, emailId]
    );
  };
  useEffect(() => {
    fetchEmailData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
    
        { permission === 1110 && (
          <SoftButton onClick={handleEditClick} variant="gradient" color="info">
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;Add new Email
          </SoftButton>
        )}
         {permission === 1111 && (
          <>
            <SoftButton onClick={handleEditClick} variant="gradient" color="info">
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp;Add new Email
            </SoftButton>
            <SoftButton variant="gradient" color="secondary" onClick={handleDelete}>
              <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
              &nbsp;Delete E-mail
            </SoftButton>
          </>
        )}
      </div>

      <SoftBox mt={4}>
        <SoftBox mb={1.5}>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={3}>
              {emailData.map((email) => (
                <Grid item xs={12} md={6} xl={4} key={email.id}>
                  <DefaultInfoCard
                    icon="email"
                    title={`Purpose: ${email.purpose}`}
                    description={`Created Date: ${email.createdDate}`}
                    value={email.emailId}
                  />
                  { permission === 1110 || permission === 1111 && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedEmails.includes(email.id)}
                        onChange={() => toggleEmailSelection(email.id)}
                      />
                    }
                    label="Select"
                  />
                )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      <Dialog
        open={showDialog}
        onClose={closeDialog}
        maxWidth="sm"
        fullWidth
        sx={{
          borderRadius: '8px',
          boxShadow: 3,
        }}
      >
        <DialogTitle>
          <SoftTypography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            Add Email to send notifications related to services
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }} error={!!errorMessage.email}>
            <SoftInput
              type="email"
              placeholder="Email*"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!errorMessage.email}
            />
            <FormHelperText>{errorMessage.email}</FormHelperText>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }} error={!!errorMessage.dropdown}>
            <Select
              labelId="valid-select-label"
              value={dropdownValue}
              onChange={(e) => setDropdownValue(e.target.value)}
              displayEmpty
              sx={{ cursor: 'pointer' }}
            >
              <MenuItem value="">
                <em>Select Purpose</em>
              </MenuItem>
              <MenuItem value="ORGCOMM">Default</MenuItem>
              <MenuItem value="ENROLLMENTS">ENROLLMENTS</MenuItem>
              <MenuItem value="FINANACE">FINANCE</MenuItem>
              <MenuItem value="TECHNICAL">TECHNICAL</MenuItem>
              <MenuItem value="SALES">SALES</MenuItem>
              <MenuItem value="ACADEMY">ACADEMY</MenuItem>
              <MenuItem value="EVENTS">EVENTS</MenuItem>
              <MenuItem value="SUPPORT">SUPPORT</MenuItem>
              <MenuItem value="HR">HR</MenuItem>
            </Select>
            <FormHelperText>{errorMessage.dropdown}</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <SoftButton onClick={closeDialog} variant="text" color="secondary">
            Cancel
          </SoftButton>
          <SoftButton onClick={handleAdd} variant="gradient" color="info">
            Add
          </SoftButton>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default EmailServices;
